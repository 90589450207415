import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from '../../service/login.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { COMMON } from '../../service/constant';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../../admin/shared/spinner.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { EStatusCode } from '@milagro-ui-core';


@Component({
    selector: 'app-reset-password-link',
    templateUrl: './reset-password-link.component.html',
    styleUrls: ['./reset-password-link.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatIcon, MatPrefix, NgIf, MatCheckbox, RouterLink, SpinnerComponent]
})
export class ResetPasswordLinkComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  resetPasswordForm: FormGroup;
  submitted = false;
  changeType: boolean = true;
  public data: any;
  isDisabled: boolean = false;
  show: boolean = false;
  public currYear: any;
  public token: string = '';
  public version: any;
  disableInput: boolean = true;
  public email: string = '';

  constructor(private fb: FormBuilder, private loginService: LoginService,
    private router: Router, public activeRouter: ActivatedRoute,) {
    this.activeRouter.queryParams.subscribe((params) => {
      if (params) {
        if (params.token && params.token.length) {
          this.token = params.token;
        } else {
          this.token = "";
        }
      } else {
        this.token = "";
      }
    });
    this.version = COMMON.VERSION;
  }

  ngOnInit() {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,40}$/;
    this.resetPasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(12), Validators.pattern(passwordPattern)]],
      confirmPassword: ['', [Validators.required,  Validators.minLength(12), Validators.pattern(passwordPattern)]],
    });
    this.verifyJWT(this.token);
  }

  onSubmit() {
    // this.submitted = true;

    if (this.resetPasswordForm.value.newPassword !== this.resetPasswordForm.value.confirmPassword) {
      this.show = true;
      this.toastr.error("New password and confirm password must be the same!");
      return;
    }
    
    const newPassword = this.resetPasswordForm.value.newPassword;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,40}$/;
    
    if (!passwordPattern.test(newPassword)) {
      this.show = false;
      this.toastr.error("Password must be 12-40 chars with upper, lower, number, and special characters");
      return;
    }

    this.data = {
      newPassword: this.resetPasswordForm.value.newPassword,
      token: this.token,
      domain: window.location.hostname
    };

    this.loginService.resetPasswordThroughLink(this.data).subscribe(
      (res: any) => {

        if (res.result.info.code === EStatusCode.OK) {
          this.toastr.success(res.result.data.responseMsg);
          this.router.navigate(['/login'], { replaceUrl: true });
        } else {
          this.toastr.error(res.result.data.responseMsg);
        }
      });
  }

  checkSelected() {
    this.changeType = !this.changeType;
  }

  verifyJWT(token) {
    this.loginService.verifyJWT(token).subscribe((res: any) => {

      if (res.info.code == EStatusCode.OK) {
        this.email = res.data.emailId;
      } else {
        this.toastr.error(res.data.responseMsg);
        this.router.navigate(['/login'], { replaceUrl: true });
      }
    })
  }
}
