import { DialogComponent } from './../../../../core/subscription/dialog/dialog.component';
import { COMMON } from './../../../../service/constant';
import { LoginService } from './../../../../service/login.service';
import { ConfigService } from './../../../../service/config.service';
import { TenantCompaniesService } from './../../../../service/tenant-companies.service';
import { Router } from '@angular/router';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ItemsService } from './../../../../service/items.service';
import { MenuService } from './../../../../service/menu.service';
import { EmployeeService } from './../../../../service/employee.service';
import { ModuleListService } from './../../../../service/module-list.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { AppComponentBase } from '../../../shared/AppComponentBase';
import moment from "moment";
import CryptoJS from "crypto-js";
import { Subscription } from 'rxjs';
import {GlobalService, ModuleList} from "../../../../service/global.service";
import {PaymentInfoService} from "../../../../service/payment-info.service";
import { CommonDatePipe } from '../../../shared/pipes/common-date.pipe';
import { MatButton } from '@angular/material/button';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { NgIf, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-subscription-dialog',
    templateUrl: './subscription-dialog.component.html',
    styleUrls: ['./subscription-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatTabGroup, MatTab, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatButton, DecimalPipe, CommonDatePipe]
})
export class SubscriptionDialogComponent extends AppComponentBase implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  isYearly: boolean = false;
  isYearlySelected = 0;
  isMonthly: boolean = true;
  activeUsers: number;
  deactiveUsers: number;
  zerocost: number = 0;
  itemTax: number = 0;
  subscribedModuleList: ModuleList[];
  defaultModulePrice = 2;
  modulePrice = 0;
  totalPrice = 0;
  list: any;
  paymentInfo: any;
  tableItems: any;
  dataArr = [];
  displayedColumns: string[] = [
    "item_name",
    "active_users",
    "cost_pupm",
    "cost_pm",
    "row_tax",
    "row_cost"
  ];
  dataSource = new MatTableDataSource<any>();
  pucost: number = 0;
  displaypucost: any = 0;
  mcost: number = 0;
  displaymcost: any = 0;
  total_cost: number = 0;
  displaytotal_cost: any = 0;
  subTotal: number = 0;
  taxSubTotal: number = 0;
  sTotal: any = 0;
  tax: number = 0;
  displaytax: any = 0;
  displaydiscount: any = 0;
  discount: number = 0;
  total: number = 0;
  totalSeats: number = 0;
  remainingDaysToEndMonth: number = 0;
  displaytotal: any = 0;
  mid: number;
  deviceId: number;
  transactionKey: string;
  padString = "0";
  encryptedTSEP: string;
  loggedInUserId: string;
  notStartOfMonth: boolean = false;
  tsysFlag: boolean = false;
  isOwner: string;
  defaultItemsList: any = {};
  reservedSeatInfo: any;
  constructor(public dialogRef: MatDialogRef<SubscriptionDialogComponent>,inject: Injector,
              private globalService: GlobalService,
    public employeeService: EmployeeService,
    public moduleListService: ModuleListService,
    public menuService: MenuService,private paymentInfoService: PaymentInfoService,
    public itemsService: ItemsService,
    public dialog: MatDialog,
    public router: Router,
    public tenantCompaniesService: TenantCompaniesService,
    public configService: ConfigService,
    public loginService: LoginService
    ) {
    super(inject);
   }

  ngOnInit() {
    this.getReserveSeatItemDetail();
    this.getUserCount();
    this.getSubscribedModules();
    this.getReservedSeats();
    this.getTsysDetails();
    this.isMonthly = true;
    this.isYearly = false;
    this.isYearlySelected = 0;
  }

  close() {
    this.dialogRef.close();
  }
  getReserveSeatItemDetail(){
    this.paymentInfoService.reservedSeatItemDetails().toPromise().then((itemDetails: any) => {
      if (itemDetails.info.code == 200) {
        this.reservedSeatInfo = itemDetails.data[0];
      }
    });
  }
  getTsysDetails(){
    const getASTsysASInfo : Subscription = this.globalService.getTsysInfo().subscribe((res:any)=>{
      if (res.result.info.code == 200) {
        this.mid = res.result.data.mid;
        this.transactionKey = res.result.data.transactionKey;
        this.deviceId = res.result.data.deviceId;
        this.tsysFlag = true;
      } else {
        this.tsysFlag = false;
      }

    })
    this.subscriptions.push(getASTsysASInfo);
  }

  getUserCount() {
    this.employeeService
      .activeDeactiveUserCount()
      .subscribe((response: any) => {
        if (response.info.code == 200) {
          this.activeUsers = response.data.activeUsers;
          this.deactiveUsers = response.data.deActiveUsers;
        }
      });
  }

  getSubscribedModules() {
    this.menuService.getSubscribedModules().toPromise().then((response: any) => {
      if (response.info.code == 200) {
        this.subscribedModuleList = response.data;
        this.getDefaultItemsList();
      }
    });
  }

  getReservedSeats() {
    this.tenantCompaniesService
      .getReservedSeats()
      .subscribe((response: any) => {
        if (response.info.code == 200) {
          this.totalSeats = response.data[0].noOfUsers;
        }
      });
  }
  getDefaultItemsList() {
    this.dataArr = [];
    this.subTotal = 0;
    this.taxSubTotal = 0;
    let displayItemTax = 0;
    const getDefltItemList : Subscription = this.itemsService.getDefaultItemsList().subscribe((response: any) => {
      if (response.info.code == 200) {
        const date = new Date();
        const daysInCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        this.remainingDaysToEndMonth = (new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - date.getDate())+1;
        if (this.isMonthly) {
          var numberOfMonths = 1;
        } else {
          var numberOfMonths = 12;
        }
        const costForRemainingDays = Number(((this.reservedSeatInfo.unitCost / daysInCurrentMonth) * this.remainingDaysToEndMonth).toFixed(2));

        if (this.reservedSeatInfo.itemTaxRate) {
          this.itemTax = Number((costForRemainingDays * this.reservedSeatInfo.itemTaxRate)/100);
          displayItemTax = Number(this.itemTax.toFixed(2));
        } else if (!this.reservedSeatInfo.itemTaxRate && this.reservedSeatInfo.itemTaxTotal) {
          this.itemTax =Number(this.reservedSeatInfo.itemTaxTotal)
          displayItemTax = Number(this.itemTax.toFixed(2));
        } else {
          displayItemTax = 0;
          this.itemTax = 0;
        }
        if (numberOfMonths == 12) {
          var currentMonthUnitCost = Number((this.reservedSeatInfo.unitCost).toFixed(2));
          var costForSeatReservedRow = Number(((this.reservedSeatInfo.totalCost * numberOfMonths)) * this.totalSeats);
        } else {
          var currentMonthUnitCost = Number((costForRemainingDays).toFixed(2));
          var costForSeatReservedRow = Number(currentMonthUnitCost * this.totalSeats);
        }

        const activeUser = {
          name: "Seats Reserved",
          class: "active_users",
          numberOfUsers: this.totalSeats,
          cost_per_u_p_m: Number(currentMonthUnitCost).toFixed(2),
          cost_per_month: Number(0.0).toFixed(2),
          itemTax: Number(displayItemTax).toFixed(2),
          total_row_cost: Number(costForSeatReservedRow).toFixed(2)
        };
        this.taxSubTotal = Number(this.itemTax.toFixed(2));
        this.subTotal = costForSeatReservedRow;
        this.dataArr.push(activeUser);
        const moduleArray = [];
        this.subscribedModuleList.forEach(subscribed_module => {
          moduleArray.push(subscribed_module.label.toLowerCase());
        });
        this.defaultItemsList = response.data.list;

        response.data.list.forEach(element => {
          this.pucost = 0;
          this.mcost = 0;
          this.discount = 0;
          let displayItemTax = 0;
          this.itemTax = 0;
          if (moduleArray.includes(element.itemName.toLowerCase())) {
            if (
              element.pricingType == 0 &&
              (element.itemName != "Dashboard" &&
                element.itemName != "Calendar" &&
                element.itemName != "Client" &&
                element.itemName != "Vendor" &&
                element.itemName != "Tasks" &&
                element.itemName != "HR" &&
                element.itemName != "Report" &&
                element.itemName != "Subscription" &&
                element.itemName != "Seats Reserved" &&
                element.itemName != "Utilities")
            ) {
              let costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.remainingDaysToEndMonth).toFixed(2));
              if (element.itemTaxRate) {
                this.itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                displayItemTax = this.itemTax;
              } else if (!element.itemTaxRate && element.itemTaxTotal) {
                this.itemTax = Number(element.itemTaxTotal)
                displayItemTax = this.itemTax;
              } else {
                this.itemTax = 0;
                displayItemTax = 0;
              }
              if (numberOfMonths == 12) {
                var currentMonthUnitCost = Number((element.unitCost).toFixed(2));
                this.total_cost = Number(((element.totalCost * numberOfMonths) * this.totalSeats).toFixed(2))
              } else {
                var currentMonthUnitCost = Number((costForRemainingDays).toFixed(2));
                this.total_cost = Number((currentMonthUnitCost * this.totalSeats).toFixed(2));
              }
              var currentMonthlyCost = Number(this.zerocost.toFixed(2));
            } else if (
              element.pricingType == 1 &&
              element.itemName != "Dashboard" &&
              element.itemName != "Calendar" &&
              element.itemName != "Client" &&
              element.itemName != "Vendor" &&
              element.itemName != "Tasks" &&
              element.itemName != "HR" &&
              element.itemName != "Report" &&
              element.itemName != "Subscription" &&
              element.itemName != "Seats Reserved" &&
              element.itemName != "Utilities"
            ) {
              let costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.remainingDaysToEndMonth).toFixed(2));

              if (element.itemTaxRate) {
                this.itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                displayItemTax = Number(this.itemTax.toFixed(2));
              } else if (!element.itemTaxRate && element.itemTaxTotal) {
                this.itemTax = Number(element.itemTaxTotal)
                displayItemTax = Number(this.itemTax.toFixed(2));
              } else {
                this.itemTax = 0;
                displayItemTax = 0;
              }
              if (numberOfMonths == 12) {

                var currentMonthlyCost = Number((element.unitCost).toFixed(2));
                this.total_cost = Number(((element.totalCost * numberOfMonths)).toFixed(2))
              } else {
                var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                this.total_cost = Number((currentMonthlyCost).toFixed(2))
              }
              var currentMonthUnitCost = Number(this.zerocost.toFixed(2));
            } else {
              var currentMonthUnitCost = Number(this.zerocost.toFixed(2));
              var currentMonthlyCost = Number(this.zerocost.toFixed(2));
              this.total_cost = Number(this.zerocost.toFixed(2));
            }
            this.tableItems = {
              name: element.itemName,
              numberOfUsers: 0,
              class: element.itemName.replace(/ /g, "_"),
              cost_per_u_p_m: currentMonthUnitCost.toFixed(2),
              cost_per_month: currentMonthlyCost.toFixed(2),
              itemTax: Number(displayItemTax).toFixed(2),
              total_row_cost: this.total_cost.toFixed(2)
            };
            this.taxSubTotal += Number(this.itemTax.toFixed(2));
            this.subTotal += this.total_cost;
            this.dataArr.push(this.tableItems);
          }
        });
      }

      this.sTotal = Number(Math.round(this.subTotal * 100) / 100).toFixed(2);
      this.displaytax = Number((
        Math.round(this.subTotal * (8.25 / 100) * 100) / 100
      ) + this.taxSubTotal).toFixed(2);
      this.tax = Number((Math.round(this.subTotal * (8.25 / 100) * 100) / 100 + this.taxSubTotal).toFixed(2));
      if (this.isYearlySelected) {
        this.displaydiscount = Number(((this.sTotal * 10) / 100).toFixed(2));
        this.discount = Number(((this.sTotal * 10) / 100).toFixed(2));
      } else {
        this.discount = Number(0);
        this.displaydiscount = Number(0.0).toFixed(2);
      }
      this.total = this.subTotal + this.tax - this.discount;
      this.displaytotal = Number(
        this.subTotal + this.tax - this.discount
      ).toFixed(2);
      this.dataSource.data = this.dataArr;

    });
    this.subscriptions.push(getDefltItemList);
  }

  radioChange(event) {
    if (event.index == 0) {
      this.isMonthly = true;
      this.isYearly = false;
      this.isYearlySelected = 0;
      this.getDefaultItemsList();
    } else if (event.index == 1) {
      this.isMonthly = false;
      this.isYearly = true;
      this.isYearlySelected = 1;
      this.getDefaultItemsList();
    }
  }

  subscriptionDialog() {
    const paymtInfoLitByUser : Subscription = this.paymentInfoService.paymentInfoListByUser().subscribe((result: any) => {
      if (result.info.code == 200) {
        if (result.data.length > 0) {
          this.paymentInfo = result.data;
        } else {
          this.paymentInfo = [];
        }
        if (!this.isYearlySelected) {
          var plan = "monthly";
        } else if (this.isYearlySelected) {
          var plan = "yearly";
        }

        const manifest = this.encryptManifest(
          this.mid,
          this.deviceId,
          this.transactionKey
        );
        if (this.tsysFlag) {
          const dialogRef = this.dialog.open(DialogComponent, {
            width: COMMON.CC_PAYMENT_WIDTH,
            height: "auto",
            disableClose: true,
            data: {
              total: this.displaytotal,
              plan: plan,
              manifest: manifest,
              ubmDeviceId : this.deviceId,
              userId: this.configService.userId,
              isOwner: this.isOwner,
              totalSeats: this.totalSeats,
              dataArr: this.dataArr,
              defaultItemsList: this.defaultItemsList,
              subscribedModuleList: this.subscribedModuleList,
              remainingDaysToEndMonth: this.remainingDaysToEndMonth,
              subtotal: this.sTotal,
              paymentInfo: this.paymentInfo
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result.isComplete == 0 && result.reOpen == 1) {
              this.subscriptionDialog();
            } else if (result.isComplete == 0 && result.reOpen == 0) {
              this.toastr.error("Add credit card aborted");
            } else if (result.isComplete == 1 && result.reOpen == 0) {
              if (result.isPaymentFailFromTsys == 1) {
                this.toastr.error(result.failedResonseMsg);
                this.toastr.error("Please try again later...!");
              } else {
                if (result.isSuccess == 1 && result.otherError == 0) {
                  this.toastr.success("Subscription payment successfully done");
                } else if (result.isSuccess == 1 && result.otherError == 1) {
                  this.toastr.success("Subscription payment successfully done");
                  this.toastr.error(result.otherErrorReason);
                  this.toastr.error("Please try again later...!");
                } else {
                }
              }
            } else if (result.isComplete == 2) {
              this.toastr.error("Credit card already exists!");
            } else if (result.isComplete == 3) {
              this.toastr.error("Something went wrong. Please try again later.");
            }
          });
        } else {
          this.toastr.error("Something went wrong. Please try again later.");
        }
      } else{
        this.toastr.error("Something went wrong. Please try again later.");
      }

    });
    this.subscriptions.push(paymtInfoLitByUser);
  }

  encryptManifest(mid, deviceId, transactionKey) {
    const dataString =
      this.pad(mid, 20) +
      this.pad(deviceId, 24) +
      "000000000000" +
      moment().format("MMDDYYYY");
    const key = CryptoJS.enc.Utf8.parse(transactionKey.substr(0, 16));
    const iv = key;
    const encrypted = CryptoJS.AES.encrypt(dataString, key, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.NoPadding
    }).toString();
    const finalManifest = CryptoJS.enc.Base64.parse(encrypted);
    const hash = CryptoJS.HmacMD5(transactionKey, transactionKey);
    const finalhash = hash.toString(CryptoJS.enc.Hex);
    return finalhash.substr(0, 4) + finalManifest + finalhash.substr(-4);
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = s + " ";
    return s;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }
}
