import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DialogData } from '../../../service/Utils/Interfaces.class';
import { IPresenceMonitoring } from './Presence-monitoring.interface';
import { Subscription } from 'rxjs';
import { LoginService } from '../../../service/login.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgFor, NgClass } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDivider } from '@angular/material/divider';


@Component({
    selector: 'app-Presence-monitoring',
    templateUrl: './Presence-monitoring.component.html',
    styleUrls: ['./Presence-monitoring.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDivider, CdkScrollable, MatDialogContent, NgFor, NgClass, ExtendedModule, MatDialogActions, MatButton]
})
export class PresenceMonitoringComponent implements OnInit, OnDestroy {
  dataSource;
  private subscriptions: Subscription;
  private nullData;

  constructor( public dialogRef: MatDialogRef<PresenceMonitoringComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private loginService: LoginService
    ) {
      this.dataSource = this.data;
      this.subscriptions = this.loginService.clockInAndPresentData.subscribe((res)=>{
        this.dataSource = res;
        this.nullData = res
        if(this.nullData === null){
          this.closeDiv();
        }
      })
     }

  ngOnInit() {}

  closeDiv()
  {
    this.dialogRef.close();
  }

  trackByUserId(index: number, data: IPresenceMonitoring):number {      
    return data.userId;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
