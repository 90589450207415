import {Component, Inject, Injector, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DOCUMENT, NgIf, NgClass, NgFor } from "@angular/common";
import {DeviceDetectorService} from "ngx-device-detector";
import moment from 'moment';
import {AppComponentBase} from '../../shared/AppComponentBase';
import { IResponse} from '../../../service/Utils/Interfaces.class';
import { EStatusCode } from '@milagro-ui-core';
import { Subscription } from 'rxjs';
import {SurveyService} from "../../../service/survey.serviceTemplate";
import { COMMON } from '../../../service/constant';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { SpinnerComponent } from '../../shared/spinner.component';
import { MatButton } from '@angular/material/button';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { LightboxGalleryComponent } from '../../shared/lightbox-gallery/lightbox-gallery.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatDivider } from '@angular/material/divider';
import { MatCard, MatCardTitle, MatCardContent } from '@angular/material/card';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'app-start-campaign',
    templateUrl: './start-campaign.component.html',
    styleUrls: ['./start-campaign.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbar, NgClass, ExtendedModule, MatCard, MatCardTitle, MatDivider, MatCardContent, MatFormField, MatInput, FormsModule, LightboxGalleryComponent, CdkTextareaAutosize, MatLabel, MatSelect, NgFor, MatOption, MatRadioGroup, MatRadioButton, ReactiveFormsModule, MatButton, SpinnerComponent, SafePipe]
})
export class StartCampaignComponent extends AppComponentBase implements OnInit, OnDestroy {
    private readonly subscriptions: Subscription[] = [];
    content = '<h6 style="text-align: center;margin-top:30px;"><br/>msg</h6>';
    welcomeMsg = '';
    buttonName = 'Click to start survey';
    buttonClick;
    surveyId;
    allDetails;
    allquestions;
    displayQues;
    currentIndex = 0;
    public staticNps: any [] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    submitDetails: FormGroup;
    resultdata;
    noOfQuestions;
    public textAns = '';
    public multiTextAns = '';
    radioAns: string;
    public yesnoAns;
    answer: any[] = [];
    public verify;
    checkNo = null;
    unitNo = null;
    backButton: boolean = false;
    nextButton: boolean = false;
    randomNo: number = null;
    encryptedId;
    device;
    surveyEnd: boolean = false;
    couponDisplay: boolean = false;
    path;
    categoryScore: any[] = [];
    categories: any[] = [];
    failingScr;
    expiry_time;
    facebook;
    yelp;
    google;
    verifyObj;
    title;
    name = '';
    quesTraverse:number[] = [0];
    expiration;
    formSubmit:boolean = false;
    expired:boolean = false;
    Notexpired:boolean = true;
    TotalScore:number = 0;
    prevSelected = '';
    lastAnsObj;
    lastGoto:number;

    viewType = '';
    moduleId = 0;
    videoExtArr = ['mp4', 'webm', 'ogg', 'flv', 'mkv', '3gp'];
    themeData: string;
    public version: string;
    public companyLogo: string = '';

    constructor(private injector: Injector, private surveyService: SurveyService, private formBuilder: FormBuilder,
                @Inject(DOCUMENT) private document: Document, private deviceService: DeviceDetectorService) {
        super(injector);
        this.configService.updateIsUserLogged.next(false);

        this.setWindowTitle('Survey');

        if (this.deviceService.isDesktop() === true) {
            this.device = 'desktop';
        } else if (this.deviceService.isMobile() === true) {
            this.device = 'mobile';
        } else if (this.deviceService.isTablet() === true) {
            this.device = 'tablet';
        } else {
            this.device = 'unknown';
        }
    }

    ngOnInit() {
        this.version = COMMON.VERSION;
        this.title = 'Welcome User';
        this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
        $('#nextQues').css('display', 'none');
        this.createForm();
        this.encryptedId = this.document.location.pathname.substr(16);
        const pathArray = this.document.location.pathname.substr(16).split('/')
        if (pathArray && pathArray.length > 1) {
            this.encryptedId = pathArray[1];
            this.viewType = pathArray[0];
        }

        const getASurvey : Subscription = this.surveyService.getSurveysId(this.encryptedId).subscribe((res: IResponse) => {
            if (res.info.code === EStatusCode.OK && res.data.length) {

                this.companyLogo = res.data[0].companyLogo
            this.surveyId = res.data[0].surveyId;
            this.expiration = res.data[0].surveyExpiration;
            const welcomeMsg = res.data[0].welcomeMsg;
            this.welcomeMsg = this.content = this.content.replace('msg',welcomeMsg);

            this.getViewType(res.data[0]);

            if (res.data[0].surveyCodeType == 'Open' || res.data[0].surveyCodeType == 'Email') {
                if(this.expiration == "0" || this.expiration == ""){
                    this.buttonClick = 'startSurvey';
                    $('#textBox').css('display', 'none');
                    this.verify = null;
                }else{
                    const CreatedDate = moment(res.data[0].createdDate);
                    const finalD = CreatedDate.add(Number(res.data[0].surveyExpiration),'hours').format("HH-DD-MM-YYYY");
                    // let currentDate = moment().format('HH-DD-MM-YYYY');
                    const currentDate = moment.utc().format('HH-DD-MM-YYYY');
                    const ms = moment(finalD,'HH/DD/MM/YYYY').diff(moment(currentDate,'HH/DD/MM/YYYY'));
                    if(ms <= 0){
                        this.expired = true;
                        this.Notexpired = false;
                        return;
                    }else{
                        this.buttonClick = 'startSurvey';
                        $('#textBox').css('display', 'none');
                        this.verify = null;
                    }
                }
            } else {
                this.buttonClick = 'surveyName';
            }

            } else {
                this.expired = true;
                this.Notexpired = false;
                return;
            }
            this.companyLogo = this.companyLogo || this.configService.companyLogo;
        });
        this.subscriptions.push(getASurvey);
    }

    startSurvey() {
        this.verifyObj = {
            surveyCode:this.verify,
            surveyId:this.surveyId,
            surveyExp:this.expiration,
            viewType: this.viewType ? this.viewType : '',
            moduleId: this.moduleId ? this.moduleId : 0,
        }
        const surveyACodeAVerify : Subscription = this.surveyService.surveyCodeVerify(this.verifyObj).subscribe((res: any) => {
            this.randomNo = res.data.randomNo;
            this.surveyQuestions();
        });
        this.subscriptions.push(surveyACodeAVerify);
    }

    surveyName() {
        this.verifyObj = {
            surveyCode:this.verify,
            surveyId:this.surveyId,
            surveyExp:this.expiration
        }
        if(typeof this.verify == 'undefined'){
            this.toastr.error('Enter Survey Code');
        }else if (this.verify == null){
            this.toastr.error('Invalid Survey Code');
        }else if (isNaN(this.verify)==true){
            this.toastr.error('Characters are not allowed in survey code');
        }else if (this.verify.toString().length == 15 || this.verify.toString().length == 16
            || this.verify.toString().length == 14 || this.verify.toString().length == 17) {
                const survACodeAVer : Subscription = this.surveyService.surveyCodeVerify(this.verifyObj).subscribe((res: any) => {
                if (res.info.code === EStatusCode.OK) {
                    this.randomNo = res.data.randomNo;
                    this.checkNo = res.data.checkno;
                    this.unitNo = res.data.unitno;
                    this.surveyQuestions();
                } else if(res.data.responseMsg == 'Survey Expired'){
                    this.expired = true;
                    this.Notexpired = false;
                    return;
                } else if (res.error.code == 'ER_DUP_ENTRY'){
                    this.toastr.error('Survey Code already Exists! Please enter a new survey code');
                } else{
                    this.toastr.error(res.data.responseMsg);
                }
            });
            this.subscriptions.push(survACodeAVer);
        } else {
            this.toastr.error('Incorrect survey code');
        }
    }

    addUniqueTrackByKey(question){
        if(!['text', 'multilinetext'].includes(question.surveyQuestionType)){
            const activeQuestion = question;
            activeQuestion.questionOptions = activeQuestion.questionOptions.length && activeQuestion.questionOptions.map((e) => {
                e.activeQuestionID = question.surveyQuestionType === 'nps' ? activeQuestion.surveyQuestionId : e.label.replace(/\s+/g, '-') + activeQuestion.surveyQuestionId;
                return e;
            });
            return activeQuestion;
        } else {
            return question;
        }   
    }

    surveyQuestions() {
        $('#textBox').css('display', 'none');
        $('#nextButton').css('display', 'none');
        $('#allQues').css('display', 'block');
        this.content = '<h6 style="text-align: center;"></h6><br>';
        const surSurveyDetailsFoVer : Subscription = this.surveyService.getSurveyDetailsForCampaign(this.surveyId).subscribe((res: any) => {
            this.allDetails = JSON.parse(JSON.stringify(res.data));
            this.failingScr = res.data.surveys[0].failingScore;
            this.allquestions = res.data.questions;
            this.categories = res.data.categories;
            this.allquestions.sort((a, b) => (a.questionOrder > b.questionOrder) ? 1 : -1);
            this.allquestions[0].gallery = (this.allquestions[0].queAttachment == null || this.allquestions[0].queAttachment == 'null') ? [] : this.convertGalleryData(this.allquestions[0].queAttachment);
            this.displayQues = this.addUniqueTrackByKey(this.allquestions[0]);
            this.categories.forEach((value)=>{
                if(this.displayQues.surveyCategoryId == value.surveyCategoryId){
                    this.title = value.surveyCategoryName;
                }
            });
            this.noOfQuestions = this.allquestions.length;
            for (let i = 0; i < this.categories.length; i++) {
                let score = 0;
                for (let j = 0; j < this.categories[i].questionArray.length; j++) {
                    for (let k = 0; k < this.categories[i].questionArray[j].questionOptions.length; k++) {
                        if (this.categories[i].questionArray[j].surveyQuestionType == 'nps') {
                            this.categories[i].questionArray[j].questionOptions[k].score = 10;
                        }
                    }
                    score += Math.max.apply(Math, this.categories[i].questionArray[j].questionOptions.map(function (o) {
                        if(isNaN(o.score)){
                            return 0;
                        }else{
                            return o.score;
                        }
                    }));
                }
                if(isNaN(score) == false){
                    this.TotalScore += score;
                }

                this.categoryScore.push({
                    "category_id": this.categories[i].surveyCategoryId,
                    "category_name": this.categories[i].surveyCategoryName,
                    "total_category_max_score": isNaN(score) == false ? score:0
                });
            }
            if (res.data.expiryTime !== null) {
                this.expiry_time = res.data.expiryTime;
            }

            if (res.data.surveys[0].facebook_url !== null) {
                this.facebook = res.data.surveys[0].facebookUrl;
            }

            if (res.data.surveys[0].yelp_url !== null) {
                this.yelp = res.data.surveys[0].yelpUrl;
            }
            if(res.data.surveys[0].google_url !== null){
                this.google = res.data.surveys[0].googleUrl;
            }
            this.nextButton = true;
        });
        this.subscriptions.push(surSurveyDetailsFoVer);
    }

    nextQuestion() {
        this.validateAnswer((msg)=>{
            if(msg === 'verified'){
                this.textAns = '';
                this.multiTextAns = '';
                this.prevSelected = '';
                this.backButton = true;
                this.nextButton = true;
                if (this.currentIndex < this.allquestions.length - 1) {
                    this.currentIndex++;
                    this.allquestions[this.currentIndex].gallery = (this.allquestions[this.currentIndex].queAttachment == null || this.allquestions[this.currentIndex].queAttachment == 'null') ? [] : this.convertGalleryData(this.allquestions[this.currentIndex].queAttachment);
                    this.displayQues = this.addUniqueTrackByKey(this.allquestions[this.currentIndex]);
                    this.categories.forEach((value)=>{
                        if(this.displayQues.surveyCategoryId == value.surveyCategoryId){
                            this.title = value.surveyCategoryName;
                        }
                    });
                    this.quesTraverse.push(this.currentIndex);
                } else {
                    this.submitForm();
                }
            }else{
                this.toastr.error(msg);
            }
        });
    }

    validateAnswer(callback){
        let msg:string = '';
        const currentAnsOrder = this.quesTraverse[this.quesTraverse.length - 1] + 1;
        const lastAnsOrder = this.answer.length > 0 ? this.answer[this.answer.length - 1].questionOrder : -1;
        if(currentAnsOrder == lastAnsOrder){
            msg = 'verified';
            callback(msg);
        } else {
            if(this.displayQues.surveyQuestionType == 'text' || this.displayQues.surveyQuestionType == 'multilinetext'){
                msg = 'Please type your answer';
                callback(msg);
            }else{
                msg = 'Please select any option';
                callback(msg);
            }
        }
    }

    jumpQues(goto) {
        this.validateAnswer((msg)=>{
            if(msg === 'verified'){
                this.prevSelected = '';
                this.backButton = true;
                this.nextButton = true;
                if (this.currentIndex == this.allquestions.length - 1) {
                    this.submitForm();
                } else if (goto == 0) {
                    this.nextQuestion();
                    return;
                }

                for (let i = 0; i < this.allquestions.length; i++) {
                    if (+this.allquestions[i].questionOrder == goto) {
                        this.allquestions[i].gallery = (this.allquestions[i].queAttachment == null || this.allquestions[i].queAttachment == 'null') ? [] : this.convertGalleryData(this.allquestions[i].queAttachment);
                        this.displayQues = this.addUniqueTrackByKey(this.allquestions[i]);
                        this.currentIndex = i;
                        this.categories.forEach((value)=>{
                            if(this.displayQues.surveyCategoryId == value.surveyCategoryId){
                                this.title = value.surveyCategoryName;
                            }
                        });
                    }
                }
                this.quesTraverse.push(this.currentIndex);
            }else{
                this.toastr.error(msg);
            }
        });
    }

    gotoNextQues() {
        if (this.displayQues.surveyQuestionType == 'text') {
            this.valueFunc('text', 'NA');
            this.nextQuestion();
        } else if (this.displayQues.surveyQuestionType == 'multilinetext') {
            this.valueFunc('multilinetext', 'NA');
            this.nextQuestion();
        } else if(this.displayQues.surveyQuestionType == 'nps'){
            if(typeof this.lastAnsObj !== "undefined" && this.lastAnsObj !== ''){
                this.answer.push(this.lastAnsObj);
                this.lastAnsObj = '';
            }
            this.nextQuestion();
        } else {
            if(typeof this.lastAnsObj !== "undefined" && this.lastAnsObj !== ''){
                this.answer.push(this.lastAnsObj);
                this.lastAnsObj = '';
            }
            if(this.prevSelected !== ''){
                const goto = +this.lastGoto + 1;
                this.jumpQues(goto);
            }else{
                this.nextQuestion();
            }
        }
    }

    valueFunc(value, score) {

        if(this.textAns == '' && this.displayQues.surveyQuestionType == 'text' || this.multiTextAns == '' && this.displayQues.surveyQuestionType == 'multilinetext'){
            return;
        }else{
            this.answer.push({
                ans: value == 'text' ? this.textAns : (value == 'multilinetext' ? this.multiTextAns : value),
                Score: score,
                Type: this.displayQues.surveyQuestionType,
                categoryId: this.displayQues.surveyCategoryId,
                questionId: this.displayQues.surveyQuestionId,
                questionOrder: this.displayQues.questionOrder
            });
        }
        this.lastAnsObj = '';
    }

    prevQuestion() {
        this.lastAnsObj = '';
        this.lastGoto = this.quesTraverse.pop();
        const i = this.quesTraverse[this.quesTraverse.length - 1];
        if(this.answer.length > 0){
            if(i == this.answer[this.answer.length -1].questionOrder - 1){
                const j = this.answer.pop();
                this.lastAnsObj = j;
                if(j.Type == 'text'){
                    this.textAns = j.ans;
                } else if(j.Type == 'multilinetext') {
                    this.multiTextAns = j.ans;
                } else{
                    const value = j.ans+'-'+j.questionId;
                    this.prevSelected = value;
                }
            }
        }
        this.allquestions[i].gallery = (this.allquestions[i].queAttachment == null || this.allquestions[i].queAttachment == 'null') ? [] : this.convertGalleryData(this.allquestions[i].queAttachment);
        this.displayQues = this.addUniqueTrackByKey(this.allquestions[i]);
        this.currentIndex = i;
        this.categories.forEach((value)=>{
            if(this.displayQues.surveyCategoryId == value.surveyCategoryId){
                this.title = value.surveyCategoryName;
            }
        });
        if (this.currentIndex == 0) {
            this.backButton = false;
        }
    }

    submitForm() {
        this.title = 'Welcome User';
        let score = 0;
        let ansCount = 0;
        let per;
        for (const j of this.categoryScore) {
            for (const i of this.answer) {
                if (i.categoryId == j.category_id) {
                    if(isNaN(i.Score)==false){
                        score += i.Score;
                    }
                    ++ansCount;
                }
            }
            j.user_total_category_score = score;
            j.user_answer_count = ansCount;
            per = j.total_category_max_score == 0?'NA':Math.round(((score / j.total_category_max_score) * 100));
            j.user_category_score_per = per;
            score = 0;
            ansCount = 0;
            per = 0;
        }
        this.backButton = false;
        this.nextButton = false;
        this.content = this.welcomeMsg;
        $('#allQues').css('display', 'none');
        this.formSubmit = true;
        $('#submitButton').css('display', 'block');
    }

    createForm() {
        this.submitDetails = this.formBuilder.group({
            firstName: ['',[Validators.required, this.noWhitespaceValidator]],
            lastName: ['',[Validators.required, this.noWhitespaceValidator]],
            replyEmail: ['',[Validators.required,Validators.pattern(COMMON.EMAIL_PATTERN)]],
            phone: ['',[Validators.required, Validators.min(0)]],
            dateOfBirth: [],
            zip: ['',[Validators.required, Validators.min(0)]]
        });
    }

    public noWhitespaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : {'whitespace': true};
    }

    submitData() {
        if(this.submitDetails.invalid){
            this.toastr.error('Please fill all the required fields');
            return this.submitDetails.controls;
        }
        this.submitDetails.controls.dateOfBirth.setValue(new Date());
        this.resultdata = this.submitDetails.value;
        this.resultdata.ansArray = this.answer;
        this.resultdata.answeredCount = this.answer.length;
        this.resultdata.unansweredCount = this.allquestions.length - this.answer.length;
        this.resultdata.checkNo = this.checkNo;
        this.resultdata.unitNo = this.unitNo;
        this.resultdata.surveyCode = +this.verify;
        this.resultdata.randomNo = this.randomNo;
        this.resultdata.device = this.device;
        this.resultdata.surveyType = this.allDetails.surveys[0].surveyCodeType;
        this.resultdata.catScore = this.categoryScore;
        this.resultdata.fScr = this.failingScr;
        this.resultdata.surveyId = this.displayQues.surveyId;
        this.resultdata.total = this.TotalScore;
        const aDDSurveyieDAnswer : Subscription = this.surveyService.addSurveyierAnswer(this.resultdata).subscribe((res: any) => {
            if (res.info.code === EStatusCode.OK) {
                const link = document.createElement('a');
                link.setAttribute('type', 'hidden');
                link.href = '/thank_you/'+this.randomNo;
                document.body.appendChild(link);
                link.click();
                link.remove();
                return;
            } else {
                this.toastr.error('error');
            }
        });
        this.subscriptions.push(aDDSurveyieDAnswer);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
          if (subscription) {
            subscription.unsubscribe();
          }
        })
      }


      getViewType(result: any) {

        const pathname = this.document.location.pathname.substr(1);

          if (this.viewType) {

            if (this.viewType === 'ticket' && result.ticketSurveyLink 
            && JSON.parse(result.ticketSurveyLink).indexOf(pathname) !== -1) {
                const index = JSON.parse(result.ticketSurveyLink).indexOf(pathname)
                this.moduleId = JSON.parse(result.ticketId)[index];

            } else if (this.viewType === 'project' && result.projectSurveyLink 
            && JSON.parse(result.projectSurveyLink).indexOf(pathname) !== -1) {
                const index = JSON.parse(result.projectSurveyLink).indexOf(pathname)
                this.moduleId = JSON.parse(result.projectId)[index];
            }

          } else {
              this.viewType = '';
              this.moduleId = 0;
          }
      }
      
    trackByDisplayQues(index: number, displayQues: any): number {   
      return displayQues.activeQuestionID;  
    }

    trackByStaticNps(index: number, staticNps: any): number {     
      return index;  
    }

      convertGalleryData(attachments){
        const filesArr = JSON.parse(attachments).map((e) => {
            e.ext = e.fileName.substring(e.fileName.lastIndexOf('.') + 1);
            e.type = this.videoExtArr.includes(e.ext) ? 'video' : (e.ext === 'pdf' ? 'pdf' : 'image');
            return e;
        });
    
        return filesArr;
    }

    /*-----------------------------------------------------------------------------------------------------*/


    onKeyPress(event: KeyboardEvent) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

}
