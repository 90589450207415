import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API } from "./constant";
import { Observable, Subject } from 'rxjs';





@Injectable({
    providedIn: 'root'
})
export class RegisterTenantService {
    domain: string;
    constructor(private http: HttpClient) { }

    signup(tenantData: any, customHeaders?: Function): Observable<any> {
      
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        const body = JSON.stringify(tenantData);
        return this.http.post(API.REGISTER_TENANT, tenantData, httpOptions);
    }
    setShareValue(domain) {
        this.domain = domain;
    }
    getShareValue() {
        return this.domain;
    }
}
