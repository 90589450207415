<div *ngIf="!error" >
    <div class="view_header">
  <mat-toolbar class="themeColor" [ngClass]="themeData" >
    <img src="{{companyLogo}}"
         onError="this.src='/assets/img/default_company_logo.png'">
    <mat-label class="headerName themeColor" [ngClass]="themeData">{{companyName}}</mat-label>
  </mat-toolbar>
    </div>
<div class="background-clr"
  *ngIf="agreementDetList?.agreementInfo?.agreementCompleteStatusByAdmin === 1 && agreementDetList?.agreementInfo?.agreementCompleteStatus === 1">
  <div class="expired_page_div tw-bg-white tw-flex tw-flex-col tw-items-center tw-justify-center">
    <div class="agreement-img tw-text-center">
    <img src="../../.././../../../assets/img/agreement_expiried.png" class="tw-inline-block">
  </div>
    <p class="agreement-title theme-text-color tw-mb-4">Your agreement successfully completed.</p>
    <div class="agreement-text">
      <p>
        You have successfully signed and submitted the agreement.
      </p>
      <p>
        If you are looking for copy of agreement please get in touch with the representative who helped you sign the
        agreement
      </p>
      <div *ngIf="companyContact">
        <p>or reach out us at {{companyContact}} </p>
      </div>
    </div>
  </div>
</div>

  <div class="background-clr" style="display: inline-block;" *ngIf="agreementDetList?.agreementInfo?.agreementCompleteStatusByAdmin !== 1 || agreementDetList?.agreementInfo?.agreementCompleteStatus !== 1">
  <div #adminDev style="background-color: white; min-height: 700px" *ngIf="agreementForm.controls['template1'].value">
    <div class="header-form">
      <div class="icon-pack">
        <div class="custom-item-btn pull-right pdf" mat-button #tooltip="matTooltip" matTooltipPosition="above"
             matTooltip="Print" style="cursor: pointer" (click)="printDetail()">
          <i class="fas fa-print text-danger" ></i>
        </div>
      </div>
    </div>

    <div class="body-agreement-form" 
      [ngClass]="(agreementDetList?.agreementInfo?.agreementCompleteStatus !== 1) ||
      (agreementDetList?.agreementInfo?.agreementCompleteStatusByAdmin !== 1 &&
       agreementDetList?.agreementInfo?.agreementCompleteStatus !== 1) ? '': 'no-edit'">

      <form class="template1" [formGroup]="agreementForm">
        <section>
          <div style="padding-top: 15px;" *ngIf="!isdefault">
              <div class="company-info">
                {{ companyName }}
                <br />
                {{companyAddress}}
                <br />
                {{dataClientAddress | addressFormat: 'city_state_zip'}}
                <br />
                {{companyCountry}}
              </div>
            <div class="subject-info">
              <h4 class="agreement-subject"><strong>{{agreementDetList['subject']}}</strong>
              </h4>
            </div>
            <div class="company-logo">
              <img src="{{companyLogo}}" class="logo tw-inline-block" onError="this.src='/assets/img/default_company_logo.png'">
            </div>
          </div>
          <div style="padding-top: 15px;" *ngIf="isdefault">
          </div>
          <div [innerHTML]="agreementForm?.controls['template1'].value| safe: 'html'"
               class="agreement-template tinyClass" #custom_agreement_body
               [ngClass]="{'agreement-validation' : agreementValidation,
               'agreement-template-align': agreementDetList.customAgreementTemplateType != 5,
               'agreement-template-style': agreementDetList.customAgreementTemplateType == 5 }">
          </div>
          <div class="agreement-detail_div" *ngIf="agreementDetList.customAgreementTemplateType != 5 ">
            <div class="table_outer">
              <table cellpadding="0" border="0" cellspacing="5" align="center" class="agreement-detail">
                <tbody>
                <tr class="cust_name">

                  <td class="agreement-pcname">
                    <div [ngClass]="agreementForm.controls['pcSignature'].value ? 'pc-name' : ''"><strong><span>{{agreementForm.controls['pcName'].value}}</span></strong></div>
                  </td>
                  <td><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></td>
                  <td colspan="2" class="agreement-pcsign">
                    <div *ngIf="agreementDetList.agreementInfo.agreementCompleteStatus !== 1">
                      <a (click)="clickSign()" class="btn btn-warning sign-capture">
                        Click To Capture Your Signature</a>
                      <div class="signature">
                        <img *ngIf="agreementForm?.controls['pcSignature'].value"
                             src="{{agreementForm?.controls['pcSignature'].value}}" width="150" height="80">
                      </div>
                      <div class="text-right">
                        <strong>{{agreementForm?.controls['pcSignatureDate'].value | date: 'MMMM d,y'}}</strong>
                      </div>
                    </div>
                    <div *ngIf="agreementDetList.agreementInfo.agreementCompleteStatus === 1">
                      <div class="signature">
                        <!--<a (click)="clickSign()" class="btn btn-warning sign-capture">-->
                          <!--Click To Capture Your Signature</a>-->
                        <img src="{{agreementForm?.controls['pcSignature'].value}}" width="150" height="80">
                      </div>
                      <div class="signature-date">
                        <strong>{{agreementForm?.controls['pcSignatureDate'].value | date: 'MMMM d,y'}}</strong>
                      </div>
                    </div>
                  </td>
                  <p class="cust_name_sign">Customer Name and Signature</p>
                </tr>
                <tr class="td_placeholder">
                  <td>
                    <p class="td_placeholder">Customer Name (Please Print)</p>
                  </td>
                  <td><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></td>
                  <td>
                    <p class="td_placeholder">Customer Signature</p>
                  </td>
                  <td>
                    <p class="td_placeholder">Date</p>
                  </td>
                </tr>
                <tr>

                  <td class="agreement-pcsign" style="color: #000;">
                    <div>
                      <mat-form-field class="example-full-width disabledClass" appearance="outline">
                        <input matInput type="text" placeholder="Company Representative Name"
                               formControlName="companyRepresentativeName" required>
                        <mat-label>Company Representative Name</mat-label>
                      </mat-form-field>
                    </div>
                  </td>
                  <td><p></p></td>
                  <td colspan="2" class="agreement-pcsign">
                    <div
                      *ngIf="agreementForm?.controls['companyRepresentativeSignature'].value">
                      <div class="rep-sign">
                        <img src="{{agreementForm?.controls['companyRepresentativeSignature'].value}}" width="150"
                             height="80">
                      </div>
                      <div class="admin-sign" style="color: #000;">
                        <strong>{{agreementForm?.controls['companyRepresentativeSignatureDate'].value | date :'MMMM d,y'}}</strong>
                      </div>
                    </div>
                  </td>

                </tr>
                <tr class="td_placeholder">
                  <td><p class="td_placeholder">{{companyName}} Representative Name (Please Print)</p></td>
                  <td><p class="td_placeholder">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></td>
                  <td><p class="td_placeholder">{{companyName}} Representative Signature</p></td>
                  <td><p class="td_placeholder">Date</p></td>
                </tr>
                <tr class="comp_name">
                  <td style="color: #000;">
                    <div>
                      <mat-form-field class="example-full-width disabledClass" appearance="outline">
                        <input type="text" placeholder="VAR Name"
                               formControlName="varName"
                               matInput name="varName">
                        <mat-label>VAR Name</mat-label>
                      </mat-form-field>
                    </div>
                  </td>
                  <p class="comp_name_sign">{{companyName}} Representative Name and Signature</p>
                </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="agreementDetList.customAgreementTemplateType !== '2' && !isEmployee" class="template2">
              <div class="authorization">
                <div class="table-responsive">
                  <table *ngIf="agreementDetList.customAgreementTemplateType === '1'" class="table authorization_tbl" cellpadding="0"
                         cellspacing="0" style="width:100%" border="0">
                    <tbody>
                    <tr>
                      <td align="center"><img src="{{companyLogo}}" class="logo" onError="this.src='/assets/img/default_company_logo.png'"></td>
                    </tr>
                    <tr>
                      <td class="LetterHeading" align="center"><h5><strong>LETTER OF AUTHORIZATION FOR PORTING &
                        SERVICE</strong></h5></td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="agreement-template tinyClass MSA"
                       [innerHTML]="agreementForm.controls['template2'].value | safe: 'html'">
                  </div>
                  <table *ngIf="agreementDetList.customAgreementTemplateType === '1'" cellpadding="0" cellspacing="0"
                         style="width:100%;"  class="cust_name_sign_tbl">
                    <tbody>
                    <tr>
                      <td>
                        <p>Approved by Authorized Customer Representative:</p>
                      </td>
                      <!-- <td>
                        <p>&nbsp;</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td> -->
                    </tr>
                    <tr>
                      <td>
                        <div id="copy_pcCapture_sign"
                             *ngIf="agreementForm.controls['pcSignature'].value">
                          <img src="{{agreementForm.controls['pcSignature'].value}}" width="100px;"
                               height="85px" required>
                        </div>
                      </td>
                      <td colspan="2" style="vertical-align: bottom;">
                        <p class="author-pc-name" style="color: #000;">
                          <strong><span>{{agreementForm.controls['pcName'].value}}</span></strong>
                        </p>
                      </td>
                      <p class="comp_name_sign">Signature and Name</p>
                    </tr>
                    <tr class="td_placeholder">
                      <td>
                        <p>Signature</p>
                      </td>
                      <td>
                        <p>Name</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="cust-designation">
                          <mat-form-field class="example-full-width" appearance="outline"
                                          [ngClass]="agreementDetList.agreementInfo.agreementCompleteStatus === 1 ? 'disabledClass' : 'noDisabled'">
                            <input type="text" placeholder="Customer designation"
                                   formControlName="pcDesignation"
                                   matInput name="pcDesignation" required>
                            <mat-label>Customer designation</mat-label>
                          </mat-form-field>
                        </p>
                      </td>
                      <td colspan="2" style="padding-top: 30px">
                        <p class="cust-designation">
                          {{ agreementForm?.controls['pcSignatureDate'].value | date: 'MMMM d,y'}}
                        </p>
                      </td>
                      <p  class="comp_name_sign">Title( Customer designation )</p>
                    </tr>
                    <tr class="td_placeholder">
                      <td>
                        <p>Title( Customer designation )</p>
                      </td>
                      <td>
                        <p>Date</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    </tbody>
                    <tbody>
                    <tr>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    </tbody>
                    <tbody>
                    <tr>
                      <td>
                        <p>Approved by {{companyName}} Representative:</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td align="">
                        <div id="copy_capture_sign"
                             *ngIf="agreementForm?.controls['companyRepresentativeSignature'].value">
                          <img src="{{agreementForm?.controls['companyRepresentativeSignature'].value}}" width="100px;"
                               height="85px">
                        </div>
                      </td>
                      <td colspan="2" style="padding-top: 30px">
                        <p class="cust-designation" style="color: #000;">
                          <strong><span class="company_representative_name">
                            {{agreementForm?.controls['companyRepresentativeName'].value}}</span></strong>
                        </p>
                      </td>
                      <p  class="comp_name_sign">Signature and Name</p>
                    </tr>
                    <tr class="td_placeholder">
                      <td>
                        <p>Signature</p>
                      </td>
                      <td>
                        <p>Name</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="cust-designation">
                          <mat-form-field class="example-full-width disabledClass" appearance="outline">
                            <input type="text" placeholder="Customer designation"
                                   formControlName="companyRepresentativeDesignation"
                                   matInput name="companyRepresentativeDesignation" required>
                            <mat-label>Company Representative designation</mat-label>
                          </mat-form-field>
                        </p>
                      </td>
                      <td colspan="2" style="padding-top: 30px">
                        <p class="cust-designation">
                          {{agreementForm?.controls['companyRepresentativeSignatureDate'].value | date: 'MMMM d,y'}}
                        </p>
                      </td>
                      <p  class="comp_name_sign">Title( Company Representative designation )</p>
                    </tr>
                    <tr class="td_placeholder">
                      <td>
                        <p>Title( Company Representative designation )</p>
                      </td>
                      <td>
                        <p>Date</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="padding-top: 15px">
                        <p style="color: red;">A COPY OF THE CURRENT PHONE BILL IS REQUIRED FOR PORTING.</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

      <div *ngIf="(agreementDetList?.agreementInfo?.agreementCompleteStatus !== 1) ||
        (agreementDetList?.agreementInfo?.agreementCompleteStatusByAdmin !== 1 &&
         agreementDetList?.agreementInfo?.agreementCompleteStatus !== 1)">

        <button mat-raised-button 
          *ngIf="agreementDetList.customAgreementTemplateType != 5 "
          class="form-buttons submit_btn themeColor" [ngClass]="themeData"
           (click)="saveCustomerSign()">Submit
        </button>

        <button mat-raised-button 
          *ngIf="agreementDetList.customAgreementTemplateType == 5 "
          class="form-buttons submit_btn themeColor" [ngClass]="themeData"
           (click)="saveCustomerSign()">Mark As Complete
        </button>

      </div>

      </form>
    </div>
    <app-scroll-top-button [scrollBody]="adminDev" [type]="'detail'"></app-scroll-top-button>
  </div>
</div>

  <footer class="page-footer view_footer tw-p-2 tw-flex tw-items-center tw-justify-between tw-gap-2">
    <p class="footer-copyright text-left tw-flex-1">Copyright© <a style="color:#5d9cec" href="http://www.Utiliko.io " target="_blank">Utiliko Corporation {{currYear}}.</a> All rights reserved.
    </p>
    <p class="alignright tw-text-sm">{{version}}</p>
  </footer>

</div>
<div *ngIf="error">
  <div class="center">
    <p style="font-size: 50px">Oops!</p>
    <p style="font-size: 25px">Something went wrong and we couldn't process your request.</p>
    <p style="font-size: 15px;margin:0px;">Please check your entered Id.</p>
  </div>
</div>
<div id="print" [hidden]="!show">
  <table [ngClass]="{'agree-default': isdefault}">
    <tbody [ngClass]="{'agree-default': isdefault}">
    <tr [ngClass]="{'agree-default': isdefault}">
      <td [ngClass]="{'agree-default': isdefault}">
    <div *ngIf="!error">
      <div class="background-clr" *ngIf="agreementDetList?.agreementInfo?.agreementCompleteStatusByAdmin === 1 &&
agreementDetList?.agreementInfo?.agreementCompleteStatus === 1">
        <div style="background-color: white" class="expired_page_div">
          <h2 style="text-align: center;">
            THE PAGE YOU ARE LOOKING FOR HAS EXPIRED
          </h2>
          <i style="color: #f05050;" class="fa fa-exclamation-triangle fa-5x" aria-hidden="true"></i>
        </div>
      </div>
      <div class="background-clr" *ngIf="agreementDetList?.agreementInfo?.agreementCompleteStatusByAdmin !== 1 ||
agreementDetList?.agreementInfo?.agreementCompleteStatus !== 1">
        <div style="background-color: white; min-height: 700px" *ngIf="agreementForm.controls['template1'].value">
          <div class="header-form">
            <div class="icon-pack">
              <div class="custom-item-btn pull-right pdf" mat-button #tooltip="matTooltip" matTooltipPosition="above"
                   matTooltip="Print Invoice" style="cursor: pointer" (click)="printDetail()">
                <i class="fas fa-print text-danger" ></i>
              </div>
            </div>
          </div>
          <div class="body-agreement-form">
            <form class="template1" [formGroup]="agreementForm">
              <section>
                <div style="padding-top: 15px;" *ngIf="!isdefault">
                  <div class="company-info">
                    {{ companyName }}
                    <br />
                    {{companyAddress}}
                    <br />
                    {{dataClientAddress | addressFormat: 'city_state_zip'}}
                    <br />
                    {{companyCountry}}
                  </div>
                  <div class="subject-info">
                    <h4 class="agreement-subject"><strong>{{agreementDetList['subject']}}</strong>
                    </h4>
                  </div>
                  <div class="company-logo">
                    <img src="{{companyLogo}}" class="logo"  onError="this.src='/assets/img/default_company_logo.png'">
                  </div>
                </div>
                <div style="padding-top: 15px;" *ngIf="isdefault">
                </div>
                <div [innerHTML]="agreementForm?.controls['template1'].value| safe: 'html'"
                  class="agreement-template tinyClass" 
                  [ngClass]="{'agreement-validation' : agreementValidation,
                  'agreement-template-align': agreementDetList.customAgreementTemplateType != 5,
                  'agreement-template-style': agreementDetList.customAgreementTemplateType == 5 }">
                </div>
                <div class="agreement-detail_div" *ngIf="agreementDetList.customAgreementTemplateType != 5">
                  <div class="table_outer">
                    <table cellpadding="0" border="0" cellspacing="5" align="center" class="agreement-detail">
                      <tbody>
                      <tr class="cust_name">

                        <td class="agreement-pcname">
                          <div [ngClass]="agreementForm.controls['pcSignature'].value ? 'pc-name' : ''"><strong><span>{{agreementForm.controls['pcName'].value}}</span></strong></div>
                        </td>
                        <td colspan="2" class="agreement-pcsign">
                          <div *ngIf="agreementDetList.agreementInfo.agreementCompleteStatus !== 1">
                            <div class="signature">
                              <img *ngIf="agreementForm?.controls['pcSignature'].value"
                                   src="{{agreementForm?.controls['pcSignature'].value}}" width="150" height="80">
                              <div class="admin-sign">
                                <strong>{{agreementForm?.controls['pcSignatureDate'].value | date: 'MMMM d,y'}}</strong>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="agreementDetList.agreementInfo.agreementCompleteStatus === 1">
                            <div class="signature">
                              <img src="{{agreementForm?.controls['pcSignature'].value}}" width="150" height="80">
                              <div class="admin-sign">
                                <strong>{{agreementForm?.controls['pcSignatureDate'].value | date: 'MMMM d,y'}}</strong>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="td_placeholder">
                        <td>
                          <p class="td_placeholder">Customer Name (Please Print)</p>
                        </td>
                        <td><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></td>
                        <td>
                          <p class="td_placeholder">Customer Signature</p>
                        </td>
                        <td>
                          <p class="td_placeholder">Date</p>
                        </td>
                      </tr>
                      <tr>

                        <td class="agreement-pcsign">
                          <div>
                            <div>{{agreementDetList?.agreementInfo.companyRepresentativeName}}
                            </div>
                          </div>
                        </td>
                        <td><p></p></td>
                        <td colspan="2" class="agreement-pcsign">
                          <div *ngIf="agreementForm?.controls['companyRepresentativeSignature'].value">
                            <div class="rep-sign">
                              <img src="{{agreementForm?.controls['companyRepresentativeSignature'].value}}" width="150"
                                   height="80">
                              <div class="admin-sign" style="color: #000;">
                                <strong>{{agreementForm?.controls['companyRepresentativeSignatureDate'].value | date :'MMMM d,y'}}</strong>
                              </div>
                            </div>
                          </div>
                        </td>

                      </tr>
                      <tr class="td_placeholder">
                        <td><p class="td_placeholder">{{companyName}} Representative Name (Please Print)</p></td>
                        <td><p class="td_placeholder">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></td>
                        <td><p class="td_placeholder">{{companyName}} Representative Signature</p></td>
                        <td><p class="td_placeholder">Date</p></td>
                      </tr>
                      <tr class="comp_name">
                        <td style="color: #000;">
                          <div>
                            <p>VAR Name: <span class="paddingSpan"><strong> <u>{{agreementDetList?.agreementInfo.varName}}</u></strong></span>
                            </p>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="agreementDetList.customAgreementTemplateType !== '2' && !isEmployee" class="page"></div>
                  <div *ngIf="agreementDetList.customAgreementTemplateType !== '2' && !isEmployee" class="template2">
                    <div class="authorization">
                      <div class="table-responsive">
                        <table *ngIf="agreementDetList.customAgreementTemplateType === '1'" class="table authorization_tbl" cellpadding="0"
                               cellspacing="0" style="width:100%" border="0">
                          <tbody>
                          <tr>
                            <td align="center"><img src="{{companyLogo}}" class="logo"  onError="this.src='/assets/img/default_company_logo.png'"></td>
                          </tr>
                          <tr>
                            <td class="LetterHeading" align="center"><h5><strong>LETTER OF AUTHORIZATION FOR PORTING &
                              SERVICE</strong></h5></td>
                          </tr>
                          </tbody>
                        </table>
                        <div class="agreement-template tinyClass MSA"
                             [innerHTML]="agreementForm.controls['template2'].value | safe: 'html'">
                        </div>
                        <table *ngIf="agreementDetList.customAgreementTemplateType === '1'" cellpadding="0" cellspacing="0"
                               style="width:100%;"  class="cust_name_sign_tbl">
                          <tbody>
                          <tr>
                            <td>
                              <p>Approved by Authorized Customer Representative:</p>
                            </td>
                            <!-- <td>
                              <p>&nbsp;</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td> -->
                          </tr>
                          <tr>
                            <td>
                              <div id="copy_pcCapture_sign" *ngIf="agreementForm.controls['pcSignature'].value">
                                <img src="{{agreementForm.controls['pcSignature'].value}}" width="100px;"
                                     height="85px" required>
                              </div>
                            </td>
                            <td colspan="2" style="vertical-align: bottom;">
                              <p class="author-pc-name" style="color: #000;">
                                <strong><span>{{agreementForm.controls['pcName'].value}}</span></strong>
                              </p>
                            </td>
                          </tr>
                          <tr class="td_placeholder">
                            <td>
                              <p>Signature</p>
                            </td>
                            <td>
                              <p>Name</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="cust-designation">
                                <strong><span>{{agreementDetList.agreementInfo.pcDesignation}}</span></strong>
                              </p>
                            </td>
                            <td colspan="2" style="padding-top: 30px">
                              <p class="cust-designation">
                                {{ agreementForm?.controls['pcSignatureDate'].value | date: 'MMMM d,y'}}
                              </p>
                            </td>
                          </tr>
                          <tr class="td_placeholder">
                            <td>
                              <p>Title( Customer designation )</p>
                            </td>
                            <td>
                              <p>Date</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          </tbody>
                          <tbody>
                          <tr>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          </tbody>
                          <tbody>
                          <tr>
                            <td>
                              <p>Approved by {{companyName}} Representative:</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          <tr>
                            <td align="">
                              <div id="copy_capture_sign"
                                   *ngIf="agreementForm?.controls['companyRepresentativeSignature'].value">
                                <img src="{{agreementForm?.controls['companyRepresentativeSignature'].value}}" width="100px;"
                                     height="85px">
                              </div>
                            </td>
                            <td colspan="2" style="padding-top: 30px">
                              <p class="cust-designation" style="color: #000;">
                                <strong><span class="company_representative_name">
                            {{agreementForm?.controls['companyRepresentativeName'].value}}</span></strong>
                              </p>
                            </td>
                          </tr>
                          <tr class="td_placeholder">
                            <td>
                              <p>Signature</p>
                            </td>
                            <td>
                              <p>Name</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p class="cust-designation">
                                <strong><span>{{agreementDetList.agreementInfo.companyRepresentativeDesignation}}</span></strong>
                              </p>
                            </td>
                            <td colspan="2" style="padding-top: 30px">
                              <p class="cust-designation">
                                {{agreementForm?.controls['companyRepresentativeSignatureDate'].value | date: 'MMMM d,y'}}
                              </p>
                            </td>
                          </tr>
                          <tr class="td_placeholder">
                            <td>
                              <p>Title( Company Representative designation )</p>
                            </td>
                            <td>
                              <p>Date</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" style="padding-top: 15px">
                              <p style="color: red;">A COPY OF THE CURRENT PHONE BILL IS REQUIRED FOR PORTING.</p>
                            </td>
                            <td>
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="tem_not_found" *ngIf="error">
      <p>Agreement template not found</p>
    </div>
      </td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td>
        <!--place holder for the fixed-position footer-->
        <div class="page-footer-space" style="height: 90px;"></div>
      </td>
    </tr>
    </tfoot>
  </table>
  <footer  style="position: fixed; bottom: 0; width: 100%; height: 60px; background-color: white" >
    <hr>
    <div  style="margin: 0 15px;font-size: 11px;font-family: 'Open Sans', sans-serif;line-height: 1.42857143;">
      <div style="text-align: left; width: 33.33333333%; float: left;">
        <p  style="margin: 0 0 10px;">{{configService.companyEmail}}</p>
      </div>
      <div style="width: 33.33333333%;float: left;">
        <p style="margin: 0 0 10px;"> {{configService.companyAddress}},{{configService.companyCity}},{{configService.companyState ? configService.companyState + ',' : ''}}{{configService.companyZipCode}},{{configService.companyCountry}} <br>{{configService.companyPhone| commonDate: 'phone_format' }}</p>
      </div>
      <div  style="text-align: right;width: 33.33333333%;float: right;">
        <p style="margin: 0 0 10px;">{{configService.companyWebsite ? configService.companyWebsite : configService.companyDomain}}</p>
      </div>
    </div>
  </footer>
<!--  <div class="page-footer" style="height:100px;position:relative;overflow: hidden;right: 0;bottom: 0;border-top: 1px solid #cfdbe2;padding: 10px;z-index: 109;font-weight: 400;">

  </div>-->
</div>
<app-spinner></app-spinner>
