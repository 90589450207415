import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { AppComponentBase } from "../../AppComponentBase";
import { PDFDocumentProxy, PdfViewerModule } from 'ng2-pdf-viewer'
import { Router, ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { EStatusCode } from '@milagro-ui-core';

import { COMMON } from '../../../../service/constant';
import { CdkDragEnd, CdkDrag } from '@angular/cdk/drag-drop';
import { NgxUiLoaderService, NgxUiLoaderModule } from 'ngx-ui-loader';
import { TicketsService } from '../../../../service/tickets.service';
import { ProjectService } from '../../../../service/project.service';
import { DocsignDialogPopup } from '../upload-docsign/docsign-dialog-popup';
import { SpinnerComponent } from '../../spinner.component';
import { FormsModule } from '@angular/forms';
import { MatCard } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatToolbar } from '@angular/material/toolbar';
import { NgIf, NgClass, NgFor } from '@angular/common';


@Component({
    selector: 'app-admin-docsign',
    templateUrl: './admin-docsign.component.html',
    styleUrls: ['./admin-docsign.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatToolbar,
        NgClass,
        ExtendedModule,
        MatTooltip,
        MatButton,
        MatCard,
        NgFor,
        PdfViewerModule,
        FormsModule,
        CdkDrag,
        NgxUiLoaderModule,
        SpinnerComponent,
    ],
})


export class AdminDocsignComponent extends AppComponentBase implements OnInit, OnDestroy {

  public offset: any;
  public position: boolean = false;
  public getpdfdata: any;
  public indexsign: number;
  public arryposition: any = [];
  public initialposition: any = [];
  public dateposition: any = [];
  public datposition: any = [];
  public offsetdesc: any;
  public descposition: any = [];
  public isadmindesc: boolean;
  public deposition: any = [];
  public iniposition: any = [];
  public offsetini: any;
  public offsetdate: any;
  public pdffileSrc = "../../../../../assets/joblog1.pdf";
  public isSrcLoaded = false;
  public isImage = true;
  public IsValue = true;
  public isInitialValue = true;
  public fontRule = {};
  public fontRuleIntial = {};
  public newinput: any[] = [];
  public issign: boolean;
  public docSignId: any;
  public docSignEncrptyId: any;
  public projectId: any;
  public themeData: string;
  public companyTemplate: string;
  public version: string;
  public merged: boolean = false;
  public fileURL: string;
  public newdesc: any[] = [];
  public isdesc: boolean = false;
  public isInitial: boolean = false;
  public boxposition = [];
  public isDate: boolean = false;
  public initial: any[] = [];
  public error: boolean = false;
  public fileUrl: Object;

  constructor(inject: Injector, public route: Router, public activatedRoute: ActivatedRoute, public ngxLoader: NgxUiLoaderService
    , public projectService: ProjectService, public ticketsService: TicketsService) {
    super(inject);
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {
    const docSignId = this.docSignEncrptyId = this.activatedRoute.snapshot.paramMap.get('id');
    // getting Docsign details
    this.getDocsignData(docSignId);
    this.version = COMMON.VERSION;
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
    this.companyTemplate = this.configService.companyTemplate;
    this.setWindowTitle('Utiliko Docsign');
  }
  getDocsignData(data) {
    this.ngxLoader.startLoader('loader-docsign');

    // Get particular docsign id data
    this.projectService.getDocSignByID(data).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        this.pdffileSrc = res.data[0].pdfPath;
        this.projectId = res.data[0].projectId;
        this.docSignId = res.data[0].docSignId;
        this.boxposition = res.data[0].boxposition;
        this.deposition = res.data[0].descposition;
        this.iniposition = res.data[0].initialposition;
        this.datposition = res.data[0].date;
        this.getpdfdata = res.data[0];
        this.ngxLoader.stopLoader('loader-docsign');
        this.fileURL = res.data.map(ele => ele.pdfPath.replace(/"/g, "'"));
        this.isSrcLoaded = true;
      }
      else {
        this.toastr.error(res.data.responseMsg);
        this.error = true;
      }
    });

  }
  ngOnDestroy() {

  }


  // Merge pdf and option which are selected
  mergePdf() {
    const docSignId = this.docSignId;
    const projectId = this.projectId;
    const pdfcreate = new JsPDF();
    const fileSize = [];
    const data = document.getElementById('pdfSend');
    this.ngxLoader.startLoader('loader-docsign');
    html2canvas(data).then(canvas => {
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      // let pdf = new jspdf('p', 'mm', 'a4', 1);
      const pdf = new JsPDF('p', 'mm', 'a4', true);

     const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST')
      const formData: FormData = new FormData();
      const file = pdf.output('blob');
      const nowday = new Date();
      const filename = "docsign" + nowday.getTime() + ".pdf";
      formData.append("directory", "Utiliko Test/project/The Kartik Shetty/project attachments");
      formData.append("file", file, filename);
      fileSize.push(file.size / 1000);
      this.ticketsService.uploadDocFile(formData).subscribe((res) => {
        if (res) {
          this.projectService.uploadAddDocsign(projectId, (<any>res).data.list[0].url, docSignId, this.arryposition, this.descposition, this.initialposition, this.dateposition, this.indexsign).subscribe((DocsignRes: any) => {
            if (DocsignRes.info.code === EStatusCode.OK) {
              this.toastr.success("Added Signature place  successfully");
              this.getDocsignData(this.docSignEncrptyId)
              this.merged = true;
              this.resetboolean();
              this.ngxLoader.stopLoader('loader-docsign');
            }
            else {
              this.toastr.error(DocsignRes.data.responseMsg);
              this.ngxLoader.stopLoader('loader-docsign');
            }
          });
        }
        else {
          this.toastr.error("Unable to upload file");
        }
      })
    });
  }

  //reset the booelan values
  resetboolean() {
    this.issign = false;
    this.isdesc = false;
    this.isInitial = false;
    this.isDate = false;
    this.position = true;
  }

  // capture the option popup
  openModal(Image, id, value) {
    const dialogRef = this.dialog.open(DocsignDialogPopup
      ,
      {
        data:
          { name: value, value: Image, }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (this.projectService.IsValue && value === 'write') {
        this.newdesc[id].desc = this.projectService.desc;
        this.fontRule = this.projectService.fontStyle;
      }
      if (this.projectService.isInitialValue && value === 'initial') {
        this.initial[id].initial = this.projectService.initial;
        this.fontRuleIntial = this.projectService.fontStyleInitial;
      }
      if (this.projectService.isImage && value === 'sign') {
        this.newinput.forEach(res => {
          if (res.id === id) {
            if (this.newinput[id].signatureImage) {
              this.newinput[id].signatureImage = this.newinput[id].signatureImage;
            }
            else {
              this.newinput[id].signatureImage = this.projectService.selected ? this.projectService.selected : '';
            }
            if (this.newinput[id].signatureImage === '') {
              this.isImage = false;
              this.IsValue = true;
              this.isInitialValue = true;
              this.newinput[id].selected = "";
              this.newinput[id].signatureImage = "";
            }
            else {
              this.isImage = this.projectService.isImage;
              this.IsValue = true;
              this.isInitialValue = true;
              this.newinput[id].selected = "";
            }

          }
          this.fontRule = this.projectService.fontStyle;
        })
      }
      if (this.projectService.isadminImage && value === 'adminsign') {
        this.newinput.forEach(res => {
          if (res.id === id) {

            if (res.adminsignatureImage) {
              this.newinput[id].adminsignatureImage = res.adminsignatureImage;
            }
            else {
              this.newinput[id].adminsignatureImage = this.projectService.adminselected ? this.projectService.adminselected : '';
            }
            if (this.newinput[id].adminsignatureImage === '') {
              this.isImage = false;
              this.IsValue = true;
              this.isInitialValue = true;
              this.newinput[id].adminselected = "";
              this.newinput[id].adminsignatureImage = "";
            }
            else {
              this.isImage = this.projectService.isadminImage;
              this.IsValue = true;
              this.isInitialValue = true;
              this.newinput[id].adminselected = "";
            }

          }
        })
      }
    });
  }

  onConvertFile() {
    const img: any = document.querySelector("#convertfile");

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdffileSrc = e.target.result;
      }
      reader.readAsArrayBuffer(img.files[0]);
    }
  }
  // pdf-viewer call editor
  callEditPdf(pdf: PDFDocumentProxy) {
    console.log(pdf.getData());
    // FIXME: do we really need this ??
    // console.log(pdf.dataLoaded);
  }

  // multiple signature for single click
  opensign() {
    if (this.getpdfdata.isOwner === 0) {
      this.issign = true;
      this.newinput.push({
        id: (this.newinput.length ? (this.newinput[this.newinput.length - 1].id + 1) : 0),
        selected: '',
        signatureImage: '',
        adminselected: '',
        adminsignatureImage: '',
      });

    }
    this.indexsign = this.newinput.length

  }

  // remove the signature box
  removeAddress(i: number) {
    this.newinput.splice(i, 1);
    this.arryposition.splice(i, 1);
    this.indexsign = this.indexsign - 1;
  }
  // multiple description for single click
  isdescript() {
    this.isdesc = true;
    this.newdesc.push({
      id: (this.newdesc.length ? (this.newdesc[this.newdesc.length - 1].id + 1) : 0),
      decs: '',
    });
  }
  // remove the description  box
  removedesc(i: number) {
    this.newdesc.splice(i, 1);
    this.descposition.splice(i, 1);
  }

  // multiple initial box for single click
  isinitial() {
    this.isInitial = true;
    this.initial.push({
      id: (this.initial.length ? (this.initial[this.initial.length - 1].id + 1) : 0),
      initial: '',
    })
  }
  // remove the initial box
  removeinitial(i: number) {
    this.initial.splice(i, 1);
    this.initialposition.splice(i, 1);
  }
  // Date box for click the icon
  isdate() {
    this.isDate = true;
  }

  // remove the date  box 
  removeDate() {
    this.isDate = false;
  }

  //autogrow of text area for description
  autogrow() {
    const textArea = document.getElementById("textarea")
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  //started form getting position for drag and drop options
  dragEnd(event: CdkDragEnd, value) {
    const prevalue = value;
    if (prevalue === value) {
      this.arryposition[value] = [];
      this.offset = { ...(<any>event.source._dragRef)._passiveTransform };
      this.arryposition[value] = this.offset

    }
    else {
      this.arryposition.push(this.offset);
    }


  }

  dragEnddesc(event: CdkDragEnd, value) {
    const prevalue = value;
    if (prevalue === value) {
      this.descposition[value] = [];
      this.offsetdesc = { ...(<any>event.source._dragRef)._passiveTransform };
      this.descposition[value] = this.offsetdesc;
    }
    else {
      this.descposition.push(this.offsetdesc)
    }
  }
  dragEndinitial(event: CdkDragEnd, value) {
    const prevalue = value;
    if (prevalue === value) {
      this.initialposition[value] = [];
      this.offsetini = { ...(<any>event.source._dragRef)._passiveTransform };
      this.initialposition[value] = this.offsetini;
    }
    else {
      this.initialposition.push(this.offsetini)
    }
  }
  dragEnddate(event: CdkDragEnd) {
    this.dateposition[0] = [];
    this.offsetdate = { ...(<any>event.source._dragRef)._passiveTransform };
    this.dateposition[0] = this.offsetdate


  }
  // end here for all option
}

