import { GlobalService } from './service/global.service';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, EMPTY, finalize, Observable, throwError } from 'rxjs';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';

import { NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import {
    AVOIDSPINNERAPI,
    REMOVESPINNERFORDASHBOARD,
    REMOVESPINNERFORDELETEMESSAGE,
    REMOVESPINNERFORFORM,
    REMOVESPINNERFORLISTMESSAGE,
    REMOVESPINNERFORMAILBOX,
    REMOVESPINNERFORTIMERS,
    StorageKey
} from './service/constant';
import { LoaderService } from './service/loader-service.service';
import { CurrentUser } from './admin/softphone/shared/models/current-user';
import { CurrentUserService } from './admin/softphone/shared/services/current-user.service';
import { ConfigService } from './service/config.service';
import { PbxAuthService } from './service/pbx-auth.service';
import { getCurrentRoute } from './service/Utils/common-function';
import { SessionPopupDialogService } from './service/session-popup-dialog.service';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HttpsRequestInterceptor implements HttpInterceptor {


    isRefreshingToken = false;
    public requests: HttpRequest<any>[] = [];
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public tokenForTimers = new BehaviorSubject(null);
    private checkURL: string;

    getFormName: any;
    private isPBXTokenRefreshed = false;
    private urlFlag = false;
    private tokenPopupFlag = false;

    constructor(
        private router: Router,
        private loaderService: LoaderService,
        private globalService: GlobalService, private currentUser: CurrentUserService,  private configService: ConfigService,
        private pbxService: PbxAuthService, private ngZone: NgZone,
        private sessionPopupDialogService: SessionPopupDialogService) {

        this.router.events.subscribe(
            event => {
                if (event instanceof NavigationStart) {
                    this.checkURL = event.url;
                }
            },
        );

        this.loaderService.clearRequests.subscribe((value: boolean) => {
            if (value) {
                this.requests = [];
                this.loaderService.clearRequests.next(false);
            }
        });

        this.getFormName = this.globalService.hideFormNameLoader.subscribe((val) => {
        });
        // if  route  match then set  flag  to  tokenpopup()
        this.urlFlag = getCurrentRoute();
    }

    // logout functionality  if user  reload the  application
    private logout() {
        localStorage.clear();
        window.location.href = '/login';
    }


    isTokenExpired(token) {
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        const expirationTimestamp = tokenData.exp * 1000;
        const expirationDate = new Date(expirationTimestamp);
        const currentDate = new Date();
        return currentDate.getTime() > expirationDate.getTime();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let request: HttpRequest<any>;
        const tokenExp = localStorage.getItem('accessToken');

        if (tokenExp === null) {
            this.tokenPopupFlag = false;
        } else {
            this.tokenPopupFlag = this.isTokenExpired(tokenExp);
        }
        const broadcastChannel = new BroadcastChannel('login-channel');
        broadcastChannel.onmessage = (event) => {
            if (event.data === 'reload') {
                window.location.reload();
                localStorage.removeItem('tokenExpired');
            }
        };
        this.pushRequestToList(req);
        if (req.url.indexOf(environment.API_ENDPOINT + '/Users/login') > 0) {
            request = req;
        } else {
            request = req;
            const token = localStorage.getItem(StorageKey.ACCESSTOKEN);
            const pbxUser = localStorage.getItem(`${environment.StoragePrefix}currentUser`);
            const currentUser: CurrentUser = pbxUser && JSON.parse(pbxUser);
            const isPBXLoggedIn: string = currentUser && currentUser.token;
            const isApiUrl = request.url.startsWith(environment.PBX_API);
            if (req.url.split('/')[2] === environment.BASE_URL.split('/')[2] || req.url.startsWith(window.origin)) {
                if (token) {
                    request = req.clone({
                        headers: new HttpHeaders({
                            'Access-Control-Allow-Origin': environment.BASE_URL + environment.API_ENDPOINT,
                            'Authorization': token
                        })
                    });
                } else {
                    request = req.clone({
                        headers: new HttpHeaders({
                            'Access-Control-Allow-Origin': environment.BASE_URL + environment.API_ENDPOINT
                        })
                    });
                }
            } else if (isPBXLoggedIn && isApiUrl) {
                request = request.clone({
                    headers: request.headers.set('X-Auth-Token', currentUser.token)
                });
            } else {
                const pbxtoken = localStorage.getItem('pbx_auth_token');
                if (pbxtoken) {
                    request = req.clone({ headers: req.headers.set('X-Auth-Token', pbxtoken) });
                }
            }
        }
        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if (request.url.includes('/monitor/errors')) {
                      this.removeRequest(req);
                      return EMPTY;
                    }

                    const isPBXAPICall = request.url.startsWith(environment.PBX_API);
                    if (isPBXAPICall) {
                        if ((<HttpErrorResponse>error).status === 401) {
                            if (!this.isPBXTokenRefreshed) {
                                this.updatePBXAuthToken();
                            }
                            this.removeRequest(req);
                        } else {
                            return throwError(() => error);
                        }
                    } else {
                        if ((<HttpErrorResponse>error).status === 419) {
                            if (!this.urlFlag) {
                                const checkUR = req.url.includes('/Users/userInitialDetails');
                                if (localStorage.getItem('tokenExpired') === 'true' && checkUR) {
                                    this.logout();
                                    localStorage.removeItem('tokenExpired');
                                } else {
                                    this.tokenExpireUser();
                                }
                            }
                        } else if ((<HttpErrorResponse>error).status === 401) {
                            this.removeRequest(req);
                            this.logoutUser();
                        } else {
                            this.removeRequest(req);
                            return throwError(() => error);
                        }
                    }
                } else {
                    this.removeRequest(req);
                    return throwError(() => error);
                }
            }),
            finalize(() => {
                this.removeRequest(req);
            })
        )
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: token } });

    }

    removeRequest(req: HttpRequest<any>) {
        if (this.checkSpinnerAvoidReq(req.url)) {
            const i = this.requests.indexOf(req);
            if (i >= 0) {
                this.requests.splice(i, 1);
            }
            this.loaderService.isLoading.next(this.requests.length > 0);
        }
    }

    logoutUser() {
        localStorage.clear();
        this.router.navigate(['/login']);
        return throwError(() => new Error(''));
    }

    tokenExpireUser() {
        const email = localStorage.getItem('email') || this.configService.userEmail;
        if (email) {
            this.sessionPopupDialogService.openDialog(email);
            throwError(() => new Error(''));
        } else {
            this.logoutUser();
        }
    }

    checkSpinnerAvoidReq(url: string, param?: any) {
        const request = [];
        request.push(...AVOIDSPINNERAPI, ...REMOVESPINNERFORTIMERS);
        if (param && param['paramD'] && (param['paramD'] === 'delete' || param['paramD'] === 'list')) {
            request.push(...REMOVESPINNERFORLISTMESSAGE);
        }
        if (param && param['check'] && (param['check'] === 'checked')) {
            request.push(...REMOVESPINNERFORDELETEMESSAGE);
        }
        if (this.checkURL === '/admin/dashboard') {
            request.push(...REMOVESPINNERFORDASHBOARD);
        }
        if (this.getFormName !== null) {
            request.push(...REMOVESPINNERFORFORM);
        }
        if (this.checkURL === '/admin/mailbox') {
            request.push(...REMOVESPINNERFORMAILBOX);
        }
        let isNotAvoidReq = true;
        request.forEach(data => {
            if (url.indexOf(data) > 0) {
                isNotAvoidReq = false;
            }
        });
        return isNotAvoidReq;
    }

    pushRequestToList(req: HttpRequest<any>) {
        if (this.checkSpinnerAvoidReq(req.urlWithParams, req.body) && this.tokenPopupFlag === false) {
            this.requests.push(req);
            this.loaderService.isLoading.next(true);
        } else {
        }
    }

    getJsonFromUrl(url) {
        if (!url) {
            url = location.search;
        }
        const query = url.substr(1);
        const result = {};
        query.split('&').forEach(function (part) {
            const item = part.split('=');
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }

    updatePBXAuthToken() {
        this.currentUser.reset();
        this.isPBXTokenRefreshed = true;
        this.pbxService.setAccountAndUser(this.pbxService.authenticate('webphone')).subscribe(
            (res: CurrentUser) => {
                this.isPBXTokenRefreshed = false;
            }, (err) => {
                this.isPBXTokenRefreshed = false;
            });
    }
}
