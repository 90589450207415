import {Component, Injector, Input, OnInit, OnDestroy} from '@angular/core';
import {AppComponentBase} from "../../../../shared/AppComponentBase";
import {Bug} from "../../../../../service/models/Bug";
import { EStatusCode } from '@milagro-ui-core';
import {COMMON} from '../../../../../service/constant';
import {ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import {BugsService} from "../../../../../service/bugs.service";
import { CommonDatePipe } from '../../../../shared/pipes/common-date.pipe';
import { SpinnerComponent } from '../../../../shared/spinner.component';
import { MatTooltip } from '@angular/material/tooltip';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';


@Component({
    selector: 'app-bugs-view',
    templateUrl: './bugs-view.component.html',
    styleUrls: ['./bugs-view.component.scss'],
    standalone: true,
    imports: [MatToolbar, NgClass, ExtendedModule, NgIf, MatTooltip, NgFor, SpinnerComponent, CommonDatePipe]
})
export class BugsViewComponent extends AppComponentBase implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public bugDetail: Bug;
  participantsInfo: any;
  @Input() isBugClientActive: number;
  private bugId: string;
  public version: string;
  public themeData: string;

  constructor(private injector: Injector , private route: ActivatedRoute , private bugsService: BugsService) {
    super(injector);
    this.bugDetail = new Bug();
    this.configService.updateIsUserLogged.next(false);

  }

  ngOnInit() {
    this.bugId = this.route.snapshot.paramMap.get('id');
    this.getBugDetails(this.bugId);
    this.version = COMMON.VERSION;
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }
  getBugDetails(data) {
    const viewADetails : Subscription = this.bugsService.viewDetails(data).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        const bugT: Bug = new Bug().deserialize(res.data);
        const str = JSON.stringify(res.data);
        this.bugDetail = JSON.parse(str);
        this.participantsInfo = res.data.users;
      } else {

      }
    });
    this.subscriptions.push(viewADetails);
  }

  clientDetails(client_id){
    if (this.isBugClientActive === 1) {
      this.toastr.error('Client is inactive or has been deleted, you are unable to make any changes, pls restore the Client first');
    } else {
      this.bugsService.bugDetail.msg = 'bugs-client';
      this.clientService.clientDetail.msg = 'bugs';
      this.bugsService.bugDetail.url = 'admin/client/details/' + client_id ;
      this.routerDialog.navigateByUrl('admin/client/details/' + client_id);
    }
  }

}
