import {Component, OnInit, ChangeDetectionStrategy, Injector, AfterViewInit} from '@angular/core';
import { TaskService } from './../../service/task.service';
import {trigger} from '@angular/animations';
import {AppComponentBase} from "../shared/AppComponentBase";
import { SharedService } from '../../service/shared.service';
import { TaskFormComponent } from './task-form/task-form.component';
import { TaskListComponent } from './task-list/task-list.component';
import { NgIf } from '@angular/common';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { SubHeaderComponent } from '../layouts/full/sub-header/sub-header.component';
@Component({
    selector: 'app-task',
    templateUrl: './task.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./task.component.css'],
    animations: [trigger('fadeInOutTranslate', [])],
    standalone: true,
    imports: [SubHeaderComponent, MatTabGroup, NgIf, MatTab, MatTabContent, TaskListComponent, TaskFormComponent]
})
export class TaskComponent extends AppComponentBase implements OnInit, AfterViewInit {
  update: boolean = true;
  setName: string = 'New Task';
  
  constructor(inject: Injector, private sharedService: SharedService, public taskService: TaskService) { super(inject);this.setWindowTitle('Task');}

  tabLabel: string;
  isChanged: boolean;

  ngOnInit() {
    this.isChanged = false;
    this.tabLabel = "New Task";
    this.sharedService.moduleNamesForFilter = 'task';
    this.sharedService.resetParticularModuleFilter();
  }

   changedTabLabel(strLable: any): void {
    this.tabLabel = strLable;
  }

  isTabChange(tab?) {
    this.isChanged = tab;
  }

  onLinkClick(event) {
    
    if (event.index === 0 ) {
      this.isChanged = false;
    }
  }

  ngAfterViewInit(): void {
    // Scroll to top by default
    this.elementRefValidator ? this.elementRefValidator.nativeElement.scrollIntoView() : '';
  }
}
