import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, isDevMode, NgModule } from '@angular/core';
import { provideRouter, RouterOutlet, withComponentInputBinding, withDebugTracing } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule, LocationStrategy, NgClass, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { provideAnimations } from '@angular/platform-browser/animations';

import { HttpLoaderFactory } from './admin/shared/http-loader.factory';
import { HttpsRequestInterceptor } from './app-request-handler.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ConfigService } from './service/config.service';
import { FileService } from './service/file.service';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { AppRoutes } from './app.routing';

import { withCoreProviders } from './core/core-providers';
import { AdminComponent } from './admin/admin.component';
import { CAPTURE_APPLICATION_ERROR_CONFIGURATION_TOKEN, CaptureApplicationErrorService } from '@milagro-ui-core';
import { COMMON } from './service/constant';


@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,

        NgClass,
        FlexLayoutModule,
        RouterOutlet,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        NgxUiLoaderModule,

        AdminComponent,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        provideAnimations(),
        provideRouter(AppRoutes, withDebugTracing(), withComponentInputBinding()),
        provideHttpClient(withInterceptorsFromDi()),
        provideCharts(withDefaultRegisterables()),
        FileService,
        withCoreProviders(),
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpsRequestInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (cs: ConfigService) => function () {
                return cs.initializeUserDetail();
            },
            deps: [ConfigService],
            multi: true
        },
        importProvidersFrom(TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
        })),
        {
          provide: CAPTURE_APPLICATION_ERROR_CONFIGURATION_TOKEN,
          useFactory: (configService: ConfigService) => ({
            endpoint: window.origin + '/monitor/errors',
            applicationName: 'utiliko-ui',
            environment: window.origin,
            version: COMMON.VERSION,
            getAppState: async () => {
              return {
                isUserLogged: configService.isUserLogged,
                userId: configService.userId,
                userFullname: configService.userFullname
              };
            },
          }),
          deps: [ConfigService],
        },
        { provide: ErrorHandler, useClass: CaptureApplicationErrorService }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
