import { GlobalService } from './../../../../service/global.service';
import { TsysService } from './../../../../service/tsys.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Component, OnInit, Inject, ElementRef, NgZone, inject, AfterViewInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ConfigService } from '../../../../service/config.service';
import { Subscription } from 'rxjs';
import { ToastMessages } from '../../../../service/constant';
import { EStatusCode } from '@milagro-ui-core';
import { ToastrService } from 'ngx-toastr';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDivider } from '@angular/material/divider';
import { PaymentInfoService } from 'utiliko-ui/src/app/service/payment-info.service';

@Component({
    selector: 'app-tsys-cc-dialog',
    templateUrl: './tsys-cc-dialog.component.html',
    styleUrls: ['./tsys-cc-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDivider, CdkScrollable, MatDialogContent, FormsModule, MatDialogActions, MatCheckbox, MatButton, MatDialogClose]
})
export class TsysCcDialogComponent implements OnInit, AfterViewInit {
  private readonly subscriptions: Subscription[] = [];
  public toastr: ToastrService = inject(ToastrService);

  currentStatus: number = 2;
  defaultCC: number = 1;
  billingZipcode: string = '';
  isDefaultAvailable: boolean = false;
  businessName: string;

  constructor(public dialogRef: MatDialogRef<TsysCcDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private elementRef: ElementRef, public tsysService: TsysService, public globalService: GlobalService, private zone: NgZone,public paymentInfoService: PaymentInfoService,
    public configService: ConfigService) {
    window['angularComponentDialogReference'] = {
      zone: this.zone,
      componentFn: (value) => this.reopenDialog(value),
      component: this,
    };

    window['angularComponentReference'] = {
      zone: this.zone,
      componentFn: (value) => this.updateTsysData(value),
      component: this,
    };
  }

  ngOnInit() {

    this.loadJs("../../../../../assets/js/tsys-tsep.js")

    // check the first time card availablity
    this.isDefaultAvailable = !!((this.data.paymentInfo.length > 0
      && this.data.paymentInfo[0].isDefaultAvailable));
  }

  loadJs(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  ngAfterViewInit() {
    this.loadScript(environment.TSYS_MANIFEST_URL + this.data.ubmDeviceId + "?" + this.data.manifest);
  }
  loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    this.elementRef.nativeElement.appendChild(node);

  }
  close() {
    const data = { isComplete: 0, reOpen: 0 };
    this.dialogRef.close(data);
  }
  reopenDialog(value) {
    const data = { isComplete: 0, reOpen: 1, errMsg: value.errorMsg };
    this.dialogRef.close(data);
  }

  addCC() {
    if (!this.billingZipcode || this.billingZipcode.length < 5) {
      this.toastr.error(ToastMessages.INVALID_ZIPCODE);
      return;
    }
      const ccData = {
        authData: this.tsysService.getTsysData(),
        userId: Number(this.configService.userId),
        isDefaultCard: Number(this.defaultCC),
        saveDefault: true
      };
      ccData.authData['zipCode'] = this.billingZipcode;
      ccData.authData.businessName = this.businessName;

      const checkCC: Subscription = this.paymentInfoService.tenantCCAuthentication(ccData).subscribe((res: any) => {

        if (res.info.code === EStatusCode.OK) {
          const data = { isComplete: 1, reOpen: 0, isSuccess: 1, response: res.data.responseMsg };
          this.dialogRef.close(data);
        } else {
          const data = { isComplete: 1, reOpen: 0, isSuccess: 0, response: res.data.responseMsg };
          this.dialogRef.close(data);
        }
      });
      this.subscriptions.push(checkCC);


  }

  updateTsysData(tsysData) {

    const cardNum = tsysData.card_number;
    this.billingZipcode = String(tsysData.zipCode || '');
    this.businessName = tsysData.businessName;
    this.tsysService.checkCardExists(cardNum).subscribe((res: any) => {
      if (res.result.info.code === EStatusCode.OK) {
        if (res.result.data.isExists == 1) {
          var data = { isComplete: 2 };
          this.dialogRef.close(data);
        } else {
          this.tsysService.updateTsysData(tsysData);
          this.currentStatus = 1;
        }
      } else {
        var data = { isComplete: 3 };
        this.dialogRef.close(data);
      }
    })
  }
  defaultCcCheck(event) {
    this.defaultCC = event.checked ? 1 : 0
  }
  SearchData(value: string, type: string) {
    if (type === 'zipcode') {
      this.billingZipcode = value
    } else {
      this.businessName = value;
    }
  }
}
