<div class="card testcaseForm">
  <form novalidate [formGroup]="createTestCaseForm" class="md-float-material form-material testcase-form" action="javascript:">
    
    <!-- Test case information -->
    <div class="col-md-12">
      <div class="col-md-2 testcase-sub-heading">
        <div class="card-block">
          <div class="form-group form-default">
            <span class="form-bar"></span>
            <label class="float-label field_label">Test Case Information</label>
          </div>
        </div>
      </div>
      
      <!-- Test case name -->
      <div class="col-md-10">
        <div class="card-block">
          <mat-form-field appearance="outline">
            <mat-label>Title</mat-label>
            <input matInput  placeholder="Title *" formControlName="testName" name="testName" required>
          </mat-form-field>
        </div>
      </div>
    </div>

    <hr>
    <div class="col-md-12">
      <div class="col-md-2 testcase-sub-heading"></div>

      <!-- Test case projects -->
      <div class="col-md-5 respUser">
        <div class="form-group form-default field_description">
          <div class="form-group form-default form-static-label">
            <ng-select bindLabel="label" appendTo="body" bindValue="value" required class=" custom-select" material  [items]="project" #projResp
              formControlName="projectName" placeholder="Project Type" (focus)="projResp.open()"
              customSearchEnabled="true" (change)="getprojectValue($event)" ngDefaultControl>
            </ng-select>
          </div>
        </div>
      </div>
              <!-- Test case responsible user -->
      <div class="col-md-5 respUser">
        <div class="form-group form-default field_description">
          <div class="form-group form-default form-static-label" *ngIf="isEditMode">
            <ng-select bindLabel="label" appendTo="body" bindValue="value" required class=" custom-select" material  [items]="responsibleUserId" #responsibleid
              formControlName="responsibleUserId" placeholder="Responsible User *" (focus)="responsibleid.open()"
              customSearchEnabled="true" (change)="getSelectUser($event)" [ngClass]="selectUserIdUpdate === true ? 'errorClass' :'noError'">
            </ng-select>
          </div>
          <div class="form-group form-default form-static-label" *ngIf="!isEditMode">
              <ng-select appendTo="body" bindLabel="label" bindValue="value" required class=" custom-select" material  [items]="responsibleUserId"#responsibleid
              formControlName="responsibleUserId" placeholder="Responsible User *"(focus)="responsibleid.open()"
              customSearchEnabled="true" (change)="getSelectUser($event)" [ngClass]="selectUserIdUpdate === true ? 'errorClass' :'noError'" [(ngModel)]="responsibleUserIdB">
            </ng-select> 
            
          </div>
        </div>
      </div>
     </div>

    <div class="col-md-12">
      <div class="col-md-2 testcase-sub-heading"></div>
      
      <!-- Test case Module Name -->
      <div class="col-md-5 relatedName field_description">
        <div class="form-group form-default" >    
            <ng-select [items]="relatedtoarr" bindLabel="label" appendTo="body" bindValue="value" material class="custom-select"#relatedTo formControlName="relatedtomoduleMainMod" 
            name="relatedtomoduleMainMod"(change)="getModuleNameDDClick($event)" placeholder=" Module Name * "  (change)="getSelectedValue($event)" ngDefaultControl ></ng-select>
        </div>
      </div>

      <!-- Test case Sub module name -->
      <div class="col-md-5  bot3">
        <div class="form-group form-default field_description">
          <div class="form-group form-default" >    
            <ng-select  bindLabel="label" appendTo="body" bindValue="value" material class="custom-select" formControlName="relatedtomodule" [items]="submodulearr"
            name="relatedtomodule" placeholder="  Sub Module Name " (change)="subModuleSelect($event)"></ng-select>
        </div>
        </div>
      </div>

    </div>

    <div class="col-md-12">
      <div class="col-md-2 testcase-sub-heading"></div>

      <!-- Test case type -->
      <div class="col-md-3 respUser" style="width: 295px;">
        <div class="form-group form-default field_description">      
          <div class="form-group form-default" >    
            <ng-select  bindLabel="label"  appendTo="body" bindValue="value" material class="custom-select"#notproject formControlName="typemodule" [items]="testcaseTypeData" (focus)="notproject.open()"
            name="relatedtomodule" placeholder="Type" (change)="typeSelect($event)" ngDefaultControl ></ng-select>
        </div>

        </div>
      </div>

      <!-- Test case Priority -->
      <div class="col-md-3 bot3" style="width: 245px;">
        <div class="form-group form-default m-b-10">
          <ng-select bindLabel="label"  appendTo="body" bindValue="value" material class="custom-select"
                     [items]="testcasePriorityData" 
                     formControlName="testcasePriority"
                     placeholder="Priority" (change)="getSelectPriority($event)" [required]="true"
                     [ngClass]="selectPriority === true ? 'errorClass' :'noError'"></ng-select>
        </div>
      </div>
      <!-- Test case Status -->
      <div class="col-md-3 bot3" style="width: 275px;">
        <div class="card-testcasesection">
          <div class="form-group form-default ">            
            <ng-select bindLabel="label"  appendTo="body" bindValue="value" class=" custom-select" material  [items]="testcaseStatus"#testcasestatusList
              formControlName="testcaseStatus" required placeholder="Status" [ngClass]="selecttestcaseStatusUpdate === true ? 'errorClass' :'noError'" (change)="filterChangeStatus($event)"(focus)="testcasestatusList.open()" ngDefaultControl>
            </ng-select>
           
          </div>
        </div>
      </div>
    </div>

    <hr>
        <div class="col-md-12">
          <div class="col-md-2 testcase-sub-heading"></div>       

          <!-- Test Case Steps reproduce -->
          <div class="col-md-5 ">
            <div class="card-block">
              <div class="form-group form-default ">            
                <mat-form-field appearance="outline" class="">
                  <mat-label>Step to reproduce</mat-label>
                  <textarea matInput placeholder="Step to reproduce*" formControlName="stepreproduce" rows="6" required></textarea>
                </mat-form-field>
               
              </div>
            </div>
          </div>

          <!-- Test Case Expected Result -->
        <div class="col-md-5">
          <div class="card-block">
            <div class="form-group form-default ">            
              <mat-form-field appearance="outline" class="">
                <mat-label>Expected Result</mat-label>
                <textarea matInput placeholder="Expected Result*" formControlName="expectedresult" rows="6" required></textarea>
              </mat-form-field>
             
            </div>
          </div>
        </div>
      </div>    

    <hr>

    <!-- Test case message information -->
    <div class="col-md-12">
      <div class="col-md-2 testcase-sub-heading testcase">
        <div class="testcaselabel">
          <div class="card-testcasesection">
            <div class="form-group form-default">
              <span class="form-bar"></span>
              <label class="float-label field_label">Description</label>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Test case description -->
      <div class="col-md-10 testcase">
        <div class="card-tasksection task-description-row"> 
          <div class="form-group form-default form-static-label"> 
             <textarea appTinymceConfig [tinymceSettings]="config" name="testcaseDescription" formControlName="testcaseDescription" id="testcaseDescription"></textarea>
          </div>
        </div> 
      </div>
    </div>

    <hr>

    <!-- Test case participants -->
    <div class="col-md-12 card-block testcase-participant-Style">
      <div class="col-md-2 testcase-sub-heading">
        <div class="testcaselabel">
          <div class="form-default">
            <span class="form-bar"></span>
            <label class="float-label field_label">Participants</label>
          </div>

        </div>
      </div>
      <div class="col-md-10 testcase">
        <div class=" form-default form-static-label">
          <div class="particpants_options">
              <mat-radio-group formControlName="permission" ngModel>
                  <mat-radio-button [value]=1 (change)="radioChange($event)" [checked]="radioValue == 1" required>Myself</mat-radio-button>
                  <mat-radio-button [value]=2 (change)="radioChange($event)" [checked]="radioValue == 2" required>
                    Everyone</mat-radio-button>
                  <mat-radio-button [value]=3 (change)="radioChange($event)" [checked]="radioValue == 3" required>Custom</mat-radio-button>
                </mat-radio-group>
                <!-- <a class="tooltipicon"><i class="fas fa-question-circle"></i></a>
               <div class="tooltiplabel">Participants<br>Myself<br>Everyone<br>Custom</div> -->
          </div>

              <div class="custom_dropdown bottom_dropdown" *ngIf="visibleCustomeFields">
                  <ng-select bindLabel="label" appendTo="body" bindValue="value" [items]="dropArr" name="salesRep" material (change)="updateSalesRep()" class="custom-select custom-select-style"
                     multiple="true" formControlName="customParticipants" #salesRep (focus)="salesRep.open()"
                     placeholder="Select Users*" id="salesRep"[ngClass]="salesrepCheck === true ? 'errorClass' :'noError'">
                  </ng-select>
                </div>
        </div>
       </div>
    </div>


    <!-- Test case button -->
    <div class="col-md-12">
      <div class="col-md-12" align="right">
        <div class="card-block m-b-15">
          <button mat-raised-button *ngIf="!isEditMode" name="edittestcase" (click)="saveTestCase()" class="btn-submit form-button-40">Create test case</button>
          <button mat-raised-button *ngIf="isEditMode" name="edittestcase" (click)="updateTestCase()" class="btn-submit form-button-40">Update test case</button>
          <button mat-raised-button  type="reset" (click)="cancelForm()" class="btn-cancel form-button-40" name="canceltestcase">Cancel</button>
        </div>
      </div>
    </div>

  </form>

</div>
