import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery';

import { ConfigService } from '../../../../../service/config.service';
import { TicketsService } from '../../../../../service/tickets.service';
import { TestcaseViewAttachmentComponent } from '../../testcase-view-attachment/testcase-view-attachment.component';
import { forkJoin } from "rxjs";
import { AlertCommonDialogData, IResponse } from '../../../../../service/Utils/Interfaces.class';
import { BugsService } from '../../../../../service/bugs.service';
import ImageCompressor from 'image-compressor.js';
import { Attachment } from '../../../../../service/models/Attachment';
import { CommonDialogModelComponent } from '../../../../../admin/shared/common-dialog-model/common-dialog-model.component';
import { COMMON } from '../../../../../service/constant';
import { ToastrService } from 'ngx-toastr';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { NgxGalleryComponent as NgxGalleryComponent_1 } from '../../../../../../../../libs/third-party-deprecated/src/lib/ngx-gallery/ngx-gallery.component';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { NgFor, NgIf } from '@angular/common';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { EStatusCode } from '@milagro-ui-core';

@Component({
    selector: 'app-testcase-attachments',
    templateUrl: './testcase-attachments.component.html',
    styleUrls: ['./testcase-attachments.component.scss'],
    standalone: true,
    imports: [MatTabGroup, MatTab, MatAccordion, NgFor, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatTooltip, NgIf, FormsModule, ReactiveFormsModule, MatFormField, MatInput, MatLabel, MatButton, NgxGalleryComponent_1]
})
export class TestcaseAttachmentsComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  mode :string = 'add';
  title :string = 'New'
  public leaveAppTitle: string;
  attachmentListArr = [];
  allAttachments = [];
  attachmentForm: FormGroup;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] =[];
  listGalleryImages: NgxGalleryImage[] =[];
  @ViewChild('onlyPreviewGallery') onlyPreviewGallery: NgxGalleryComponent;
  @ViewChild('listPreviewGallery') listPreviewGallery: NgxGalleryComponent;
  fileCount: number = 0;
  public totalCompressedImg = [];
  public totalFileName = [];
  tenantName:any;
  userDetails:any;
  taskAttachmentId:any;
  folder:any;
  fileList :any = []
  fileUrl:any;
  @Input() viewType: string;
  modulesName:string = 'testCases'
  data:any;
  fileAttachmets: any;
  public leaveAppDescription: string;
  loginUserName:any;
  public _albums: Array<any> = [];
  public list_albums: Array<any> = [];
  @ViewChild('myFileInput') myFileInput: ElementRef;
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;
  indexExpanded: number = -1;
  @Input() testCaseId: number;

  constructor(public fb: FormBuilder, public dialog: MatDialog,
    public configService: ConfigService,public ticketsService: TicketsService,public bugsService: BugsService) { }

  ngOnInit() {
    this.tenantName = this.configService.tenantName;
    this.loginUserName = this.configService.loginUserName;
    this.galleryOptions = [
      {
        image: false,
        thumbnails: false,
        width: '0px',
        height: '0px'
      },
      {
        breakpoint: 500,
        width: '300px',
        height: '300px',
        thumbnailsColumns: 3
      },
      {
        breakpoint: 300,
        width: '50%',
        height: '200px',
        thumbnailsColumns: 2
      }
    ];
    this.attachmentForm = this.fb.group({
      title: [],
      description: []
    });
    this.getAttachmentsList();
  }

  onTabChange(event){
    if(this.tabGroup.selectedIndex !== 1 && this.mode == 'edit'){
          this.mode = 'add';
    }

    if(this.tabGroup.selectedIndex == 0){
      this.getAttachmentsList();
    }
  }

  expand(attachment, attachmentId, index) {
    this.indexExpanded = -1;
    this.indexExpanded = index == this.indexExpanded ? -1 : index;

    const fileData = this.reformatResponse(attachment);
    this.allAttachments = fileData.fileattachement;

    this.fileCount = this.allAttachments.length;
    this.list_albums = this.allAttachments.map((element, index) => {
      const _object = {};
      if(element.ext === ".jpg" || element.ext === ".jpeg" || element.ext === ".png" || element.ext === "jpg" || element.ext === "jpeg" || element.ext === "png") {
        _object['src'] = element.uploadedPath;
        _object['caption'] = element.fileName;
        _object['thumb'] = element.uploadedPath;
        return _object;
      }else {
        return null;
      }
    }).filter((e) => e !== null);

    this.listGalleryImages = this.allAttachments.map((element, index) => {
      return (element.ext === ".jpg" || element.ext === ".jpeg" || element.ext === ".png" || element.ext === "jpg" || element.ext === "jpeg" || element.ext === "png") ?  { small: element.uploadedPath, medium: element.uploadedPath, big: element.uploadedPath } : null;
    }).filter((e) => e !== null);
  }

  onSelectFile(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const filesAmount = fileInput.target.files.length;
      this.fileCount = this.fileCount + filesAmount;
      if (this.fileCount > 10) {
        if (this.totalCompressedImg.length === 0) {
          this.fileCount = 0;
        } else {
          this.fileCount = this.totalCompressedImg.length;
        }
        this.toastr.error('Maximum 10 files are allowed to upload');
      } else {
        this.getImgAttachmentData(fileInput.target.files, this.totalCompressedImg.length,filesAmount).then(result=>{
          this.myFileInput.nativeElement.value = '';
        });
      }
    }
  }

  /*get compressed image on select file*/
  getCompressedImage(file) {
    return new Promise((resolve, reject) => {
      if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
        new ImageCompressor(file, {
          quality: .5,
          success(result: any) {
            resolve(result);
          },
          error(e) {
            console.log(e.message);
          },
        });
      } else {
        resolve(file);
      }
    });
  }

  /* get preview images data after file select*/
  getAlbumData(files, reader) {
    let albumImg = {};
    if (files.type === 'image/png' || files.type === 'image/jpg' || files.type === 'image/jpeg') {
      albumImg = {
        src: reader,
        caption: files.name,
        thumb: reader,
        type: files.type
      };
    } else {
      albumImg = {
        src: '',
        caption: '',
        thumb: '',
        type: ''
      };
    }
    return albumImg;
  }

  async getImgAttachmentData(files, totalFileCount, filesAmount){
    for (let i = 0; i < filesAmount; i++) {
      const selectedFiles = files[i];
      const reader = new FileReader();
      reader.onload = (e: Event) => {
        this.totalFileName.push(reader.result);
        this.getCompressedImage(selectedFiles).then((resFile: any) => {
          const album = this.getAlbumData(resFile, reader.result);
          resFile.extension = resFile.name.substr(resFile.name.lastIndexOf('.') + 1);
          resFile.isEdit = false;
          this._albums[totalFileCount + i] = album;
          if (this._albums[totalFileCount + i].src) {
            this.galleryImages.push({
              small: this._albums[totalFileCount + i].src,
              medium: this._albums[totalFileCount + i].src,
              big: this._albums[totalFileCount + i].src,
              description: this._albums[totalFileCount + i].caption
            });
          }else {
            this.galleryImages.push({
              small: '',
              medium: '',
              big: '',
              description: ''
            });
          }
          this.totalCompressedImg[totalFileCount + i] = resFile;
        });
      }
      reader.readAsDataURL(selectedFiles);
    }
  }

  onSubmitAttachments(action,type){
      this.userDetails = this.tenantName + '/test-cases/' + this.loginUserName + '/test-cases attachments';
      const uploadFile = "";
      this.data = {
        moduleId: Number(this.testCaseId),
        type: String(this.modulesName),
        title: this.attachmentForm.value.title ? this.attachmentForm.value.title : this.leaveAppTitle,
        description: this.attachmentForm.value.description ? this.attachmentForm.value.description : this.leaveAppDescription,
        files: String(uploadFile)
      };
      this.folder = {
        dir: this.userDetails
      };
      const observables = [];
      const fileSize = [];
      let upSize;
      const folder = { directory: this.userDetails };
      for (let fileIndex = 0; fileIndex < this.totalCompressedImg.length; fileIndex++) {
        const formData: FormData = new FormData();
        if (!this.totalCompressedImg[fileIndex].isEdit) {
          const fileDetails = this.totalCompressedImg[fileIndex];
          const file: File = <File>fileDetails;
          const filename = fileDetails.name;
          formData.append("directory", this.folder.dir);
          formData.append("file", file, filename);
          formData.append('type', 'public');
          fileSize.push(file.size / 1000);
          observables.push(this.ticketsService.uploadFile(formData));
        }
      }
      const fileNameArr: any = [];
      forkJoin(observables).subscribe(
        (res: any) => {
          for (let index = 0; index < res.length; index++) {
            if (res[index].info.code === EStatusCode.OK) {
              fileNameArr.push(res[index].data.list[0]);
              this.fileUrl = res[index].data.list[0].url;
            }
          }
        },
        error => console.log("Error: ", error),
        () => {
          if (fileNameArr.length > 0) {
            let upfile, url, upSize;
            upfile = fileNameArr.map(function (ele) {
              return ele.fileName;
            }).join(",");
            url = fileNameArr.map(function (ele) {
              return ele.url;
            }).join(",");
            upSize = fileSize.map(function (ele) {
              return ele;
            }).join(",");

            this.data.files = String(upfile);
            this.data.url = String(url);
            this.data.size = String(upSize);
            this.data.dir = this.userDetails;

          }
          if (action === 'add') {
              this.bugsService.addAttachments(this.data).subscribe(
                (res: any) => {
                  if (res.id.info.code === EStatusCode.OK) {
                      this.toastr.success("File uploaded successfully");
                      this.attachmentForm.reset();
                      this.totalCompressedImg = [];
                      this._albums = [];
                      this.galleryImages = [];
                      this.tabGroup.selectedIndex = 0;
                  }
                },
                error => {
                });
            // }
          }else if (action === 'update') {
              this.bugsService.updateAttachments(this.taskAttachmentId, this.data).subscribe(
                (res: any) => {
                  if (res.info.code === EStatusCode.OK) {
                    this.toastr.success("File uploaded successfully");
                    this.attachmentForm.reset();
                    this.totalCompressedImg = [];
                    this._albums = [];
                    this.galleryImages = [];
                    this.tabGroup.selectedIndex = 0;
                  } else {
                    this.toastr.error(res.data.responseMsg);
                  }
                },
                error => {
                });
          }
        
      })
   }



  getAttachmentsList() {
    this.attachmentListArr = [];
    let reqData;
    reqData = {
      moduleId: this.testCaseId,
      type: 'testCases'
  }
  this.bugsService.bugFileAttchments(reqData).subscribe((res: any) => {
    if (res.info.code === EStatusCode.OK) {
      if (res.data.length > 0) {
        res.data.map((attachment: Attachment) => {
          const bugAttachmentT: Attachment = new Attachment().deserialize(attachment);
          this.attachmentListArr.push(bugAttachmentT);
        });
      }
    }
  })
}

  // get attachments details by attachment id
  getAttachmentData(id) {
    this.mode = 'edit';
    this.taskAttachmentId = id
    this.tabGroup.selectedIndex = 1;
    let  fileObj, album;
    this.bugsService.getAttachmentsById(id).subscribe((response: IResponse) => {

      if (response.info.code === EStatusCode.OK) {
        this.fileAttachmets = response.data[0];
        this.fileList = this.fileAttachmets.fileattachement;

        this.totalCompressedImg = this.fileList.map(file => {
          return {
            extension: file.ext,
            isEdit: true,
            name: file.fileName,
            url: file.uploadedPath,
            uploadedFileId: file.uploadedFilesId,
            filePath: file.files
          }
        });

        this._albums = this.fileList.map((file) => {
          return file.isImage === 1 ? { src: file.uploadedPath, caption: file.fileName, thumb: file.uploadedPath, type: file.ext } : { src: '', caption: '', thumb: '', type: '' };
        }).filter((file) => file !== null);

        this.galleryImages = this.fileList.map((file) => {
          return file.isImage === 1 ? { small: file.uploadedPath, medium: file.uploadedPath, big: file.uploadedPath, description: file.fileName } : { small: '', medium: '', big: '', description: '' };
        }).filter((file) => file !== null);

        this.attachmentForm.patchValue({
          title: this.fileAttachmets.title,
          description: this.fileAttachmets.description,
          email: this.fileAttachmets.email,
        });
      } else {
        this.totalCompressedImg = [];
      }
    });
  }
  removeMultipleFiles(index) {
    const self = this;
    let isImageDelete = false, fileDetails;
    fileDetails = this.totalCompressedImg[index];
    this.removeUploadedFile(fileDetails).then(function (response) {
      self.totalCompressedImg.splice(index, 1);
      self.totalFileName.splice(index, 1);
      self.fileCount = self.totalCompressedImg.length;
      const deletedFile = self._albums.splice(index, 1);
      if (deletedFile[0]['src'] !== '') {
        self.galleryImages.forEach(function (value, galleryIndex) {
          if ((deletedFile[0]['caption'] === value['description']) && !isImageDelete) {
            isImageDelete = true;
            self.galleryImages.splice(galleryIndex, 1);
          }
        });
      }
    }).catch(function (error) {
      this.toastr.error(error);
    });
    this.myFileInput.nativeElement.value = '';
  }

  /* remove uploaded files from preview */
  removeUploadedFile(fileData) {
    return new Promise((resolve, reject) => {
      if (fileData.isEdit) {
        const fileName = fileData.filePath.replace('-/', '');
        this.ticketsService.attachmentsDeleteCloud(fileName).subscribe((res: IResponse) => {
          if (res.info.code === EStatusCode.OK) {
            this.bugsService.deleteUploadFile(fileData.uploadedFileId).subscribe((response: IResponse) => {
              if (res.info.code === EStatusCode.OK) {
                resolve('success');
              } else {
                reject('Failed to delete uploaded file');
              }
            });
          } else {
            reject('Failed to delete uploaded file');
          }
        });
      } else {
        resolve('success');
      }
    });
  }

  deleteAttachments(attachments) {
      const fileNames = attachments.fileattachement.map(function (value) {
        return value.files;
      }).join(",");
      const alertCommonDialogData: AlertCommonDialogData = {
        title: 'Confirm Delete',
        message: 'You are about to delete a record. This cannot be undone. Are you sure?',
        submitButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      };
      const dialogRef = this.dialog.open(CommonDialogModelComponent, {
        width: COMMON.DELETE_ALERT_WIDTH,
        height: COMMON.DELETE_ALERT_HEIGHT,
        data: alertCommonDialogData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.ticketsService.attachmentsDeleteCloud(fileNames).subscribe((res: IResponse) => {
            if (res.info.code === EStatusCode.OK) {
              this.bugsService.deleteAttachments(attachments.taskAttachmentId, this.modulesName, this.testCaseId).subscribe((response: any) => {
                if (response.info.code === EStatusCode.OK) {
                  this.toastr.success('Attachment deleted successfully');
                  this.getAttachmentsList();
                } else {
                  this.toastr.error('Failed to delete attachment');
                }
              }, error => {
                this.toastr.error('Failed to delete attachment. Try again later.!');
              });
            } else {
              this.toastr.error('Failed to delete attachment');
            }
          }, error => {
            this.toastr.error('Failed to delete attachment. Try again later.!');
          });
        }
      });
  }

  open(fileName: string) {
    const newIndex = this.getImageIndex(this.list_albums, fileName);
    this.listPreviewGallery.openPreview(newIndex);
  }

  showImage(fileName){
    const newIndex = this.getImageIndex(this._albums, fileName);
    this.onlyPreviewGallery.openPreview(newIndex);
  }

  getImageIndex(albumList, fileName) {
    let albumIndex = 0;
    albumList.every(function (albumImg, index) {
      if (albumImg && albumImg.caption === fileName) {
        albumIndex = index;
        return false;
      }
      return true;
    });

    return albumIndex;
  }

  deleteUploadFile(id, file) {
    const fileName = file.replace('-/', '');
    const alertCommonDialogData: AlertCommonDialogData = {
      title: 'Confirm Delete',
      message: 'You are about to delete a record. This cannot be undone. Are you sure?',
      submitButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    };
    const dialogRef = this.dialog.open(CommonDialogModelComponent, {
      width: COMMON.DELETE_ALERT_WIDTH,
      height: COMMON.DELETE_ALERT_HEIGHT,
      data: alertCommonDialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ticketsService.attachmentsDeleteCloud(fileName).subscribe((res: IResponse) => {
          if (res.info.code === EStatusCode.OK) {
            this.bugsService.deleteUploadFile(id).subscribe((response: IResponse) => {
              if (response.info.code === EStatusCode.OK) {
                this.toastr.success('Attachment deleted successfully');
                this.getAttachmentsList();
              } else {
                this.toastr.error('Failed to delete attachment');
              }
            }, error => {
              this.toastr.error('Failed to delete attachment. Try again later.!');
            });
          } else {
            this.toastr.error('Failed to delete attachment');
          }
        }, error => {
          this.toastr.error('Failed to delete attachment. Try again later.!');
        });
      }
    });
  }

  reformatResponse(data) {
    if (data && data.fileattachement) {
      data.fileattachement.forEach(item => {
        item["userId"] = data.userId;
        item["uploadTime"] = data.uploadTime;
        item.ext = item.ext.substring(0, 1) === '.' ? item.ext.toLowerCase() : '.' + item.ext.toLowerCase();
      });
    }
    return data;
  }

  viewFiles(url, fileName){
    const type = this.ifVideo(fileName) ? 'video' : (this.ifDocument(fileName) ? 'document' : '');
    const dialogRef = this.dialog.open(TestcaseViewAttachmentComponent, {
      width: "900px",
      height: "600px",
      data: {url: url, type: type}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ifVideo(ele){
    const ext = this.getExt(ele);
    return (ext === 'webm' ||
      ext === 'mpg' ||
      ext === 'mp2' ||
      ext === 'mpeg' ||
      ext === 'mpe' ||
      ext === 'mpv' ||
      ext === 'ogg' ||
      ext === 'mp4' ||
      ext === 'avi' ||
      ext === 'wmv' ||
      ext === 'mov' ||
      ext === 'qt' ||
      ext === 'flv' ||
      ext === 'swf' ||
      ext === 'avchd' ||
      ext === 'm4p' ||
      ext === 'm4v');
  }

  ifDocument(ele){
    const ext = this.getExt(ele);
    return (ext === 'pdf' ||
      ext === 'doc' ||
      ext === 'docx' ||
      ext === 'xls' ||
      ext === 'xlsx' ||
      ext === 'csv' ||
      ext === 'ppt' ||
      ext === 'pptx' ||
      ext === 'txt');
  }

  getExt(name){
    return name.split('.').pop().toLowerCase();
  }
  cancel(){
    this.attachmentForm.reset();
    this.totalCompressedImg = [];
    this._albums = [];
    this.galleryImages = [];
    this.tabGroup.selectedIndex = 0;
  }
}
