import {
    ChangeDetectorRef,
    Component, DestroyRef, inject,
    Injector,
    OnDestroy,
    OnInit,
    signal,
    WritableSignal
} from '@angular/core';
import { IResponse } from '../../../service/Utils/Interfaces.class';

import { interval, Subscription } from 'rxjs';
import { TaskListComponent } from '../../task/task-list/task-list.component';
import { AppComponentBase } from '../AppComponentBase';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { BugsService } from '../../../service/bugs.service';
import { OpportunitiesService } from '../../../service/opportunities.service';
import { TicketsService } from '../../../service/tickets.service';
import { ProjectService } from '../../../service/project.service';
import { VendorService } from '../../../service/vendor.service';
import { TaskService } from '../../../service/task.service';
import { TimerclockinclockoutService } from '../../../service/timerclockinclockout.service';
import { SOCKET_KEY } from '../../../service/constant';
import { SocketService } from '../../../service/socket.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonDatePipe } from '../pipes/common-date.pipe';
import {
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle
} from '@angular/material/expansion';
import { MatTooltip } from '@angular/material/tooltip';
import { MatBadge } from '@angular/material/badge';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, UpperCasePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { EStatusCode } from '@milagro-ui-core';

@Component({
    standalone: true,
    selector: 'app-task-timer',
    templateUrl: './task-timer.component.html',
    styleUrls: ['./task-timer.component.scss'],
    imports: [
        NgClass,
        ExtendedModule,
        MatBadge,
        MatTooltip,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        UpperCasePipe,
        CommonDatePipe,
        DragDropModule
    ],
    providers: [TaskListComponent]
})
export class TaskTimerComponent extends AppComponentBase implements OnInit, OnDestroy {
    readonly #destroyRef: DestroyRef = inject(DestroyRef);
    readonly #cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

    datasource = new MatTableDataSource<any>();
    public taskId: any;
    address: string;
    clockTimerStatus: any = '';
    timerCount: number;
    public taskDetails: [];
    timerArray = [];
    projectId: number;
    start = 0;
    ticks = 0;
    moduleName: string;
    public show: boolean = true;
    timerDragged: boolean = false;
    protected readonly panelOpenState: WritableSignal<boolean> = signal(false);

    private readonly subscriptions: Subscription[] = [];
    loginDevice: number = 0;

    sub: Subscription[] = [];

    constructor(
        injector: Injector,
        private timerClockInClockOut: TimerclockinclockoutService,
        private taskService: TaskService,
        private projectService: ProjectService,
        private vendorService: VendorService,
        private socketService: SocketService,
        private ticketsService: TicketsService,
        private bugsService: BugsService,
        public opportunitiesService: OpportunitiesService,
    ) {
        super(injector);

        // Sync timer for all active clients
        this.socketService.listen(SOCKET_KEY.TIMERSTART).pipe(
            filter(Boolean),
            takeUntilDestroyed(this.#destroyRef)
        ).subscribe(() => {
            this.getTimerOnList();
        });
    }

    ngOnInit() {
        this.getTimerOnList();
    }

    ngOnDestroy() {
        this.stopSubscription();
    }

    dragStart() {
        this.timerDragged = true;
    }

    getTimerOnList() {
        this.timerClockInClockOut.getTimerOnList().subscribe((data: any) => {
            if (data.info.code === EStatusCode.OK) {
                if (data.data.length > 0) {
                    this.timerArray = data.data;
                    this.timerCounter();
                } else {
                    this.timerArray = [];
                }
            } else {
                this.timerArray = [];
            }

            this.timerCount = this.timerArray.length;
            this.#cdr.detectChanges();
        });
    }

    timerCounter() {
        this.stopSubscription();

        for (let index = 0; index < this.timerArray.length; index++) {
            // by default set false for stopping timer disable action
            this.timerArray[index].isStop = false;

            if (this.timerArray[index].relatedTo === 'task' && this.timerArray[index].taskId) {
                this.taskService.detailsById(this.timerArray[index].taskId).subscribe((res: IResponse) => {
                    if (res.info.code === EStatusCode.OK) {
                        this.timerArray[index]['startTime'] = res.data[0]['startTime'];
                        this.addTimer(this.timerArray[index].relatedTo, this.timerArray[index].taskId, index);
                    }
                });

            } else if (this.timerArray[index].relatedTo === 'project' && this.timerArray[index].projectId) {
                this.projectService.getProjectById(this.timerArray[index].projectId).subscribe((res: IResponse) => {
                    if (res.info.code === EStatusCode.OK) {
                        this.timerArray[index]['startTime'] = res.data[0]['startTime'];
                        this.addTimer(this.timerArray[index].relatedTo, this.timerArray[index].projectId, index);
                    }
                });

            } else if (this.timerArray[index].relatedTo == 'ticket' && this.timerArray[index].ticketsId) {
                this.ticketsService.getTicketsById(this.timerArray[index].ticketsId).subscribe((res: IResponse) => {
                    if (res.info.code === EStatusCode.OK) {
                        this.timerArray[index]['startTime'] = res.data[0]['startTime'];
                        this.addTimer(this.timerArray[index].relatedTo, this.timerArray[index].ticketsId, index);
                    }
                });
            }
        }
    }

    addTimer(type, id, index) {
        const timeZone = this.configService.timezone;

        const subscription = interval(1000).subscribe(t => {
            this.ticks = t;

            if (this.timerArray[index]) {
                const dateIn = new Date();
                const time = Math.round(dateIn.getTime() / 1000);

                // update startTime on 12 AM
                const currentTime = moment().utcOffset(momenttimezone.tz(moment(), timeZone).format('Z')).format('HH:mm:ss');

                const nextDate = moment(momenttimezone.tz((this.timerArray[index]['startTime'] * 1000), timeZone)).add(1, 'd').format('YYYY-MM-DD');
                const newStartTime = moment(momenttimezone.tz(nextDate + ' 00:00:00', timeZone).utc().format('YYYY-MM-DD' +
                    ' HH:mm:ss')).unix();

                if (currentTime === '00:00:00') {
                    this.timerArray[index]['startTime'] = newStartTime;
                }

                if (currentTime === '00:02:00') {
                    // Refresh timer list
                    this.getTimerOnList();
                }

                // this.timerArray[index]['totalSeconds'] = this.ticks + totalSeconds;
                this.timerArray[index]['totalSeconds'] = time - Number(this.timerArray[index]['startTime']);
            }

            if (type === 'task') {
                subscription['taskId'] = id;
            } else if (type === 'project') {
                subscription['projectId'] = id;
            } else if (type === 'ticket') {
                subscription['ticketId'] = id;
            }
        });

        this.sub.push(subscription);
    }

    stopTimer(data) {
        const ticketLockStatus = data.lockStatus;
        let id;

        if (data.relatedTo === 'task') {
            id = data.clockTimerTaskId;
        } else if (data.relatedTo === 'project') {
            id = data.clockTimerProjectId;
        } else if (data.relatedTo === 'ticket') {
            id = data.clockTimerTicketsId;
        }

        if (ticketLockStatus === 1) {
            this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
        } else {
            this.taskService.taskTimerOff('off', data.relatedTo, id, data.timerClockinClockoutId, this.loginDevice).subscribe((timerOffRes: any) => {

                if (timerOffRes.result.info.code === EStatusCode.OK) {
                    if (data.relatedTo === 'task' || data.relatedTo === 'ticket' || data.relatedTo === 'project') {
                        this.getTimerOnList();
                    }

                }
            });
        }
    }

    navigateToModule(data) {

        this.closeDialog('module', data);

        if (data.moduleName == 'Project' && data.relatedTo == 'task') {
            if (this.permissionService.projects.view) {
                this.router.navigateByUrl('support/project/details/' + data.taskProjectId);
            }
        } else if (data.moduleName == 'Client' && data.relatedTo == 'project') {
            if (this.permissionService.client.view) {
                this.router.navigateByUrl('admin/client/details/' + data.projectClientId);
            }
        } else if (data.moduleName == 'Client' && data.relatedTo == 'ticket') {
            if (this.permissionService.client.view) {
                this.router.navigateByUrl('admin/client/details/' + data.ticketClientId);
            }
        } else if (data.moduleName == 'Bugs') {
            if (this.permissionService.bugs.view) {
                this.router.navigateByUrl('support/bugs/details/' + data.bugId);
            }
        } else if (data.moduleName == 'Leads') {
            if (this.permissionService.leads.view) {
                this.router.navigateByUrl('sales/leads/details/' + data.leadsId);
            }
        } else if (data.moduleName == 'Tickets') {
            if (this.permissionService.tickets.view) {
                this.router.navigateByUrl('support/tickets/details/' + data.ticketsId);
            }
        } else if (data.moduleName == 'Opportunities') {
            if (this.permissionService.opportunities.view) {
                this.router.navigateByUrl('sales/opportunities/details/' + data.opportunitiesId);
            }
        }
    }

    navigateToTask($event, data) {

        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        this.closeDialog('non-module', data);

        if (data.relatedTo === 'task') {
            this.taskService.taskDetail.msg = 'list';
            this.router.navigateByUrl('admin/tasks/details/' + data.clockTimerTaskId);
        }
        if (data.relatedTo === 'ticket') {
            this.ticketsService.ticketDetail.msg = 'list';
            this.router.navigateByUrl('support/tickets/details/' + data.clockTimerTicketsId);
        }
        if (data.relatedTo === 'project') {
            this.projectService.projectDetail.msg = 'list';
            this.router.navigateByUrl('support/project/details/' + data.clockTimerProjectId);
        }
    }

    closeDialog(type, data) {

        // Closed open dialog
        if (this.projectService.projectDetail.projectDialog && (data.relatedTo !== 'project' || type === 'module'))
            this.projectService.projectDetail.projectDialog.close();

        if (this.clientService.clientDetail.clientDialog && (data.moduleName === 'Client' && type !== 'module'))
            this.clientService.clientDetail.clientDialog.close();

        if (this.vendorService.vendorDetail.vendorDialog)
            this.vendorService.vendorDetail.vendorDialog.close();

        if (this.bugsService.bugDetail.bugDialog)
            this.bugsService.bugDetail.bugDialog.close();

        if (this.leadsService.leadsDetail.leadsDialog)
            this.leadsService.leadsDetail.leadsDialog.close();

        if (this.opportunitiesService.oppoDetail.oppoDialog)
            this.opportunitiesService.oppoDetail.oppoDialog.close();

        if (this.ticketsService.ticketDetail.ticketDialog && (data.relatedTo !== 'ticket' || type === 'module'))
            this.ticketsService.ticketDetail.ticketDialog.close();

        if (this.taskService.taskDetail.taskDialog && (data.relatedTo !== 'task' || type === 'module'))
            this.taskService.taskDetail.taskDialog.close();
    }

    stopSubscription() {
        if (this.sub && this.sub.length) {
            this.sub.forEach(element => {
                element.unsubscribe();
            });
            this.sub = [];
        }
    }

    protected togglePanel(value: boolean): void {
        this.panelOpenState.set(value);
    }
}
