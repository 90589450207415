import {Component, Inject, Injector, OnInit, OnDestroy} from '@angular/core';
import {AppComponentBase} from '../../shared/AppComponentBase';
import { DOCUMENT, NgIf } from "@angular/common";
import { Subscription } from 'rxjs';
import {SurveyService} from "../../../service/survey.serviceTemplate";
import { SpinnerComponent } from '../../shared/spinner.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDivider } from '@angular/material/divider';
import { MatCard, MatCardTitle, MatCardContent } from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['./thank-you.component.css'],
    standalone: true,
    imports: [FlexModule, MatCard, MatCardTitle, MatDivider, MatCardContent, NgIf, MatTooltip, SpinnerComponent]
})
export class ThankYouComponent extends AppComponentBase implements OnInit, OnDestroy {
    private readonly subscriptions: Subscription[] = [];
  title;
  name;
  couponDisplay:boolean = false;
  expiry_time;
  path;
  facebook;
  twitter;
  yelp;
  google;
  randomNo;
  message;

  constructor(private injector : Injector, private surveyService: SurveyService, @Inject(DOCUMENT) private document: Document) {
    super(injector);
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {
      const surveyierId = this.document.location.pathname.substr(11);
      this.randomNo = surveyierId;
      const getAThankyouDet : Subscription = this.surveyService.getThankyouDetails(surveyierId).subscribe((res:any)=>{
          this.message = res.data.result[0].thankYouMessage;
        this.title = 'Thank you ';
        this.name = res.data.result[0].firstName;
        this.path = res.data.result[0].couponPath;
        this.facebook = res.data.result[0].facebookUrl;
        this.twitter = res.data.result[0].twitterMessage;
        this.yelp = res.data.result[0].yelpUrl;
        this.google = res.data.result[0].googleUrl;
        this.expiry_time = res.data.expirationDays;
        if(res.data.result[0].couponPath !== null){
            this.couponDisplay = true;
        }
      });
      this.subscriptions.push(getAThankyouDet);
  }


  downloadCoupon() {
        const link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.href = this.path;
        link.download = 'coupon';
        document.body.appendChild(link);
        link.click();
        link.remove();
  }


  share(portal) {
        const socialMedia = {platform: '', randomId: 0};
        const link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.setAttribute('target', '_blank');
        if (portal == 'facebook') {
            link.href = this.facebook;
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else if (portal == 'twitter') {
            link.href = 'https://twitter.com/intent/tweet?text=' + this.twitter;
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else if (portal == 'yelp') {
            link.href = this.yelp;
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else if(portal == 'google'){
            link.href = this.google;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        socialMedia.platform = portal;
        socialMedia.randomId = this.randomNo;
        const addASocialAMedia : Subscription = this.surveyService.addSocialMedia(socialMedia).subscribe((res: any) => {

        });
        this.subscriptions.push(addASocialAMedia);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
          if (subscription) {
            subscription.unsubscribe();
          }
        })
      }
}
