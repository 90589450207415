.layout-fixed .sidebar-navbar-brand {
    display: none !important;
}

.layout-fixed .mat-list-base {
    padding-top: 0 !important;
}

.layout-boxed .wrapper {
    width: 1300px;
}

.layout-boxed .wrapper {
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
}

.layout-boxed .settingTab .mat-tab-header {
    width: auto;
}

.wrapper {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100%;
    overflow-x: hidden;
}

.aside-float .content-header {
    box-shadow: none;
}

.aside-float .settingTab .mat-tab-group {
    padding: 0em 1em;
}

.aside-float #bugsCom {
    background: transparent;
}

