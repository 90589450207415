import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { LoginService } from '../../../service/login.service';

import { COMMON, secretKeys } from '../../../service/constant';
import { TenantCompaniesService } from '../../../service/tenant-companies.service';
import CryptoJS from 'crypto-js';
import { ReCaptchaSiteKeyResponse, RecaptchaResponse } from '../../../service/Interfaces/login.interface';
import { ToastrService } from 'ngx-toastr';
import { RecaptchaModule } from 'ng-recaptcha-2';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-re-captcha',
    templateUrl: './re-captcha.component.html',
    styleUrls: ['./re-captcha.component.css'],
    standalone: true,
    imports: [NgIf, RecaptchaModule]
})
export class ReCaptchaComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  @Output() disableLoginBtn: EventEmitter<boolean> = new EventEmitter();
  reCaptchaSiteKey: string;
  constructor(private loginService: LoginService, private tenantCompaniesService: TenantCompaniesService) { }

  ngOnInit(): void {
    this.getCaptchaSiteKey();
  }
  resolved(token: string) {
    if (token !== null) { //Check if the token exists to avoid making an additional API request after the verification has expired.
      //send token to backend
      this.loginService.verifyReCaptcha(token).subscribe((res: RecaptchaResponse) => {
        if (res.data.success) {
          this.disableLoginBtn.emit(false);
        } else if (res.data.responseMsg && res.data.isActive === 0) {
          this.toastr.error(res.data.responseMsg, JSON.stringify(res.error));
        } else {
          this.disableLoginBtn.emit(true);
          this.toastr.error(res.data.responseMsg);
        }
      },
        (error) => {
          this.toastr.error(error.message);
          this.disableLoginBtn.emit(true);
        }
      );
    } else {
      this.toastr.error(COMMON.RECAPTCHA_VERIFICATION_EXPIRED);
    }

  }

  //to get reCaptcha sie key 
  getCaptchaSiteKey() {
    this.loginService.fetchReCaptchaSiteKey().subscribe((res: ReCaptchaSiteKeyResponse) => {
      if (res.info.code === 200 && res.info.status === 'success') {
        const encryptedSiteKey = decodeURIComponent(res.data.decryptSiteKey);
        this.reCaptchaSiteKey = (CryptoJS.AES.decrypt(encryptedSiteKey.toString(), secretKeys.ubmEncryptKey).toString(CryptoJS.enc.Utf8));
      } else {
        this.toastr.error(res.data.responseMsg);
      }
    }, (error) => {
      this.toastr.error(error.message);
    })
  }
}
