import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Event, ActivationEnd, NavigationEnd, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, buffer, pluck } from 'rxjs/operators';
import { COMMON } from '../service/constant';
import {ConfigService} from "../service/config.service";
import { MatExpansionPanel, MatAccordion, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { SpinnerComponent } from '../admin/shared/spinner.component';
import { MatLabel } from '@angular/material/form-field';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgStyle, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatNavList, MatListItem } from '@angular/material/list';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatSidenavContainer, MatSidenav, MatSidenavContent } from '@angular/material/sidenav';

@Component({
    selector: 'app-docs',
    templateUrl: './docs.component.html',
    styleUrls: ['./docs.component.scss'],
    standalone: true,
    imports: [
        MatSidenavContainer,
        MatSidenav,
        RouterLinkActive,
        RouterLink,
        FlexModule,
        MatNavList,
        MatListItem,
        MatIcon,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        NgClass,
        ExtendedModule,
        MatSidenavContent,
        NgStyle,
        MatToolbar,
        MatIconButton,
        MatLabel,
        MatButton,
        RouterOutlet,
        SpinnerComponent,
        AsyncPipe,
    ],
})
export class DocsComponent implements OnInit {

  isMenuOpen = true;
  contentMargin = 340;
  title : string[];
  public version: any;
  panelState;
  public currYear: any;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));


  constructor(private configService: ConfigService,private breakpointObserver: BreakpointObserver) {
    this.configService.updateIsUserLogged.next(false);
    this.version = COMMON.VERSION;
    this.currYear = COMMON.CURRENT_YEAR;
  }

  onToolbarMenuToggle() {
  
    this.isMenuOpen = !this.isMenuOpen;

    if(!this.isMenuOpen) {
      this.contentMargin = 70;
    } else {
      this.contentMargin = 340;
    }
  }
  @ViewChild('first') first: MatExpansionPanel;
  ngOnInit() {
    window.document.title = 'Docs';
    // var lielement = document.getElementById('firstId');
    // lielement.classList.add('tabSection');
  }

   tabClick(tabactivevariable,idVariable){
      this.first.close();
      var lielements = document.querySelectorAll('a');
     
      lielements.forEach(function(el) {
        el.classList.remove("tabSection"); 
      }) 
    var lielements = document.querySelectorAll('a');
    lielements.forEach(function(el) {
      el.classList.remove("childSection"); 
    }) 
     const idName = document.getElementById(idVariable);
   
    idName.classList.add('tabSection');
 }

 //subMenu subTab Click
   subtabClick(a1,idVariable){
   var idName = document.getElementById(idVariable);
 
   const lielements = document.querySelectorAll('a');
     lielements.forEach(function(el) {
      el.classList.remove("childSection"); 
    }) 
    
    var idName = document.getElementById(idVariable);
    idName.classList.add('childSection');
  }
}
  




