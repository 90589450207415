import { EStatusCode } from '@milagro-ui-core';
import { TsysService } from './../../../../../service/tsys.service';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Component, OnInit, Inject, Injector, ElementRef, NgZone, AfterViewInit, OnDestroy } from '@angular/core';
import { AppComponentBase } from '../../../../shared/AppComponentBase';
import { Client } from '../../../../../service/models/Client';
import { environment } from '../../../../../../environments/environment';
import { TenantCompaniesService } from '../../../../../service/tenant-companies.service';
import { Subscription } from 'rxjs';
import {GlobalService} from "../../../../../service/global.service";
import {InvoiceServiceService} from "../../../../../service/invoice-service.service";
import {ItemsService} from "../../../../../service/items.service";
import {PaymentInfoService} from "../../../../../service/payment-info.service";
import {SettingsService} from "../../../../../service/settings.service";
import { ToastMessages } from '../../../../../service/constant';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgFor, NgClass, UpperCasePipe } from '@angular/common';
@Component({
    selector: 'app-make-modules-payment-dialog',
    templateUrl: './make-modules-payment-dialog.component.html',
    styleUrls: ['./make-modules-payment-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatDialogTitle, MatIcon, MatDivider, CdkScrollable, MatDialogContent, MatRadioGroup, FormsModule, ReactiveFormsModule, NgFor, MatRadioButton, MatCheckbox, NgClass, ExtendedModule, MatButton, UpperCasePipe]
})
export class MakeModulesPaymentDialogComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  cvvForm: FormGroup;
  paymentInfoArray: any;
  itemInfoArray: any;
  paymentForm: FormGroup;
  paymentInfoId: number;
  activateBtn: boolean = false;
  showErrorMsg: boolean = false;
  isSavedCard: boolean = false;
  cardsOnFile: boolean = false;
  encryptedTSEP: string;
  currentStatus: number = 2;
  itemTax: any;
  request: any;
  trialPeriodEndDate: any;
  today: any;
  defaultCC: number = 0;
  btnText: string ='Save & Pay';
  saveDefault: boolean = true;
  showOptions : boolean;
  showdefault : boolean = true;
  showCheckbox : boolean = false;
  setValue:any;
  businessName: string;

  billingZipcode: string = '';
  defaultTaxId: number;

  constructor(inject: Injector, private settingService: SettingsService,
              private itemsService: ItemsService,private paymentInfoService: PaymentInfoService,
              public dialog: MatDialog, private InvoiceService: InvoiceServiceService, private globalService: GlobalService,
              public dialogRef: MatDialogRef<MakeModulesPaymentDialogComponent>, private elementRef: ElementRef, 
              @Inject(MAT_DIALOG_DATA) public data: any, public tsysService: TsysService, private zone: NgZone, private tenantCompaniesService: TenantCompaniesService) {
    super(inject);
    this.request = this.data;
    if(data.paymentInfo.responseMsg == 'No record found'){
      this.showOptions = true;
    }
    window['angularComponentDialogReference'] = {
      zone: this.zone,
      componentFn: (value) => this.reopenDialog(value),
      component: this,
    };

    window['angularComponentReference'] = {
      zone: this.zone,
      componentFn: (value) => this.updateTsysData(value),
      component: this,
    };
  }

  ngOnInit() {

    this.encryptedTSEP = this.data.manifest;
    this.addCvvForm();
    this.getDefaultTaxId();

    if (this.data.eGroup === 'cnfmPayMthd') {
      this.addPaymentForm();
    }
    if (this.request.paymentInfo.length > 0) {
      this.cardsOnFile = true;
    } else {
      this.cardsOnFile = false;
    }
    const itemsListstring = this.request.subscribedModuleList.join(',');
    this.itemsService.getModuleListArray(itemsListstring).subscribe((moduleArrayList: any)=>{
      this.itemInfoArray = moduleArrayList;
    });
    const getCompnyDtils : Subscription = this.tenantCompaniesService.getCompanyDetails().subscribe((data: any) => {
      if (data.info.code === EStatusCode.OK) {
        this.trialPeriodEndDate = new Date(data.data.trialPeriodEndDate);
        this.today = new Date();
      }
    })
    this.subscriptions.push(getCompnyDtils);

    this.loadJs("../../../../../../assets/js/tsys-tsep.js")
  }

  loadJs(url: string){
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  ngAfterViewInit() {
    if (this.data.fromSubscription) {
      this.loadScript(environment.TSYS_MANIFEST_URL+ this.data.ubmDeviceId + "?" + this.encryptedTSEP);
    } else {
      this.loadScript(environment.TSYS_MANIFEST_URL+ this.configService.tsysDeviceId+"?" + this.encryptedTSEP);
    }
  }

  loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    this.elementRef.nativeElement.appendChild(node);

  }
  reopenDialog(value) {
    const data = { isComplete: 0, reOpen: 1 ,errMsg:value.errorMsg};
    this.dialogRef.close(data);
  }

  addCvvForm() {
    this.cvvForm = this.fb.group({
      cardType: new FormControl('', [Validators.required]),
      cvv: new FormControl('', [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]*$')]),
      paymentId: new FormControl('', [])
    });
  }

  addPaymentForm() {
    this.paymentForm = this.fb.group({
      paymentId: new FormControl('', [Validators.required]),
      cardType: new FormControl('', [Validators.required]),
      cvc: new FormControl('', [Validators.required]),
    });
  }

  closeModel() {
    // this.data.msg = 'un done';
    const data1 = { isComplete: 0, reOpen: 0 };
    this.dialogRef.close(data1);
  }

  checkCVC(value) {
    if (value.length >= 3) {
      const cvv = this.cvvForm.controls['cvv'].value;
      const validateACC : Subscription = this.paymentInfoService.validateCC(cvv, this.paymentInfoId).subscribe((res: any) => {
        if (res.result.info.code === EStatusCode.OK) {
          if (res.result.data.isMatched == 1) {
            this.activateBtn = true;
            this.isSavedCard = true;
            this.showErrorMsg = false;
          } else {
            this.toastr.error("CVV verification failed ! please try again.")
            this.activateBtn = false;
            this.isSavedCard = false;
            this.showErrorMsg = true;
            }
          }
      })
      this.subscriptions.push(validateACC);
    }
  }
  setPaymentInfo(id,isDefaultCard) {
    this.showOptions = false;
    if(isDefaultCard == 0){
      this.showCheckbox = true;
    }
    else{
      this.showCheckbox = false;
    }
    this.activateBtn = false;
    this.cvvForm.patchValue({ cvv: null });
    this.paymentInfoId = id;
    this.isSavedCard = false;
    this.showErrorMsg = false;
  }

  selectCC() {
    const sbscribPaytInfo : Subscription = this.paymentInfoService.subscribePaymentInfo(this.paymentInfoId).subscribe((result: any) => {
      if (result.info.code === EStatusCode.OK) {
        const cardInfo = result.data[0];
        let isAddCC: 0
        const payMentData = {
          creditCardNumber: cardInfo.creditCardNumber,
          cardType: cardInfo.cardType,
          expirationDate: cardInfo.expirationDate,
          cvc: cardInfo.cvc.toString(),
          billingZip: cardInfo.billingZip
        };
        const ccData = {
          userId: Number(this.request.userId),
          authData: payMentData
        }
        const amount = this.request.total;
        if(this.defaultCC == 1){
          const makeCardASDefault : Subscription = this.globalService.makeCardDefault(this.paymentInfoId).subscribe((data:any)=>
          {
            if(data.info.code === EStatusCode.OK){
              this.toastr.success("Default card has changed!");
            }
          })
          this.subscriptions.push(makeCardASDefault);
        }
        this.makesubscriptionPayments(amount, payMentData, ccData, isAddCC, cardInfo);
      }

    });
    this.subscriptions.push(sbscribPaytInfo);
  }


  addCCAndActivate() {
    if (!this.billingZipcode || this.billingZipcode.length < 5) {
      this.toastr.error(ToastMessages.INVALID_ZIPCODE);
      return;
    }
    const ccData = {
      userId: Number(this.request.userId),
      authData: this.tsysService.getTsysData(),
      isDefaultCard : this.defaultCC,
      saveDefault : this.saveDefault,
      businessName: this.businessName
    };
    const isAddCC = 1;
    const payMentData = {
      creditCardNumber: ccData.authData.card_number,
      cardType: ccData.authData.card_type,
      expirationDate: ccData.authData.expiration_date,
      cvc: ccData.authData.cvc,
      billingZip: this.billingZipcode || ccData.authData.zipCode,
      businessName: this.businessName
    };
    const amount = this.request.total;
    this.makesubscriptionPayments(amount, payMentData, ccData, isAddCC, null);


  }

  makesubscriptionPayments(amount, paymentData, ccData, isAddCC, cardInfo){
    this.paymentInfoService.payNowEventListener.next(true);
    this.globalService
      .makeSubscribePayment(amount, paymentData)
      .subscribe((res: any) => {
        if (
          res.info.code == EStatusCode.OK ||
          res.info.code == EStatusCode.CREATED
        ) {
          const paymentInfo = res.data;
          if (isAddCC) {
            this.paymentInfoService.tenantCCAuthentication(ccData)
              .subscribe((saveCardRes: any) => {

                if (
                  saveCardRes.info.code == EStatusCode.OK ||
                  saveCardRes.info.code == EStatusCode.CREATED
                ) {
                  this.processSubcriptionInvoice(saveCardRes.data.savedData, amount, paymentInfo);
                } else {
                  // var data = {
                  //   isComplete: 1,
                  //   reOpen: 0,
                  //   isSuccess: 1,
                  //   otherError: 1,
                  //   otherErrorReason: saveCardRes.data.responseMsg,
                  //   isPaymentFailFromTsys: 0
                  // };
                  // this.dialogRef.close(data);
                  this.toastr.error(res.data.responseMsg);
                }
              });
          } else {
            this.processSubcriptionInvoice(cardInfo, amount, paymentInfo);
          }
          this.paymentInfoService.hideMessageventListener.next(true);
          /* SAVE CARD DATA */
        } else {
          // var data = {
          //   isComplete: 1,
          //   reOpen: 0,
          //   isSuccess: 0,
          //   otherError: 0,
          //   otherErrorReason: "",
          //   isPaymentFailFromTsys: 1,
          //   failedResonseMsg:
          //     res.data.responseMsg + " : " + res.data.response.responseMessage
          // };
          // this.dialogRef.close(data);

          this.toastr.error(res.data.responseMsg);
          this.paymentInfoService.hideMessageventListener.next(true);
        }
      });
  }


  processSubcriptionInvoice(cardInfo, amount, paymentInfo,){
    this.globalService
      .getLeadDetailsByCompanyKey()
      .subscribe((leadRes: any) => {
        if (
          leadRes.info.code == EStatusCode.OK ||
          leadRes.info.code == EStatusCode.CREATED
        ) {
          const leadInfo = leadRes.data;
          const clientId = leadInfo.convertedClientId;
          const getUbmClitDetail : Subscription = this.clientService.getUbmClientDetail(clientId).subscribe((clientRes: any) => {
              if (clientRes.info.code === EStatusCode.OK) {
                const clientT: Client = new Client().deserialize(clientRes.data);
                const clientInfo = clientT;
                const itemsList = this.request.actualModuleAdded;
                const moduleArray1 = [];
                for (let index = 0; index < itemsList.length; index++) {
                  const element = itemsList[index];
                  moduleArray1.push(element);
                }
                const itemData: any = [];
                let itemOrder = 1;
                this.itemInfoArray.data.forEach(element => {
                  if (
                    moduleArray1.includes(
                      element.itemName.toLowerCase()
                    )
                  ) {
                    const newData = {
                      itemOrder: itemOrder,
                      itemId: element.savedItemsId,
                      itemName: element.savedItemsId,
                      itemDesc: element.itemDesc,
                      qty: 1,
                      unitCost: 0.0,
                      taxName: 0.0,
                      totalCost: 0.0,
                      itemTaxTotal: 0.0,
                      itemTaxRate: 0.0,
                      price: element.unitCost,
                      editItem: false,
                      taxValue: "",
                      isDeleted: 0,
                      check: true
                    };

                    if (element.pricingType == 0) {
                      var perDayCostOfModules = element.unitCost / 30;
                      var moduleCostForMonth = Number(perDayCostOfModules.toFixed(2)) * this.request.noOfDaysToExpiry * this.request.totalSeats;
                      newData.unitCost = moduleCostForMonth;
                      newData.totalCost = moduleCostForMonth;
                    } else if (element.pricingType == 1) {
                      var perDayCostOfModules = element.unitCost / 30;
                      var moduleCostForMonth = Number(perDayCostOfModules.toFixed(2)) * this.request.noOfDaysToExpiry;
                      newData.unitCost = moduleCostForMonth;
                      newData.totalCost = moduleCostForMonth;
                    } else {
                      newData.qty = 0;
                      newData.unitCost = 0;
                      newData.totalCost = 0;
                    }
                    let moduleTax = 0;
                    if (element.itemTaxRate) {
                      moduleTax = (moduleCostForMonth/100) * element.itemTaxRate;
                    } else if (element.itemTaxTotal){
                      moduleTax = element.itemTaxTotal;
                    }
                    newData.taxName = element.taxRatesId ? element.taxRatesId: 0.0;
                    newData.itemTaxRate = element.itemTaxRate ? element.itemTaxRate : 0.0;
                    newData.itemTaxTotal = moduleTax ? moduleTax : 0.0;
                    itemData.push(newData);
                    itemOrder = itemOrder + 1;
                  }
                });
                const invoiceData: any = {
                  clientId: clientId,
                  moduleId: 0,
                  relatedTo: "",
                  commissionId: 0,
                  commission: "",
                  memo: "Charged for " + this.request.noOfDaysToExpiry + " days",
                  invoiceDate: new Date().toString(),
                  taxId: this.defaultTaxId ? Number(this.defaultTaxId) :
                    Number(this.configService.defaultTax),
                  discount: 0,
                  notes: this.configService.defaultTerms,
                  items: itemData,
                  recuringFrequency: "none",
                  recurStartDate: "",
                  recurEndDate: "",
                  autoPayBy: 0,
                  autoPayId: 0,
                  userId: 0,
                  isSubscriptionInvoice: 1
                };

                const invoiceASData : Subscription = this.InvoiceService.addInvoice(invoiceData)
                  .subscribe((invRes: any) => {
                    if (
                      invRes.info.code == EStatusCode.OK ||
                      invRes.info.code == EStatusCode.CREATED
                    ) {

                      /* Update Payment, Transaction, COA, income_expense */
                      const invoicesId = invRes.data.invoicesId;
                      const updateData: any = {
                        invoicesId: invoicesId,
                        amount: parseFloat(amount),
                        clientInfo: clientInfo,
                        paymentInfo: paymentInfo
                      };

                      const updateAData : Subscription = this.globalService.updatePaymentTrans(updateData).subscribe((PTRes: any) => {

                        if (
                          PTRes.info.code == EStatusCode.OK ||
                          PTRes.info.code == EStatusCode.CREATED
                        ) {
                          //todo
                          const globServ : Subscription = 
                          this.globalService.createPolicyAndRoles(this.request.subscribedModuleList.join(','),
                           this.request.addedSubscribedModules).subscribe((RolesAndPolicyRes: any) => {
                            const data = {
                              isComplete: 1,
                              reOpen: 0,
                              isSuccess: 1,
                              otherError: 0,
                              otherErrorReason: null,
                              isPaymentFailFromTsys: 0
                            };
                            this.toastr.success(
                              "Apps added successfully"
                            );
                            this.settingService.menuUpdateEventListener.next(true);
                            this.dialogRef.close(data);
                          })
                          this.subscriptions.push(globServ);
                        } else {
                          const data = {
                            isComplete: 1,
                            reOpen: 0,
                            isSuccess: 1,
                            otherError: 1,
                            otherErrorReason:
                              PTRes.data.responseMsg,
                            isPaymentFailFromTsys: 0
                          };
                          this.dialogRef.close(data);
                        }
                      });
                      this.subscriptions.push(updateAData);
                    } else {
                      const data = {
                        isComplete: 1,
                        reOpen: 0,
                        isSuccess: 1,
                        otherError: 1,
                        otherErrorReason: invRes.data.responseMsg,
                        isPaymentFailFromTsys: 0
                      };
                      this.dialogRef.close(data);
                    }
                  });
                  this.subscriptions.push(invoiceASData);
              }
            });
            this.subscriptions.push(getUbmClitDetail);
        }
      });
  }


  updateTsysData(tsysData) {
    const cardNum = tsysData.card_number;
    this.billingZipcode = String(tsysData.zipCode || '');
    this.businessName = tsysData.businessName;

    const userId = this.configService.userId;
    const cheCardExts : Subscription = this.tsysService.checkCardExists(cardNum).subscribe((res: any) => {
      if (res.result.info.code === EStatusCode.OK) {
        if (res.result.data.isExists == 1) {
          this.toastr.error('Credit card already exists!');
          this.currentStatus = 2;
          // var data = { isComplete: 2 };
          // this.dialogRef.close(data);
        } else {
          this.tsysService.updateTsysData(tsysData);
          this.currentStatus = 1;
        }
      } else {
        this.toastr.error('Credit card already exists!');
        const data = { isComplete: 3 };
        this.dialogRef.close(data);
      }
    })
    this.subscriptions.push(cheCardExts);
  }
  changeOption(event){
    if(event == 'default'){
      this.showdefault = true;
      this.showOptions = false;
    }
    else{
      this.setValue = null;
      this.showOptions = true;
      this.showdefault = false;
    }
  }

  saveCcCheck(event){
    if(event.checked){
      this.saveDefault = true;
      this.btnText = 'Save & Pay';
    }
    else{
      this.saveDefault = false;
      this.btnText = 'Pay';
    }
  }
  defaultCcCheck(event) {
    if (event.checked) {
      this.defaultCC = 1;
    } else {
      this.defaultCC = 0;
    }
  }

  checkCC(isDefaultCard){
    if(isDefaultCard == 1 ){
      if(this.showdefault){
        return true;
      }
      else{

      }
    }
    else{
      return false;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

  SearchData(value: string, type: string) {
    if (type === 'zipcode') {
      this.billingZipcode = value;
    } else {
      this.businessName = value;
    }
  }
  
  getDefaultTaxId(){
    const getASConfigASById : Subscription = 
    this.tsysService.getConfigById('default_tax').subscribe((data:any)=>{
      if(data.result.info.code === EStatusCode.OK){
        this.defaultTaxId = data.result.data[0].value;
      }
    })
    this.subscriptions.push(getASConfigASById);
  }
}
