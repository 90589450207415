import { COMMON } from './../../service/constant';
import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterLink } from "@angular/router";

import { ModuleListService } from "../../service/module-list.service";
import { TenantExistsService } from "../../service/tenant-exists.service";
import { RegisterTenantService } from "../../service/register-tenant.service";
import { ClientService } from '../../service/client.service';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {StorageKey} from "../../service/constant";
import { environment } from './../../../environments/environment';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../../admin/shared/spinner.component';
import { NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgIf, MatError, RouterLink, SpinnerComponent]
})
export class RegisterComponent implements OnInit, OnDestroy {
  public toastr: ToastrService = inject(ToastrService);
  private readonly subscriptions: Subscription[] = [];
  public moduleList: any;
  public ischecked: any;
  public data: any;
  // checkedListModule: any = [1, 2, 5, 6, 7, 39, 65, 76];
  registerForm: FormGroup;
  submitted = false;
  show: boolean = false;
  showMsg : boolean =false;
  showLengthMsg : boolean;
  showDomainAvailability: boolean;
  yourDomain: string;
  check : boolean = true;
  domainChecked: boolean = false;
  isDisabled: boolean = false;
  isDisabledCheck: boolean = false;

  constructor(private router: Router,
    private moduleListService: ModuleListService,
    private tenantExistsService: TenantExistsService,
    private clientService :  ClientService,
    private fb: FormBuilder,
    private tenantRegister: RegisterTenantService
  ) {
    if(localStorage.getItem(StorageKey.ACCESSTOKEN)){
      this.router.navigate(["/"], { replaceUrl: true });
    }
  }

  ngOnInit() {
    this.yourDomain = "." + environment.APP_DOMAIN;
    this.registerForm = this.fb.group({
      fullname: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, Validators.email, Validators.pattern(COMMON.EMAIL_PATTERN)])],
      companyName: ["", Validators.required],
      domain: ["", Validators.required],
      companyContact: ['', [Validators.required, Validators.pattern(COMMON.PHONE_PATTERN)]],
      selectModules: [],
      password: ["", [Validators.required, Validators.minLength(6)]],
      confirmPassword: ["", [Validators.required, Validators.minLength(6)]]
    });

    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
    this.moduleListService.getModules().subscribe(data => {
      const dataLength = Object.keys(data).length
      for (let index = 0; index < dataLength; index++) {
        const element = data[index];
        element.label = element.label.toLowerCase();
        if (element.label == 'dashboard' || element.label == 'calendar' || element.label == 'client' 
        || element.label == 'vendor' || element.label == 'tasks' || element.label == 'HR' 
        || element.label == 'report' || element.label == 'utilities' || element.label == 'subscription') {
          element.ischecked = 1;
          element.isDisabledCheck = true;
        } else {
          element.ischecked = 0;
          element.isDisabledCheck = false;
        }
      }
      this.moduleList = data;
    });
  }

  // Add new modules to subscribe module array
  /* onCheckboxChange(option, event) {
    console.log(option)
    if (event.checked) {
      this.checkedListModule.push(option.menuId);
    }
    else {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.checkedListModule[i] == option.menuId) {
          this.checkedListModule.splice(i, 1);
        }
      }
    }
    console.log(this.checkedListModule);
  } */

  checkEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
      const isTenantAExists : Subscription = this.tenantExistsService.tenantExists(email).subscribe((res: any) => {
        if (res.data.isTenantExist) {
          this.isDisabled = !this.show;
          this.show = true;
        } else {
          this.show = false;
          this.isDisabled = false;
        }
      });
      this.subscriptions.push(isTenantAExists);
    }
  }

  checkDomain(domain) {
    this.showMsg = false;
    if (domain) {
      if(domain.length < 1 || domain.length > 63){
        this.showLengthMsg = true;
      }
      else{
        this.showLengthMsg = false;
        const value = this.checkDomainRegex(domain);
        if(value){
          this.domainChecked = true;
          domain = domain.replace(/ /g, "").toLowerCase().trim();
          // domain = domain.trim();
          const isdomainAExists : Subscription = this.tenantExistsService.domainExists(domain + "." + environment.APP_DOMAIN).subscribe((res: any) => {
            if (res.data.isDomainExist) {
              this.isDisabled = !this.show;
              this.showDomainAvailability = false;
              this.yourDomain = "https://" + domain + "." + environment.APP_DOMAIN;
            } else {
              this.showDomainAvailability = true;
              this.isDisabled = false;
              this.yourDomain = "https://" + domain + "." + environment.APP_DOMAIN;
            }
          });
          this.subscriptions.push(isdomainAExists);
        }
        else{
            this.showMsg = true;
        }
      }
    } else{
      this.domainChecked = false;
    }
  }

  checkDomainRegex(domain) {
    if (/^[a-zA-Z0-9][a-zA-Z0-9-]{1,63}[a-zA-Z0-9]+$/.test(domain)) {
        return true;
    }
    else {
        return false;
    }
  }

  restrictSpace(event) {
    if (event.keyCode == 32) {
        return false;
    }
  } 
  
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid || this.showMsg || this.showLengthMsg) {
      return this.registerForm.controls;
    }
    if (this.registerForm.value.confirmPassword !== this.registerForm.value.password) {
       this.toastr.error("Please enter password and confirm password same");
    } else {
      this.data = {
        fullname: this.registerForm.value.fullname,
        email: this.registerForm.value.email,
        companyName: this.registerForm.value.companyName,
        companyEmail: this.registerForm.value.email,
        domain: this.registerForm.value.domain.replace(/ /g, "").toLowerCase().trim() + "." + environment.APP_DOMAIN,
        contact: this.registerForm.value.companyContact,
        // modules: this.checkedListModule.toString(),
        password: this.registerForm.value.password,
        isWeb : '1'
      };
      this.tenantRegister
        .signup(
          this.data
        ).subscribe(
          res => {
            if (res.info.code == 200) {
              this.tenantRegister.setShareValue(
                this.yourDomain
              );
              
              this.toastr.success("Registration successful");
              this.router.navigate(["/thank-you"], { replaceUrl: true });
            } else {
              this.toastr.error(res.data.responseMsg);
            }
          },
          error => {
            
            this.toastr.error("Registeration Failed");
          }
        );

    }

  }

  checkNameExists(value){
    if (value) {
      const checkClientNameExis : Subscription = this.clientService.checkClientNameExists(value).subscribe((res: any) => {
        if (res.info.code === 200) {
          this.check = true;
        } else {
          this.check = false;
        }
      });
      this.subscriptions.push(checkClientNameExis);
      } else {
        this.check = true;
      }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }
}
