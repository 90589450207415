<div id="billing-com">
  <!-- Content Header (Page header) -->
  <app-sub-header [icon]="'fas fa-file-invoice-dollar'" [title]="'Subscription'"></app-sub-header>

  <section class="content">
    <mat-card>
      <div class="billing-item-container clearfix">
        <!-- add company & subscription button wrapper -->
        <div class="billing-add-company" style="text-align: center">
          <button style="visibility:hidden" name="addCompanyBtn" mat-raised-button color="primary"
            (click)="openCompanyDialog('newCompany')">Add Company</button>
          <!-- Expiry box -->
          <span style="flex: 60%;text-align: center;font-size: 18px; color: #337ab7;"
            *ngIf="expiryCount > 0 && expiryCount < 5 && isTrialOver">
            Your free trial ends in <strong>{{expiryCount}} {{expiryCount > 1 ? 'days': 'day'}}</strong>, Click here to
            <strong>Subscribe Now <i class="fas fa-arrow-right"></i></strong>
          </span>
          <button name="addCompanyBtn" mat-raised-button color="primary" class="subscribeNow"
            (click)="openCompanyDialog('subscription')" *ngIf="isTrialOver">Subscribe Now</button>
          <button name="addCompanyBtn" mat-raised-button color="primary" class="subscribeNow"
            (click)="cancelSubscriptionDialog(1)" *ngIf="(!isTrialOver && isSubscriptionActive) ?true:false">Cancel
            Subscription</button>
          <button name="addCompanyBtn" mat-raised-button color="primary" class="subscribeNow"
            (click)="cancelSubscriptionDialog(0)" *ngIf="(!isTrialOver && !isSubscriptionActive) ?true:false">Activate
            Subscription</button>
        </div>

        <hr class="hr-class">
        <div class="clearfix">
          <div class="billing-inner-container">
            <div class="billing-inner-container__element">
              <div class="billing-inner-container__visuals">
                <h3>Users</h3>

                <div id="billing-users-value" class="billing-item-value users">
                  <p class="active_count">Active Users / Total Seats</p>
                  {{activeUsers}}/{{totalSeats + removedSeats}}
                </div>
                <div id="billing-remove-users-value" class="billing-item-value users" *ngIf="removedSeats">
                  <p class="active_count">Seats removed from next billing cycle</p>
                  {{removedSeats}}
                </div>
                <div class="billing-item-action">
                  <p class="tw-mb-4">You have {{deactiveUsers}} deactive users.</p>
                  <a class="small button" style="text-decoration:none;" (click)="gotoUsers()">
                    <button name="manageUsersBtn" mat-raised-button color="primary">Manage Users</button>
                  </a>
                </div>
              </div>
            </div>
            <div class="billing-inner-container__element">
              <div class="billing-inner-container__visuals">
                <h3 id="billing-period-value" *ngIf="isYearlyPlan">Yearly Payment</h3>
                <h3 id="billing-period-value" *ngIf="isMonthlyPlan">Monthly Payment</h3>
                <div class="billing-item-value">
                  <span id="billing-period-payment" class="textscale" style="transform: none;">{{displaytotal | commonDate :
                        'currency_format'}}</span>
                </div>
                <div class="billing-item-notice">
                  <p>You have {{remainingSeats}} unused seats.</p>
                  <div class="tw-full-width tw-p-2">
                    <button name="addSeats" mat-raised-button color="primary" (click)="usersSeatDialog('add')"
                      class="seatsBtn">Add Seats</button>
                    <span matTooltip="There are 0 seats available, cannot remove seat!"
                      [matTooltipDisabled]="!disableRemoveSeats">
                      <button name="removeSeats" class="btn-cancel" mat-raised-button color="default"
                        [disabled]="disableRemoveSeats" (click)="usersSeatDialog('remove')">Remove Seats</button>
                    </span>
                  </div>

                </div>
              </div>
            </div>
            <div class="billing-inner-container__element billing-inner-container__element--last">
              <div class="billing-inner-container__visuals">
                <h3>Subscribed Apps</h3>
                <div class="row">

                  <div class="col-sm-6 billing-item-list detailsBox" *ngFor="let module of subscribedModuleList">
                    <ul>
                      <li>{{module.label | uppercase}}</li>
                    </ul>
                  </div>
                </div>
                <div class="billing-item-notice removeModules">
                  <button name="addModules" mat-raised-button color="primary" (click)="openDialog()">Add Apps</button>
                  <button name="removeModules" mat-raised-button class="btn-cancel" (click)="openRemoveDialog()">Remove
                    Apps</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card>
      <div class="billing-item-container clearfix">
        <div class="clearfix">
          <div class="billing-inner-container">
            <div class="col-md-4 br-rg">
              <div>

                <div class="variablewidth billing-item-container single-item floatl billing-details-item">
                  <h3 class="text-center">Subscription Details</h3>
                  <ul>
                    <li>
                      <span class="label">Company name</span>
                      <span class="data"><strong class="current-company-name">{{companyName}}</strong></span>
                    </li>
                    <li>
                      <span class="label">Payment method</span>
                      <span class="data"><strong class="billBox">{{defaultCardDetails}}</strong></span>
                    </li>
                    <li>
                      <span class="label">Next billing date</span>
                      <span class="data"><strong
                          class="billBox">{{subscriptionEndDate ? (subscriptionEndDate | commonDate : 'date'): '-'}}</strong></span>
                    </li>
                    <li class="clear-border">
                      <span class="label">Billing country</span>
                      <span class="data"><strong class="billBox">{{countryName}}</strong></span>
                    </li>
                    <li class="clear-border">
                      <span class="label">Account Status</span>
                      <span class="data">
                        <strong class="billBox make-default" *ngIf="isSubscriptionActive">Active</strong>
                        <strong class="billBox not-default" *ngIf="!isSubscriptionActive">Inactive</strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="row">

                <div class="payments_method">
                  <mat-panel-title class="payments_method_header">
                    Saved Credit Card List
                    <a class="btn btn-mini btn-action add_new" 
                    *ngIf="permissionService.subscription.edit" (click)="addCC()">Add New</a>
                  </mat-panel-title>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="tableDiv">
                        <mat-table #table [dataSource]="dataSource1" matSort matSortDisableClear>

                          <ng-container matColumnDef="credit_card">
                            <mat-header-cell *matHeaderCellDef>Credit Card</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.creditCardNumber}}</mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="card_type">
                            <mat-header-cell *matHeaderCellDef>Card Type</mat-header-cell>
                            <mat-cell *matCellDef="let element"><span
                                class="method-name-class">{{element.cardType | uppercase}}</span></mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="expiry_date">
                            <mat-header-cell *matHeaderCellDef>Expiry Date</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.expirationDate}}</mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="cvv">
                            <mat-header-cell *matHeaderCellDef>CVV</mat-header-cell>
                            <mat-cell *matCellDef="let element">***</mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="billing_zip">
                            <mat-header-cell *matHeaderCellDef>Billing Zip</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.billingZip}}
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="business_name">
                            <mat-header-cell  *matHeaderCellDef>Business Name</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.businessName}}</mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="Action">
                            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                            <mat-cell *matCellDef="let element;let i = index">
                              <div class="actionIcon">
                                <a class="btn btn-sm btn-action" matTooltip="Delete"
                                  [ngClass]="{disabled: clientActive === 0}"
                                  (click)="deleteCC(element.paymentInfoId, element.isDefaultCard)">
                                  <div class="ripple">
                                    <i class="fai fa-trash-o text-danger fa-lg " aria-hidden="true"></i>
                                  </div>
                                </a>
                                <a class="btn btn-sm btn-action card-action"
                                  (click)="makeCardDefault(element.paymentInfoId, element.isDefaultCard)">
                                  <i [ngClass]="element.isDefaultCard == '1' ? 'fas fa-credit-card make-default':'fas fa-credit-card not-default'"
                                    [matTooltip]="setToolTip(element.isDefaultCard)"></i>
                                </a>
                              </div>
                            </mat-cell>

                          </ng-container>

                          <mat-header-row *matHeaderRowDef="displayedColumns1"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: displayedColumns1"></mat-row>
                        </mat-table>
                        <div *ngIf="totalRecord1 === 0" class="no-data">{{configService.noData}}</div>
                        <mat-paginator #paginator [pageSizeOptions]="pageSizeArray" showFirstLastButtons
                          (page)="onChange($event)" [length]="totalRecord1" [pageSize]="limit"></mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </section>
</div>