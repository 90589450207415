<div *ngIf="!error" #invoice id="invCom" class="clientView">
  <div class="view_header">
  <mat-toolbar class="themeColor" [ngClass]="themeData" >
    <img src="{{invoiceDetList?.companyLogo}}" onError="this.src='/assets/img/default_company_logo.png'">
    <span *ngIf="themeData==='green-light'||themeData==='green-dark'" class="headerName themeColor" [ngClass]="themeData"  style="color: black">{{invoiceDetList?.companyName}}</span>
    <span *ngIf="themeData!=='green-light'&& themeData!=='green-dark'" class="headerName themeColor" [ngClass]="themeData"  style="color: white">{{invoiceDetList?.companyName}}</span>
  </mat-toolbar>
</div>
  <section class="inv-main-content">
    <div id="inv-detail-content" class="clearfix">

      <button mat-raised-button #tooltip="matTooltip" matTooltipPosition="above"
              matTooltip="{{(tsysCC && tsysAch) ? 'Please setup Credit Cards / ACH details for proceeding payment.!' :
               ('invoices.invPay' | translate)}}"
              *ngIf="isPayable"
              class="invpay btn-submit" (click)="openPayInvoiceDialog(invoiceDetList?.clientId)">{{
        'invoices.invPay' | translate }}
      </button>

      <div class="custom-item-btn pull-right hand-cursor-invoice view-print" mat-button #tooltip="matTooltip" matTooltipPosition="above"
           matTooltip="Print Invoice" (click)="printDetail(invoiceId,invoiceDetList?.locationName)">
        <i class="fas fa-print text-danger" ></i>
      </div>
      <div class="custom-item-btn pull-right hand-cursor-invoice" mat-button #tooltip="matTooltip" matTooltipPosition="above"
           matTooltip="{{'common.pdf' | translate}}"
           (click)="downloadPDF(invoiceId,invoiceDetList?.locationName)" >
        <i class="fa fa-file-pdf-o colorPDF" style="padding-left:2px;"></i></div>

      <div class="inv-sub-main-content" [ngClass]="{'inv-sub-payable-main-content' : isPayable}">
        <div *ngIf="showMessage" class="inv-sub-header messageBox" style="background-color: #fec0c0">
          <i class="fas fa-exclamation-triangle"></i>
          This invoice is overdue!
        </div>
        <div class="inv-sub-header">{{invoiceDetList?.refNo}}
        </div>
        <div class="inv-sub-detail-content clearfix">
          <mat-divider [vertical]="true"></mat-divider>
          <div class="all_details row tw-mt-2 tw-p-2">
            <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4 pull-left client_info">
              <div class="clearfix">
                <span class="pull-left"><h4>{{invoiceDetList?.companyLegalName}}</h4></span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{invoiceDetList?.companyAddress}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{invoiceDetList?.companyCity}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{invoiceDetList?.companyCountry}}</span>
              </div>
              <div class="clearfix address" *ngIf="invoiceDetList?.companyPhone || invoiceDetList?.companyPhone !== ''">
                <span class="pull-left light-font">
                  {{ 'invoices.invoice-detail.phone' | translate }}: {{invoiceDetList?.companyPhone | commonDate: 'phone_format'}}</span>
              </div>
            </div>
            <div class="col-lg-4 col-xs-4 col-md-4 col-sm-4 br pv hidden-print client_info">
              <div class="clearfix">
                <span class="pull-left"><h4 class="tw-text-lg tw-mb-2 tw-leading-tight tw-font-medium">{{invoiceDetList?.locationName}}</h4></span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{invoiceDetList?.billingAddress}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{invoiceDetList?.cityStateZip}}</span>
              </div>
              <div class="clearfix address">
                <span class="pull-left light-font">{{invoiceDetList?.country}}</span>
              </div>
              <div class="clearfix address" *ngIf="invoiceDetList?.phone">
                <span class="pull-left light-font">{{ 'invoices.invoice-detail.phone' | translate }}: {{invoiceDetList?.phone | commonDate: 'phone_format'}}</span>
                <!--&lt;!&ndash;displayPhoneFormat&ndash;&gt;-->
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4  pull-right client_status_detail">
              <div class="m-t-5 clearfix" *ngIf="invoiceDetList?.recurring === 'No'">
                <div class="light-font">{{'invoices.invoice-detail.payStatus' | translate}}:
                  <span class="tag-btn f-r" [ngClass]="invoiceDetList?.statusValue === 'PAID' ? 'successButton' : 'dangerButton'"> {{invoiceDetList?.statusValue}} </span>
                </div>
              </div>
              <div class="clearfix invdate">
                <p class="pull-left light-font">{{ 'invoices.invoice-detail.invDate' | translate }}</p>
                <p class="pull-right mr">{{invoiceDetList?.invoiceDate | commonDate: 'date' }}</p>
              </div>
              <div class="clearfix paydue"  *ngIf="invoiceDetList?.recurring === 'No'">
                <p class="pull-left light-font">{{ 'invoices.invoice-detail.paymentDue' | translate }}</p>
                <p class="pull-right mr">{{invoiceDetList?.dueDate | commonDate: 'date'}}</p>
              </div>

              <div class="clearfix paydue" *ngIf="invoiceDetList?.recurring === 'Yes'">
                <p class="pull-left light-font">{{ 'invoices.invoice-detail.startDate' | translate }}</p>
                <p class="pull-right mr">{{invoiceDetList?.recurStartDate | commonDate: 'date' }}</p>
              </div>
              <div class="clearfix paydue" *ngIf="invoiceDetList?.recurring === 'Yes'">
                <p class="pull-left light-font">{{ 'invoices.invoice-detail.endDate' | translate }}</p>
                <p class="pull-right mr">{{!invoiceDetList?.recurEndDate ? '-' : invoiceDetList?.recurEndDate |
                  commonDate: 'date' }}</p>
              </div>

              <div class="clearfix invdate" *ngFor="let field of customElements">
                <p class="pull-left light-font"> {{field.fieldLabel | titlecase}}</p>
                <p class="pull-right mr">{{field.fieldValue}}</p>
              </div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="table_outer">
            <table mat-table [dataSource]="dataSource" class="inv-table">
              <ng-container matColumnDef="item_name">
                <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.itemName' |
                  translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.savedItemName}}</td>
              </ng-container>
              <ng-container matColumnDef="item_desc">
                <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.description' |
                  translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="item-desc-table inv-item-table" style="max-width: 290px;">
                  {{element.itemDesc}}
                </td>
              </ng-container>
              <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.qty' | translate
                  }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.quantity}}</td>
              </ng-container>
              <ng-container matColumnDef="unit_price">
                <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.unitPrice' |
                  translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.unitCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
              </ng-container>
              <ng-container matColumnDef="promo">
                <th mat-header-cell *matHeaderCellDef class="light-font">Promo
                </th>
                <td mat-cell *matCellDef="let element"> {{element.isPromo ? 'Yes' : 'No'}}</td>
              </ng-container>
              <ng-container matColumnDef="promoPrice">
                <th mat-header-cell *matHeaderCellDef class="light-font">Promo Price
                </th>
                <td mat-cell *matCellDef="let element"> {{element.promoPrice | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
              </ng-container>
              <ng-container matColumnDef="promoValidity">
                <th mat-header-cell *matHeaderCellDef class="light-font">Promo validity
                </th>
                <td mat-cell *matCellDef="let element"> {{element.promoValidity | commonDate : 'date'}}</td>
              </ng-container>
              <ng-container *ngIf="configService.enableItemLevelTaxation === '1'" matColumnDef="tax_name">
                <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.tax' | translate
                  }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.taxName}}</td>
              </ng-container>
              <ng-container matColumnDef="subTotal">
                <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.subTotal' |
                  translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.totalCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
              </ng-container>
              <ng-container *ngIf="configService.enableItemLevelTaxation === '1'" matColumnDef="tax">
                <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.taxAmount' |
                  translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.itemTaxTotal | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <!--Group Tax details-->
          <div style="padding-bottom: 21px;" *ngIf="configService.enableItemLevelTaxation === '1'">
          <div class="inv-line" *ngIf="invoiceDetList?.grouptax?.length"></div>
          <div class="payment-detail-table" *ngIf="invoiceDetList?.grouptax?.length">
            <div class="recurredPadding">Group Tax Detailed Information : </div>
            <table mat-table [dataSource]="invoiceDetList.grouptax" class="mat-elevation-z8 inv-table">
              <ng-container matColumnDef="main_group_tax_name">
                <th mat-header-cell *matHeaderCellDef class="light-font">Main Group Tax Name
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.groupTaxRateName}}</span>
              </ng-container>
              <ng-container matColumnDef="sub_tax_name">
                <th mat-header-cell *matHeaderCellDef class="light-font">Sub Tax Name
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.subTaxName}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="subTotal">
                <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.subTotal' |
                  translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="inv-item-table" style="max-width: 290px;">
                  {{element.totalCost | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="tax">
                <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.taxAmount' |
                  translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.itemTaxTotal | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</span>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="groupTaxDisplayedColumns;"></tr>
              <tr mat-row *matRowDef="let row; columns: groupTaxDisplayedColumns;"></tr>
            </table>
            <div *ngIf="invoiceDetList?.grouptax?.length === 0" class="no-data">{{configService.noData}}</div>
          </div>
          </div>

          <div class="inv-line"></div>
          <div class="inv-foot-detail row">
            <div class="inv-notes col-sm-7 col-md-7 col-xs-7 col-lg-7" style="padding-left: 15px">
              <div class="left-notes tinyClass" [innerHTML]="invoiceDetList?.notes"></div>
            </div>
            <div class="inv-payment-detail col-sm-5 col-md-5 col-xs-5 col-lg-5"
              *ngIf="invoiceDetList?.templateType !== 'packingSlip'" style="padding-right: 13px">
              <div class="inv-payment-sub-detail">
                <div>
                  <div>
                    <div class="left-panel">{{ 'invoices.invoice-detail.subTotal' | translate }}</div>
                    <div class="right-panel">{{subTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel">{{ 'invoices.invoice-detail.tax' | translate }}</div>
                    <div class="right-panel">{{tax | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel">{{ 'invoices.invoice-detail.discount' | translate }}</div>
                    <div class="right-panel">{{discount | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel">{{ 'invoices.invoice-detail.total' | translate }}</div>
                    <div *ngIf="invoiceDetList?.discount !== 100; then content else other_content"></div>
                    <ng-template #content>
                      <div class="right-panel">{{invoiceTotalAmount | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                    </ng-template>
                    <ng-template #other_content>
                      <div class="right-panel">0.00</div>
                    </ng-template>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel">{{ 'invoices.invoice-detail.payment' | translate }}</div>
                    <div *ngIf="invoiceDetList?.discount !== 100; then content1 else other_content1"></div>
                    <ng-template #content1>
                      <div class="right-panel">{{paidAmount | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                    </ng-template>
                    <ng-template #other_content1>
                      <div class="right-panel">0.00</div>
                    </ng-template>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="left-panel baldue">{{ 'invoices.invoice-detail.balDue' | translate }}</div>
                    <div *ngIf="invoiceDetList?.discount !== 100; then content2 else other_content2"></div>
                    <ng-template #content2>
                      <div class="right-panel baldueright">{{invoiceDue | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                    </ng-template>
                    <ng-template #other_content2>
                      <div class="right-panel baldueright">0.00</div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
  <footer class="page-footer view_footer" style="padding-top:5px;">
    <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="http://www.Utiliko.io " target="_blank">Utiliko Corporation {{currYear}}.</a>All rights reserved.
      <p class="alignright">{{version}}</p>
    </div>
  </footer>
</div>
<div *ngIf="error">
  <div class="center">
    <p style="font-size: 50px">Oops!</p>
    <p style="font-size: 25px">Something went wrong and we couldn't process your request.</p>
    <p style="font-size: 15px">Please check your entered Id.</p>
  </div>
</div>

<div id="print" [hidden]="!show">
  <table style="width: 100%">
    <tbody>
    <tr>
      <td>
        <div>
          <div class="inv-sub-main-content" style="background: none !important;">
            <!--Non-recurring invoice-->
            <div class="waterMark" *ngIf="invoiceDetList?.recurring === 'No'">
              <p  class="styleWtareMarkGreen" *ngIf="invoiceDetList?.statusValue === 'PAID'">
                {{invoiceDetList?.statusValue}}</p>
              <p  class="styleWtareMarkRed" *ngIf="invoiceDetList?.statusValue !== 'PAID'">
                {{invoiceDetList?.statusValue}}</p>
            </div>
            <!--only for recurring invoice-->
            <div class="waterMark" *ngIf="invoiceDetList?.recurring === 'Yes'">
              <p  class="styleWtareMarkGreen" *ngIf="stopRecurButton">
                Active</p>
              <p  class="styleWtareMarkRed" *ngIf="!stopRecurButton">
                Inactive</p>
            </div>

            <div class="themeColor">
              <div style="display: inline-block;float: left;">
                <img src="{{invoiceDetList?.companyLogo}}" style="height: 60px;"
                     onError="this.src='/assets/img/default_company_logo.png'"> </div>
              <div class="headerName themeColor ">{{invoiceDetList?.companyName}}</div>
            </div>
            <div class="inv-line"></div>
            <div class="inv-sub-detail-content clearfix" style="background: none !important;">
              <div>
                <div class="addressAlign">
                  <div class="clearfix">
                    <span class="pull-left"><h4>{{invoiceDetList?.companyLegalName}}</h4></span>
                  </div>
                  <div class="clearfix address">
                    <span class="pull-left light-font">{{invoiceDetList?.companyAddress}}</span>
                  </div>
                  <div class="clearfix">
                    <span class="pull-left light-font">{{invoiceDetList?.companyCity}}</span>
                  </div>
                  <div class="clearfix">
                    <span class="pull-left light-font">{{invoiceDetList?.companyCountry}}</span>
                  </div>
                  <div class="clearfix" *ngIf="invoiceDetList?.companyPhone || invoiceDetList?.companyPhone !== ''">
                <span class="pull-left light-font">{{ 'invoices.invoice-detail.phone' | translate }}
                  : {{invoiceDetList?.companyPhone | commonDate: 'phone_format'}} </span>
                  </div>
                </div>
                <mat-divider [vertical]="true"></mat-divider>
                <div>
                  <div class="addressAlign">
                    <div class="clearfix">
                      <span class="pull-left"><h4>{{invoiceDetList?.locationName}}</h4></span>
                    </div>
                    <div class="clearfix address">
                      <span class="pull-left light-font">{{invoiceDetList?.billingAddress}}</span>
                    </div>
                    <div class="clearfix">
                      <span class="pull-left light-font">{{invoiceDetList?.cityStateZip}}</span>
                    </div>
                    <div class="clearfix">
                      <span class="pull-left light-font">{{invoiceDetList?.country}}</span>
                    </div>
                    <div class="clearfix" *ngIf="invoiceDetList?.phone">
                      <span class="pull-left light-font">{{ 'invoices.invoice-detail.phone' | translate }}
                  : {{invoiceDetList?.phone | commonDate: 'phone_format'}} </span>
                    </div>
                  </div>
                </div>
                <div class="statusAlign" style="padding-top: 10px">
                  <div class="clearfix paydue">
                    <p class="pull-left light-font item3-margin">Invoice # :</p>
                    <p class="DateRight item3-margin">{{invoiceDetList?.refNo}}</p>
                  </div>
                  <div class="clearfix" *ngIf="invoiceDetList?.recurring === 'No'">
                    <div class="lightFont">{{'invoices.invoice-detail.payStatus' | translate}}:
                      <span class="tag-btn f-r" [ngClass]="invoiceDetList?.statusValue === 'PAID' ? 'successButton' : 'dangerButton'"> {{invoiceDetList?.statusValue }} </span>
                    </div>
                  </div>

                  <div class="clearfix paydue">
                    <p class="pull-left light-font item3-margin">{{ 'invoices.invoice-detail.invDate' | translate }}</p>
                    <p class="DateRight item3-margin">{{invoiceDetList?.invoiceDate | commonDate: 'date' }}</p>
                  </div>
                  <div class="clearfix" *ngIf="invoiceDetList?.recurring === 'No'">
                    <div class="lightFont item3-margin">{{ 'invoices.invoice-detail.paymentDue' | translate }}
                      <span class="f-r item3-margin" style="font-weight: 600"> {{invoiceDetList?.dueDate | commonDate: 'date'}} </span>
                    </div>
                  </div>

                  <div class="clearfix paydue" *ngIf="invoiceDetList?.recurring === 'Yes'">
                    <p class="pull-left light-font item3-margin">{{ 'invoices.invoice-detail.startDate' | translate }}</p>
                    <p class="DateRight item3-margin">{{invoiceDetList?.recurStartDate | commonDate: 'date' }}</p>
                  </div>
                  <div class="clearfix paydue" *ngIf="invoiceDetList?.recurring === 'Yes'">
                    <p class="pull-left light-font item3-margin">{{ 'invoices.invoice-detail.endDate' | translate }}</p>
                    <p class="DateRight item3-margin">{{!invoiceDetList?.recurEndDate ? '-' : invoiceDetList?.recurEndDate |
                      commonDate: 'date' }}</p>
                  </div>

                  <div class="clearfix paydue" *ngFor="let field of customElements">
                    <p class="pull-left light-font item3-margin"> {{field.fieldLabel | titlecase}}</p>
                    <p class="DateRight item3-margin">{{field.fieldValue}}</p>
                  </div>
                  <div class="divider"></div>
                </div>
              </div>
              <div style="padding-bottom: 21px;">
                <table mat-table [dataSource]="dataSource" class="tableHead" style="background: none !important;">
                <ng-container matColumnDef="item_name">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">{{ 'invoices.invoice-detail.itemName' |
                    translate }}
                  </th>
                    <td mat-cell *matCellDef="let element" class="tableFirstRowCol" style="background: none !important;"> {{element.savedItemName}}</td>
                </ng-container>
                <ng-container matColumnDef="item_desc">
                  <th mat-header-cell *matHeaderCellDef class="tableRow light-font">{{ 'invoices.invoice-detail.description' |
                    translate }}
                  </th>
                    <td mat-cell *matCellDef="let element" class="item-desc-table inv-item-table tableColumn" style="max-width: 150px;background: none !important;">
                    {{element.itemDesc}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                  <th mat-header-cell *matHeaderCellDef class="tableRow light-font">{{ 'invoices.invoice-detail.qty' | translate
                    }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tableColumn" style="text-align:center;background: none !important;"> {{element.quantity}}</td>
                </ng-container>
                <ng-container matColumnDef="unit_price">
                  <th mat-header-cell *matHeaderCellDef class="tableRow light-font">{{ 'invoices.invoice-detail.unitPrice' |
                    translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;"> {{element.unitCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
                </ng-container>
                <ng-container matColumnDef="promo">
                  <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Promo
                  </th>
                  <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;"> {{element.isPromo ? 'Yes' : 'No'}}</td>
                </ng-container>
                <ng-container matColumnDef="promoPrice">
                  <th mat-header-cell *matHeaderCellDef class="tableRow light-font" >Promo Price
                  </th>
                  <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;"> {{element.promoPrice | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
                </ng-container>
                <ng-container matColumnDef="promoValidity">
                  <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Promo validity
                  </th>
                  <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;"> {{element.promoValidity | commonDate : 'date'}}</td>
                </ng-container>
                <ng-container *ngIf="configService.enableItemLevelTaxation === '1'" matColumnDef="tax_name">
                  <th mat-header-cell *matHeaderCellDef class="tableRow light-font" >{{ 'invoices.invoice-detail.tax' | translate
                    }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;"> {{element.taxName}}</td>
                </ng-container>
                <ng-container matColumnDef="subTotal">
                  <th mat-header-cell *matHeaderCellDef class="tableRow light-font" >{{ 'invoices.invoice-detail.subTotal' |
                    translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;"> {{element.totalCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
                </ng-container>
                <ng-container *ngIf="configService.enableItemLevelTaxation === '1'" matColumnDef="tax">
                  <th mat-header-cell *matHeaderCellDef class="tableRow light-font">{{ 'invoices.invoice-detail.taxAmount' |
                    translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;"> {{element.itemTaxTotal | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              </div>
              <!--Group Tax details-->
              <div style="padding-bottom: 21px;" *ngIf="configService.enableItemLevelTaxation === '1'">
                <div class="payment-detail-table" *ngIf="invoiceDetList?.grouptax?.length">
                  <div class="recurredPadding" style="padding-left: 15px;margin-top: 10px">Group Tax Detailed Information : </div>
                  <table mat-table [dataSource]="invoiceDetList.grouptax" class="tableHead" style="background: none !important;">
                    <ng-container matColumnDef="main_group_tax_name">
                      <th mat-header-cell *matHeaderCellDef class="tableRow light-font" style="background: none !important;">Main Group Tax Name
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableFirstRowCol" style="background: none !important;">
                        {{element.groupTaxRateName}}
                    </ng-container>
                    <ng-container matColumnDef="sub_tax_name">
                      <th mat-header-cell *matHeaderCellDef class="tableRow light-font" style="background: none !important;">Sub Tax Name
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;">
                        {{element.subTaxName}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="subTotal">
                      <th mat-header-cell *matHeaderCellDef class="tableRow light-font" style="background: none !important;">{{ 'invoices.invoice-detail.subTotal' |
                          translate }}
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;">
                        {{element.totalCost | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="tax">
                      <th mat-header-cell *matHeaderCellDef class="tableRow light-font" style="background: none !important;">{{ 'invoices.invoice-detail.taxAmount' |
                          translate }}
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;">
                        {{element.itemTaxTotal | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="groupTaxDisplayedColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: groupTaxDisplayedColumns;"></tr>
                  </table>
                  <div *ngIf="invoiceDetList?.grouptax?.length === 0" class="no-data">{{configService.noData}}</div>
                </div>
              </div>
              <div class="inv-foot-detail">
                <div class="notes" style="padding-left: 15px">
                  <div class="inv-notes tinyClass" [innerHTML]="invoiceDetList?.notes"></div>
                </div>
                <div class="inv-payment-detail col-sm-4 col-md-4 col-xs-4 col-lg-4"
                  *ngIf="invoiceDetList?.templateType !== 'packingSlip'" style="padding-right: 13px">
                  <div class="inv-payment-sub-detail">
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.subTotal' | translate }}</div>
                        <div class="right-panel" style="background: none !important;">{{subTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.tax' | translate }}</div>
                        <div class="right-panel" style="background: none !important;">{{tax | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.discount' | translate }}</div>
                        <div class="right-panel" style="background: none !important;">{{discount | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.total' | translate }}</div>
                        <div *ngIf="invoiceDetList?.discount !== 100; then content else other_content"></div>
                        <ng-template #content>
                          <div class="right-panel" style="background: none !important;">{{invoiceTotalAmount | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                        </ng-template>
                        <ng-template #other_content>
                          <div class="right-panel" style="background: none !important;">0.00</div>
                        </ng-template>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.payment' | translate }}</div>
                        <div *ngIf="invoiceDetList?.discount !== 100; then content1 else other_content1"></div>
                        <ng-template #content1>
                          <div class="right-panel" style="background: none !important;">{{paidAmount | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                        </ng-template>
                        <ng-template #other_content1>
                          <div class="right-panel" style="background: none !important;">0.00</div>
                        </ng-template>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel baldue" style="background: none !important;">{{ 'invoices.invoice-detail.balDue' | translate }}</div>
                        <div *ngIf="invoiceDetList?.discount !== 100; then content2 else other_content2"></div>
                        <ng-template #content2>
                          <div class="right-panel baldueright" style="background: none !important;">{{invoiceDue | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                        </ng-template>
                        <ng-template #other_content2>
                          <div class="right-panel baldueright" style="background: none !important;">0.00</div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
    </tbody>

    <tfoot>
    <tr>
      <td>
        <div class="page-footer-space"></div>
      </td>
    </tr>
    </tfoot>

  </table>
  <div class="view_footer" style="padding-top:5px;">
    <footer class="footer">
      <hr>
      <div class="page">
        <div class="bottomFooter">
          <p class="leftFooter">{{invoiceDetList?.companyEmail}}</p>
        </div>
        <div class="centerFooter">
          <p class="leftFooter"> {{invoiceDetList?.companyAddress}},{{invoiceDetList?.companyCity}},{{invoiceDetList?.companyState ? invoiceDetList?.companyState + ',' : ''}}{{invoiceDetList?.companyZipCode}},{{invoiceDetList?.companyCountry}} <br>{{invoiceDetList?.companyPhone | commonDate: 'phone_format'}}</p>
        </div>
        <div class="rightFooter">
          <p class="leftFooter">{{invoiceDetList?.companyWebsite ? invoiceDetList?.companyWebsite : invoiceDetList?.companyDomain}}</p>
        </div>
      </div>
    </footer>
  </div>
</div>
<app-spinner></app-spinner>
