import {Component, Injector, OnInit, Input, ViewChild} from "@angular/core";

import { Router } from "@angular/router";
import { LoginService } from "./../../service/login.service";
import { TenantCompaniesService } from "./../../service/tenant-companies.service";
import { DialogComponent } from "./dialog/dialog.component";
import { ItemsService } from "./../../service/items.service";
import { MenuService } from "./../../service/menu.service";
import { ModuleListService } from "./../../service/module-list.service";
import { EmployeeService } from "./../../service/employee.service";
import moment from "moment";
import CryptoJS from "crypto-js";
import { ConfigService } from "../../service/config.service";
import {AppComponentBase} from "../../admin/shared/AppComponentBase";
import { COMMON } from "../../service/constant";
import {GlobalService} from "../../service/global.service";
import {PaymentInfoService} from "../../service/payment-info.service";
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Subscription } from 'rxjs';
import { SocketService } from "../../service/socket.service";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CommonDatePipe } from "../../admin/shared/pipes/common-date.pipe";
import { SpinnerComponent } from "../../admin/shared/spinner.component";
import { MatButton } from "@angular/material/button";
import { ExtendedModule } from "@angular/flex-layout/extended";
import { MatTabGroup, MatTab } from "@angular/material/tabs";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf, NgClass, DecimalPipe } from "@angular/common";
import { MatToolbar } from "@angular/material/toolbar";
import { EStatusCode } from '@milagro-ui-core';

@Component({
    selector: "app-subscription",
    templateUrl: "./subscription.component.html",
    styleUrls: ["./subscription.component.scss"],
    standalone: true,
    imports: [MatToolbar, NgIf, MatTooltip, MatIcon, MatTabGroup, MatTab, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, NgClass, ExtendedModule, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, FormsModule, ReactiveFormsModule, MatButton, SpinnerComponent, DecimalPipe, CommonDatePipe]
})
export class SubscriptionComponent extends AppComponentBase implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  seatForm = new FormControl();
  isYearly: boolean = false;
  isYearlySelected = 0;
  isMonthly: boolean = true;
  activeUsers: number;
  deactiveUsers: number;
  zerocost: number = 0;
  itemTax: number = 0;
  subscribedModuleList: any = [];
  defaultModulePrice = 2;
  modulePrice = 0;
  totalPrice = 0;
  list: any;
  paymentInfo: any;
  tableItems: any;
  dataArr = [];
  selectedModuleArr: any = [];
  selectedItemsNameArr: any = [];
  displayedColumns: string[] = [
    "class",
    "checkbox",
    "name",
    "active_users",
    "cost_pupm",
    "cost_pm",
    "row_tax",
    "row_cost"
  ];
  dataSource = new MatTableDataSource<any>();
  pucost: number = 0;
  displaypucost: any = 0;
  mcost: number = 0;
  displaymcost: any = 0;
  total_cost: number = 0;
  displaytotal_cost: any = 0;
  subTotal: number = 0;
  taxSubTotal: number = 0;
  sTotal: any = 0;
  monthlyObject: any;
  yearlyObject: any;
  calculationObject: any;
  tax: number = 0;
  displaytax: any = 0;
  displaydiscount: any = 0;
  discount: number = 0;
  total: number = 0;
  totalSeats: number = 0;
  actualTotalSeats: number = 0;
  remainingDaysToEndMonth: number = 0;
  displaytotal: any = 0;
  mid: number;
  deviceId: number;
  transactionKey: string;
  padString = "0";
  encryptedTSEP: string;
  loggedInUserId: string;
  notStartOfMonth: boolean = false;
  tsysFlag: boolean = false;
  isOwner: string;
  defaultItemsList: any = {};
  reservedSeatInfo: any;
  actualTotalOfPlan: any = 0;
  seat = new FormControl('',[Validators.pattern('^[0-9]*$'), Validators.required]);
  private readonly subscriptions: Subscription[] = [];

  constructor(private inject: Injector, private globalService: GlobalService,
    public employeeService: EmployeeService,
    public moduleListService: ModuleListService,private paymentInfoService: PaymentInfoService,
    public menuService: MenuService,
    public itemsService: ItemsService,
    public dialog: MatDialog,
    private socketService: SocketService,
    public router: Router,
    public tenantCompaniesService: TenantCompaniesService,
    public configService: ConfigService,
    public loginService: LoginService
    ) {
      super(inject);
      this.setWindowTitle('Subscription')
      this.configService.updateIsUserLogged.next(false);
    }
    
    ngOnInit() {      
      this.dataSource.sort = this.sort;
      const subscription = localStorage.getItem("subscription");
      this.loggedInUserId = localStorage.getItem("userId");
      this.isOwner = localStorage.getItem("owner");
      if (subscription == "1") {
        this.router.navigate(["/admin/dashboard"], { replaceUrl: true });
        this.getUserCount();
        this.getSubscribedModules();
        this.getReservedSeats();
      } else if (subscription === null) {
        this.router.navigate(["/login"], { replaceUrl: true });
      } else if (subscription == "0") {
        this.getReserveSeatItemDetail();
        this.getUserCount();
        this.getSubscribedModules();
        this.getReservedSeats();
        this.getTsysDetails();
        this.isMonthly = true;
        this.isYearly = false;
        this.isYearlySelected = 0;
    }
    const date = new Date()
    this.remainingDaysToEndMonth = (new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - date.getDate())+1;    
  }
  getReserveSeatItemDetail(){
    this.paymentInfoService.reservedSeatItemDetails().toPromise().then((itemDetails: any) => {
      if (itemDetails.info.code === EStatusCode.OK) {
        this.reservedSeatInfo = itemDetails.data[0];
      }
    });
  }
  getTsysDetails(){
    const getTsysData : Subscription = this.globalService.getTsysInfo().subscribe((res:any)=>{
      if (res.result.info.code === EStatusCode.OK) {
        this.mid = res.result.data.mid;
        this.transactionKey = res.result.data.transactionKey;
        this.deviceId = res.result.data.deviceId;
        this.tsysFlag = true;
      } else {
        this.tsysFlag = false;
      }
      
    })
    this.subscriptions.push(getTsysData);
  }

  getUserCount() {
    const getUserCount : Subscription = this.employeeService
      .activeDeactiveUserCount()
      .subscribe((response: any) => {
        if (response.info.code === EStatusCode.OK) {
          this.activeUsers = response.data.activeUsers;
          this.deactiveUsers = response.data.deActiveUsers;
        }
      });
    this.subscriptions.push(getUserCount);
  }

  getSubscribedModules() {
    this.moduleListService.getModules().toPromise().then((response: any) => {
      if (response.info.code === EStatusCode.OK) {
        this.menuService.getSubscribedModules().toPromise().then((response1: any) => {
          if (response1.info.code === EStatusCode.OK) {
            const moduleNameArr = [];
            for (let index = 0; index < response1.data.length; index++) {
              const name = response1.data[index].label;
              moduleNameArr.push(name);
            }
            const selectedModulesStr = moduleNameArr.toString();
            this.moduleListService.getSubscirbedModulesByName(selectedModulesStr).subscribe((resp:any)=>{
              if (resp.info.code === EStatusCode.OK) {
                resp.data.forEach(element => {
                  this.selectedModuleArr.push(element);
                });
              }
              this.getDefaultItemsList();
            })
          }
        })
      }
    });
  }

  getReservedSeats() {
    const getRsrvSeats : Subscription = this.tenantCompaniesService
      .getReservedSeats()
      .subscribe((response: any) => {
        if (response.info.code === EStatusCode.OK) {
          this.totalSeats = response.data[0].noOfUsers;
          this.actualTotalSeats = response.data[0].noOfUsers;
        }
      });
    this.subscriptions.push(getRsrvSeats);
  }
  subCount(event){
    if (this.activeUsers > Number(this.totalSeats)-1) {
      this.toastr.error("Seats can't be less than active users in system!");
    } else {
      this.totalSeats = Number(this.totalSeats)-1
    }
    this.getDefaultItemsList();
  }
  addCount(event){
    if (this.activeUsers < Number(this.totalSeats)+1) {
      this.totalSeats = Number(this.totalSeats)+1;
    } else {
      this.toastr.error("Seats can't be less than active users in system!");
    }
    this.getDefaultItemsList();
  }
  changeSeats(event){
    if (event.target.value%1 ===0 && event.key !== ".") {
      if(this.activeUsers && this.activeUsers > 0){
        if (event.target.value >= this.activeUsers) {
          this.totalSeats = Number(event.target.value);
        } else{
          this.totalSeats = Number(this.activeUsers);
          this.toastr.error("Total seats can't be less than active users!");
        }
      }else{
        if (event.target.value > 0) {
          this.totalSeats = Number(event.target.value);
        } else{
          this.totalSeats = Number(this.activeUsers);
          this.toastr.error("Total seats can't be less than zero!");
        }
      }
    } else {
      this.totalSeats = 1;
      this.totalSeats = Number(this.activeUsers);
      this.toastr.error("Invalid input!");
    }
    this.getDefaultItemsList();
  }
  getDefaultItemsList() {
    this.dataArr = [];
    this.subTotal = 0;
    this.taxSubTotal = 0;
    const displayItemTax = 0;
    const getDefaultItemsList : Subscription = this.itemsService.getDefaultItemsList().subscribe((defaultItemsresponse: any) => {      
      if (defaultItemsresponse.info.code === EStatusCode.OK) {        
        this.defaultItemsList = defaultItemsresponse.data.list;
        const defaultItemsNameArr = [];
        this.selectedItemsNameArr = []
        this.selectedModuleArr.forEach(module => {          
          this.selectedItemsNameArr.push(module.itemName.toLowerCase())
        });
        this.selectedItemsNameArr.push("seats reserved")
        for (let index = 0; index < this.defaultItemsList.length; index++) {
          const module = this.defaultItemsList[index];
          defaultItemsNameArr.push(module.itemName.toLowerCase())
        }
        const resultA = this.selectedItemsNameArr.filter(elm => !defaultItemsNameArr.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));
        const resultB = defaultItemsNameArr.filter(elm => !this.selectedItemsNameArr.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));  
        const nonSelectedModules = [...resultA, ...resultB]

        const getSubscriptionAmountConst : Subscription = this.globalService.getSubscriptionAmount(JSON.stringify(this.selectedModuleArr),JSON.stringify(this.reservedSeatInfo), this.totalSeats).subscribe((response: any) => {
          if (response.info.code === EStatusCode.OK) {
            this.calculationObject = response.data
            const n = 2;
            for (let index = 0; index < n; index++) {
              const element = this.calculationObject[index];              
              element.moduleObj.forEach(module => {
                const moduleName = module.name.toLowerCase();
                module.isChecked = true;
                if (moduleName == "seats reserved" || moduleName == "dashboard" || moduleName == "calendar" 
                || moduleName == "client" || moduleName == "vendor" || moduleName == "tasks" 
                || moduleName == "hr" || moduleName == "report" || moduleName == "utilities" 
                || moduleName == "subscription") {
                  module.unSelectable = true;
                } else {
                  module.unSelectable = false;
                }
              });
              this.defaultItemsList.forEach(defModule => {
                const moduleName = defModule.itemName.toLowerCase();
                const indexOfModule = defaultItemsNameArr.indexOf(moduleName);
                const indexOfNModule = nonSelectedModules.indexOf(moduleName);
                if (indexOfNModule != -1) {
                  const newObj = {
                    itemName:this.defaultItemsList[indexOfModule].itemName,
                    name: this.defaultItemsList[indexOfModule].itemName,
                    numberOfUsers: 0,
                    class: this.defaultItemsList[indexOfModule].itemName,
                    costPerUPM: "0.00",
                    costPerMonth: "0.00",
                    itemTax: "0.00",
                    savedItemsId: this.defaultItemsList[indexOfModule].savedItemsId,
                    totalRowCost: "0.00",
                    unSelectable: false,
                    isChecked: false
                  }
                  element.moduleObj.push(newObj)
                }
              });
            }
            if (this.isMonthly) {
              this.dataSource.data = response.data[0].moduleObj;
              this.dataSource.sort = this.sort;
              this.sTotal = response.data[0].subTotal;
              this.actualTotalOfPlan = response.data[0].totalToDisplay;
              this.displaytotal = response.data[0].total;
              this.displaytax = response.data[0].tax;
              this.displaydiscount = response.data[0].discount;
            } else {
              this.dataSource.data = response.data[1].moduleObj;
              this.dataSource.sort = this.sort;
              this.sTotal = response.data[1].subTotal;
              this.actualTotalOfPlan = response.data[1].totalToDisplay;
              this.displaytotal = response.data[1].total;
              this.displaytax = response.data[1].tax;
              this.displaydiscount = response.data[1].discount;
            }
          }
        });
        this.subscriptions.push(getSubscriptionAmountConst);
      }
    });
    this.subscriptions.push(getDefaultItemsList);
  }

  radioChange(event) {
    if (event.index == 0) {
      this.isMonthly = true;
      this.isYearly = false;
      this.isYearlySelected = 0;
      this.dataSource.data = this.calculationObject[0].moduleObj
      this.sTotal = this.calculationObject[0].subTotal
      this.displaytotal = this.calculationObject[0].total
      this.actualTotalOfPlan = this.calculationObject[0].totalToDisplay
      this.displaytax = this.calculationObject[0].tax
      this.displaydiscount = this.calculationObject[0].discount
    } else if (event.index == 1) {
      this.dataSource.data = this.calculationObject[1].moduleObj
      this.sTotal = this.calculationObject[1].subTotal
      this.displaytotal = this.calculationObject[1].total
      this.actualTotalOfPlan = this.calculationObject[1].totalToDisplay
      this.displaytax = this.calculationObject[1].tax
      this.displaydiscount = this.calculationObject[1].discount
      this.isMonthly = false;
      this.isYearly = true;
      this.isYearlySelected = 1;
    }
  }

  subscriptionDialog() {
    const paymentInfoListByUserConst : Subscription = this.paymentInfoService.paymentInfoListByUser().subscribe((result: any) => {
      if (result.info.code === EStatusCode.OK) {
        if (result.data.length > 0) {
          this.paymentInfo = result.data;
        } else {
          this.paymentInfo = [];
        }
        if (!this.isYearlySelected) {
          var plan = "monthly";
        } else if (this.isYearlySelected) {
          var plan = "yearly";
        }
        
        const manifest = this.encryptManifest(
          this.mid,
          this.deviceId,
          this.transactionKey
        );
        if (this.tsysFlag) {
          const dialogRef = this.dialog.open(DialogComponent, {
            width: COMMON.CC_PAYMENT_WIDTH,
            height: "auto",
            disableClose: true,
            data: {
              total: this.displaytotal,
              plan: plan,
              manifest: manifest,
              ubmDeviceId : this.deviceId,
              userId: this.loggedInUserId,
              isOwner: this.isOwner,
              totalSeats: this.totalSeats,
              seatsToadd: (this.totalSeats-this.actualTotalSeats),
              dataArr: this.dataArr,
              defaultItemsList: this.defaultItemsList,
              subscribedModuleList: this.selectedItemsNameArr,
              remainingDaysToEndMonth: this.remainingDaysToEndMonth,
              subtotal: this.sTotal,
              paymentInfo: this.paymentInfo
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            
            if (result.isComplete == 0 && result.reOpen == 1) {
              this.subscriptionDialog();
            } else if (result.isComplete == 0 && result.reOpen == 0) {
              this.toastr.error("Add credit card aborted");
            } else if (result.isComplete == 1 && result.reOpen == 0) {
              if (result.isPaymentFailFromTsys == 1) {
                this.toastr.error(result.failedResonseMsg);
                this.toastr.error("Please try again later...!");
              } else {
                if (result.isSuccess == 1 && result.otherError == 0) {
                  this.toastr.success("Subscription payment successfully done");
                } else if (result.isSuccess == 1 && result.otherError == 1) {
                  this.toastr.success("Subscription payment successfully done");
                  this.toastr.error(result.otherErrorReason);
                  this.toastr.error("Please try again later...!");
                } else {
                }
              }
            } else if (result.isComplete == 2) {
              this.toastr.error("Credit card already exists!");
            } else if (result.isComplete == 3) {
              this.toastr.error("Something went wrong. Please try again later.");
            }
          });
        } else {
          
          this.toastr.error("Something went wrong. Please try again later.");
        }
      } else{
        
        this.toastr.error("Something went wrong. Please try again later.");
      }
      
    });
    this.subscriptions.push(paymentInfoListByUserConst);
  }

  encryptManifest(mid, deviceId, transactionKey) {
    const dataString =
      this.pad(mid, 20) +
      this.pad(deviceId, 24) +
      "000000000000" +
      moment().format("MMDDYYYY");
    const key = CryptoJS.enc.Utf8.parse(transactionKey.substr(0, 16));
    const iv = key;
    const encrypted = CryptoJS.AES.encrypt(dataString, key, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.NoPadding
    }).toString();
    const finalManifest = CryptoJS.enc.Base64.parse(encrypted);
    const hash = CryptoJS.HmacMD5(transactionKey, transactionKey);
    const finalhash = hash.toString(CryptoJS.enc.Hex);
    return finalhash.substr(0, 4) + finalManifest + finalhash.substr(-4);
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = s + " ";
    return s;
  }

  logout() {
    const logoutConst : Subscription = this.loginService.logout().subscribe(res => {
      this.socketService.disconnectSocket();
      localStorage.clear();
      this.router.navigate(["/login"], { replaceUrl: true });
      this.titleService.setTitle('Utiliko');
    });
    this.subscriptions.push(logoutConst);
  }

  checkModule(event,itemId,item){    
    if (event.currentTarget.checked) {
      this.defaultItemsList.forEach(element => {
        if (itemId == element.savedItemsId) {
          this.selectedModuleArr.push(element)
        }
      });
    } else {
      for(let i = 0; i < this.selectedModuleArr.length; i++) {
        if(this.selectedModuleArr[i].savedItemsId == itemId) {
          this.selectedModuleArr.splice(i, 1);
          break;
        }
      }
    }
    this.getDefaultItemsList();
  }
}
