import { Component, inject, OnInit } from '@angular/core';
import { ConfigService } from '../../service/config.service';
import { COMMON } from '../../service/constant';
import { PbxConsentService } from './services/pbx-consent.service';
import { take } from 'rxjs/operators';
import { EStatusCode } from '@milagro-ui-core';
import { ActivatedRoute } from '@angular/router';

import { CompanyDetails } from './interfaces/pbx-consent-types';
import { ToastrService } from 'ngx-toastr';
import { NgClass } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'app-pbx-consent',
    templateUrl: './pbx-consent.component.html',
    styleUrls: ['./pbx-consent.component.scss'],
    standalone: true,
    imports: [MatToolbar, NgClass]
})
export class PbxConsentComponent implements OnInit {

  // footer variables
  public version: string;
  public currYear: number;
  public callbackUrl: string;
  public state: string;

  // header variables
  public companyDetails: CompanyDetails = { companyLogo: '', companyName: '' };
  public themeData: string;
  public toastr: ToastrService = inject(ToastrService);

  constructor(
    private configService: ConfigService,
    private pbxConsentService: PbxConsentService,
    private activateRoute: ActivatedRoute
  ) {
    this.configService.updateIsUserLogged.next(false);
    this.version = COMMON.VERSION;
    this.currYear = COMMON.CURRENT_YEAR;
    this.companyDetails['companyLogo'] = this.configService.companyLogo;
    this.companyDetails['companyName'] = this.configService.companyName;
  }

  ngOnInit() {
    this.callbackUrl = this.activateRoute.snapshot.queryParams.callback_url;
    this.state = this.activateRoute.snapshot.queryParams.state;
  }

  onAllow() {
    this.fetchAccessToken();
  }

  async fetchAccessToken() {
    const getAccessToken: any = await this.pbxConsentService.getAccessToken().pipe(
      take(1),
    ).toPromise();
    if (getAccessToken.info.code === EStatusCode.OK) {
      const decodeToken = decodeURIComponent(getAccessToken.data.pbxAccessToken);
      const pbxUrl = `${this.callbackUrl}?access_token=${decodeToken}&state=${this.state}&userId=${getAccessToken.data.userId}&success=true`;
      window.open(pbxUrl, '_self');
    } else {
      this.toastr.error(getAccessToken.info.message);
    }
  }

  onDeny() {
    const pbxUrl = `${this.callbackUrl}?state=${this.state}&success=false`;
    window.open(pbxUrl, '_self');
  }
}
