<div class="tsys-dialog">
  <h1 mat-dialog-title>Add Credit Card</h1>
  <mat-divider></mat-divider>

  <mat-dialog-content>
    <div class="billing-dialog-tsys showfileds">

      <body style="background:#ffffff;">
        <form method="POST" id="payment-form" class="clearfix">
          <div class="col-md-12 pl-0">
            <div class="label-field">Business Name</div>
            <div id="tsep-businessName" class="form-group mb tsys-div">
              <input id="businessName" class="form-control" name="businessName" [(ngModel)]="businessName" [ngModelOptions]="{ standalone: true }" (input)="SearchData(nameInput.value, 'businessName')" type="text" #nameInput placeholder="Business Name">
            </div>
          </div>
          <div class="col-md-12 pl-0">
            <div class="label-field">Credit Card Number</div>
            <div id="tsep-cardNumDiv" class="tsys-div"></div>
          </div>
          <div class="col-md-4 pl-0">
            <div class="label-field">Zip Code</div>
            <div id="tsep-zipcode" class="form-group mb tsys-div">
              <input 
              #textInput 
              type="text" 
              class="form-control" 
              id="zipcode" 
              name="zipcode" 
              placeholder="00000"
              (input)="SearchData(textInput.value, 'zipcode')"
              minlength="5" 
              maxlength="10" 
              inputmode="numeric"
              pattern="[0-9]*"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57">
            </div>
          </div>
          <div class="col-md-4 pl-0">
            <div class="label-field">Exp Date</div>
            <div id="tsep-datepickerDiv" class="tsys-div"></div>
          </div>
          <div class="col-md-3 pl-0">
            <div class="label-field">CVV</div>
            <div id="tsep-cvv2Div" class="tsys-div"></div>
          </div>
          <div id="div_console"></div>
        </form>
      </body>
    </div>
  </mat-dialog-content>

  <div class="save-card-option"  mat-dialog-actions>
    <div class="text-info tw-px-2.5">
      <i class="fa fa-info-circle"></i>
      <span>&nbsp;&nbsp; Hit the tab button or click outside the input box after filling out the CVV field.</span>
    </div>
    <div class="card-checkboxes">
      <mat-checkbox [checked]="true" (change)="defaultCcCheck($event)" id="checkbox1" [disabled]="!isDefaultAvailable">
        <span class="field_label">Make this card default</span>
      </mat-checkbox>
    </div>

    <div class="pay-now tw-flex tw-items-center tw-mt-4">
      <button mat-raised-button class="btn-cancel" mat-dialog-close >Cancel</button>
      <button mat-raised-button class="btn-submit form-button-40" (click)="addCC()" [disabled]="currentStatus == 2">Add</button>
    </div>
  </div>
</div>