import { Component, EventEmitter, HostBinding, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavItem } from '../../../../service/Utils/Interfaces.class';
import { NavService } from '../../../../service/nav-service.service';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../../../../service/config.service';
import { TourServiceService } from '../../../../service/tour-service.service';
import { FlexModule } from '@angular/flex-layout/flex';
import { OverlayTourComponent } from '../overlay-tour/overlay-tour.component';
import { MatIcon } from '@angular/material/icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgStyle, NgClass, NgIf, NgFor } from '@angular/common';
import { MatListItem } from '@angular/material/list';

@Component({
    selector: 'app-menu-list-item',
    templateUrl: './menu-list-item.component.html',
    styleUrls: ['./menu-list-item.component.css'],
    animations: [
        trigger('indicatorRotate', [
            state('collapsed', style({ transform: 'rotate(0deg)' })),
            state('expanded', style({ transform: 'rotate(180deg)' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
        ])
    ],
    standalone: true,
    imports: [MatListItem, NgStyle, ExtendedModule, NgClass, MatIcon, NgIf, OverlayTourComponent, RouterLink, FlexModule, NgFor]
})
export class MenuListItemComponent implements OnInit {

  expanded = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  @Input() navItemList: NavItem[];
  @Output() parentActiveEmit = new EventEmitter<boolean>();
  @Output() expandParentEmit = new EventEmitter<boolean>();
  parentActiveCheck = false;
  tourItems = [];
  activeTourIndex;
  public text;
  public icon;
  public label;
  public id;
  customerTourDataObject;
  constructor(public navService: NavService, public router: Router, private renderer: Renderer2,
    private titleService: Title, private configService: ConfigService, private tourService: TourServiceService) {

    if (this.depth === undefined) {
      this.depth = 0;
    }
  }



  ngOnInit() {
    this.tourService.activeTourIndex.subscribe((index) => {
      this.activeTourIndex = index;
      const activeIndexData = this.tourService.customerTourData.find((x) => x.isMenuSlide && x.id === this.activeTourIndex);
      if (activeIndexData && (activeIndexData.slideModule.toLowerCase() === this.item.label.toLowerCase())) {
        this.customerTourDataObject = null;
        const addHash = '#';
        const selectedID = addHash.concat(this.item.label.split(' ').join('-'));
        this.item.label && document.querySelector(selectedID) && document.querySelector(selectedID).scrollIntoView({block: 'center', behavior: 'smooth'});
          setTimeout(() => {
            this.customerTourDataObject = activeIndexData;
          }, 0);
      }
    });
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.link && url) {
        this.expanded = url.indexOf(`/${this.item.link}`) === 0;
        this.item.expanded = this.expanded;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  trackByTourItems(index: number, tourItems: any): string {
    return tourItems.idNumber;
  }
  trackByItems(index: number, item: any): string {
    return item.items.parent;
  }
  onItemSelected(item: NavItem, $event) {
    this.configService.getsearchdata();
    $event.preventDefault();
    $event.stopPropagation();
    if (!item.items || !item.items.length) {
      this.parentActiveEmit.emit(false);
      this.router.navigate([item.link]);
      const windowTitle = item.label ? (item.label.substr(0, 1).toUpperCase() + item.label.substr(1)) : '';
      this.titleService.setTitle(windowTitle);
    }
    if (item.items && item.items.length) {
      const expandStanded = !item.expanded;
      this.closeExpandofAll(this.navItemList);
      item.expanded = expandStanded;
      if (this.item.parent !== 0) {
        this.expandParentEmit.emit(expandStanded);
      }
    }
    return false;
  }

  closeExpandofAll(itemList: NavItem[]) {
    itemList.forEach(itemData => {
      itemData.expanded = false;
      if (itemData.items.length > 0) {
        this.closeExpandofAll(itemData.items);
      }
    });
  }

  receiveMessage($event, $matListItem) {
    if ($event && $matListItem?._element?.nativeElement) {
      this.item.expanded = true;
      this.renderer.addClass($matListItem._element.nativeElement, 'active');
      if (this.item.parent !== 0) {
        this.parentActiveEmit.emit(true);
      }
    }
  }

  receiveParentExpand($event) {
    if ($event) {
      this.item.expanded = true;
      if (this.item.parent !== 0) {
        this.expandParentEmit.emit(true);
      }
    } else {
      this.item.expanded = true;
    }
  }

  checkCurrentUrlWithRoute(navItem: NavItem) {

    const link = navItem.link;
    const paths = [link,
      `${link}/form`,
      `${link}/pay-bill-refund-form`, `${link}/make-payment`, `${link}/vendor-credit-note-form`,
      `${link}/match-transactions`,
      `${link}/refund`,
      `${link}/ar-aging`, `${link}/ap-aging`, `${link}/pnl`, `${link}/bs`];

    const status = paths.some(path => this.router.isActive(path, true));

    if (status && navItem.parent !== 0) {
      this.parentActiveEmit.emit(true);
    }
    return status;
  }

}
