import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../../../../../service/config.service';
import { TaskService } from '../../../../../service/task.service';
import { IResponseList } from '../../../../../service/Utils/Interfaces.class';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { SafePipe } from '../../../../../admin/shared/pipes/safe.pipe';
import { CommonDatePipe } from '../../../../../admin/shared/pipes/common-date.pipe';
import { NgFor, NgIf } from '@angular/common';
import { MatDivider } from '@angular/material/divider';

@Component({
    selector: 'app-testcase-activities',
    templateUrl: './testcase-activities.component.html',
    styleUrls: ['./testcase-activities.component.scss'],
    standalone: true,
    imports: [MatDivider, NgFor, NgIf, MatPaginator, CommonDatePipe, SafePipe]
})
export class TestcaseActivitiesComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  activitiesListArr = [];
  event: any;
  prevPageIndex = 0;
  start: number = 0;
  limit: number = 15;
  totalRecordes: number;
  pageSize:number = 15;
  testCaseId:number;
  pageSizeArray = [15, 50, 100];
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(public configService: ConfigService,public taskService: TaskService) { }
  

  ngOnInit() {
    this.testCaseId = this.taskService.testCaseId
    this.getActivitiesList(this.testCaseId,0,0);
  }

  getActivitiesList(id, count?, previousPageIndex?){

        const self = this;
        if (count === 0) {
          if (count <= previousPageIndex) {
            this.start = 0;
          } else {
            this.start += this.pageSize;
          }
        } else {
          this.start = count * this.pageSize;
        }
        const filter = {"start": this.start,"limit": this.limit};
        this.taskService.testCaseActivity(this.testCaseId,  filter).subscribe((res: IResponseList) => {
          if (res.info.code === 200) {
           this.activitiesListArr = res.data.list;
           this.totalRecordes = res.data.totalCount;
          }else{
          this.toastr.error('Activity not available ');
          }
        });
    }
      
    onChange(event) {
        this.event = event.pageIndex;
        if (this.limit !== event.pageSize) {
          this.start -= event.pageSize;
          this.limit = event.pageSize;
          this.pageSize = event.pageSize;
        }
        this.prevPageIndex = event.previousPageIndex;
        this.getActivitiesList(this.testCaseId, event.pageIndex, event.previousPageIndex);
      }

}
