import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgOption, NgSelectModule } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { TaskService } from '../../../../service/task.service';
import { ToastrService } from 'ngx-toastr';
import { MatButton } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgClass } from '@angular/common';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { EStatusCode } from '@milagro-ui-core';

@Component({
    selector: 'app-participants-dialog',
    templateUrl: './participants-dialog.component.html',
    styleUrls: ['./participants-dialog.component.scss'],
    standalone: true,
    imports: [MatIcon, MatDivider, FormsModule, ReactiveFormsModule, MatRadioGroup, MatRadioButton, NgIf, NgSelectModule, NgClass, ExtendedModule, MatButton]
})
export class ParticipantsDialogComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  private readonly subscriptions: Subscription[] = [];
  participantsForm: FormGroup;
  showUsers = false;
  openFlag = false;
  showError: boolean = false;
  radioValue: any;
  customUsersData: NgOption[] = [];
  usersArr = [];
  particip:any;
  custmUsers:any;
  responsibleUser: number;
  responsiblearr: Array<object> = [];
  responsibleUserId: NgOption = [];
  testCaseId:number;
  permissions:any;

  constructor(public fb: FormBuilder,  public taskService:TaskService, public dialogRef: MatDialogRef<ParticipantsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.testCaseId = this.data.details.testCaseId
    this.participantsForm = this.fb.group({
      permission: ["", Validators.required],
      participants: ["", Validators.required],
    });
    this.getResponsibleUserList();
    this.patchValueForCustUser();
  }
  patchValueForCustUser(){
    const customRoleId =[];
    const permission = this.data.details.permissionType
    this.permissions = this.data.details.permissionType
    const tempArr = JSON.parse(this.data.details.customUsers);
    if(permission == 1){
      this.radioValue = 1;
    }
    else if(permission == 2){
    this.radioValue = 2;
    }
    else if(permission == 3){
      this.radioValue = 3;
      this.showUsers = true;
      if(tempArr != null){
        this.custmUsers = tempArr;
      const custUsers = tempArr;
      for(let i=0; i < custUsers.length; i++)
      customRoleId.push(parseInt(custUsers[i].id));
      this.participantsForm.patchValue({
        participants: customRoleId
      })
      }
    }
  }

  getResponsibleUserList() {
    const responsibleUseAList : Subscription = this.taskService.responsibleUserList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        let myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.userId, 'label': element.fullname};
          this.responsiblearr.push(myObj);
          if (element.isOwner == 1) {
            this.responsibleUser = element.userId;
          }
        });
        this.customUsersData = this.responsiblearr;
      }
    });
    this.subscriptions.push(responsibleUseAList);
  }
  onSubmit(){
    if(this.participantsForm.value.permission == 3){
      if(this.particip == undefined || this.particip.length == 0){
        this.toastr.error('Please select Custom Participants');
        return;
      }
    }
    const perm = this.particip ? this.particip :'';
    this.permissions = this.participantsForm.value.permission ? this.participantsForm.value.permission : this.permissions;
      const changePer : Subscription = this.taskService.testCasePermissionChange( this.testCaseId,this.permissions,perm ).subscribe((res: any) => {
        if (res.info.code === EStatusCode.OK) {
          this.toastr.success(res.data.responseMsg);
          this.navigateMainPage();
        }
      })
      this.subscriptions.push(changePer);
    
  }

  radioChange(event) {
    // this.permission = event.value;
    if (event.value === 3) {
      this.showUsers = true;
      if (this.openFlag){
        this.participantsForm.controls['participants'].reset();
        this.openFlag = false;
      }
    }
    else {
      this.showUsers = false;
      this.openFlag = false;
    }
  }

  updateParticipants() {
    this.particip = this.participantsForm.value.participants;
  }

  navigateMainPage(){
    this.dialogRef.close({ data: true });
    this.participantsForm.controls['permission'].reset();
    this.participantsForm.controls['participants'].reset();
    this.particip = []
  }

}
