import { Routes } from '@angular/router';
import { AuthGuard } from '../app.authGuard.service';
import { IntegrationsAppsComponent } from './integrations-apps/integrations-apps.component';
import { SubscriptionPayComponent } from './subscription-pay/subscription-pay.component';
import { PbxConsentComponent } from './pbx-integration/pbx-consent.component';
import { AgreementDialogEntryComponent } from './dashboard/agreement-dialog-entry/agreement-dialog-entry.component';

const adminRoutes: Routes = [
    {
        path: 'admin',
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard'
            },
            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                loadChildren: () => import('./dashboard/dashboard.routes'),
                data: { title: 'Dashboard', status: false },
            },
            {
                path: 'mailbox',
                canActivate: [AuthGuard],
                loadChildren: () => import('./mailbox/mailbox.routes')
            },
            {
                path: 'calendar',
                canActivate: [AuthGuard],
                loadChildren: () => import('./calendar/calendar.routes')
            },
            {
                path: 'client',
                canActivate: [AuthGuard],
                loadChildren: () => import('./admin-client/admin-client.routes')
            },
            {
                path: 'vendor',
                canActivate: [AuthGuard],
                loadChildren: () => import('./vendor/vendor.routes')
            },
            {
                path: 'meetings',
                canActivate: [AuthGuard],
                loadChildren: () => import('./meetings-and-agenda/meetings.routes')
            },
            {
                path: 'apps',
                canActivate: [AuthGuard],
                component: IntegrationsAppsComponent,
            },
            {
                path: 'subscription',
                canActivate: [AuthGuard],
                component: SubscriptionPayComponent
            },
            {
                path: 'agreement/detail/:id',
                component: AgreementDialogEntryComponent
            },
        ]
    },
    {
        path: 'hr',
        canActivate: [AuthGuard],
        loadChildren: () => import('./hr/hr.routes')
    },
    {
        path: 'report',
        canActivate: [AuthGuard],
        loadChildren: () => import('./reports/reports.routes')
    },
    {
        path: 'accounting',
        loadChildren: () => import('./accounting/accounting.routes')
    },
    {
        path: 'utilities',
        loadChildren: () => import('./utilities/utilities.routes')
    },
    {
        path: 'sales',
        canActivate: [AuthGuard],
        loadChildren: () => import('./sales/sales.routes')
    },
    {
        path: 'survey',
        canActivate: [AuthGuard],
        loadChildren: () => import('./survey/survey.module').then((m: typeof import('./survey/survey.module')) => m.SurveyModule),
    },
    {
        path: 'pbx/consent',
        canActivate: [AuthGuard],
        component: PbxConsentComponent
    },
];

export default adminRoutes;
